import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import {
  clickedSearchAtom,
  searchStringValueAtom,
} from "../stateManager/searchValueAtom";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import {
  searchResultAtom,
  vehiclesListAtom,
} from "../stateManager/vehiclesListAtom";

const useSearchVehicles = () => {
  const [state, setState] = useRecoilState(searchStringValueAtom);
  const [vehiclesList] = useRecoilState(vehiclesListAtom);
  const [transitionArray, setTransitionArray] = useState([]);
  const [searchResult, setSearchResult] = useRecoilState(searchResultAtom);
  // eslint-disable-next-line no-unused-vars
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [clicked, setCliked] = useRecoilState(clickedSearchAtom);

  // SUGGESTIONS
  const [filteredSuggestions, setFilteredSuggestions] = useState(searchResult);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange = (e) => {
    setState({ value: e.target.value });

    const unLinked = transitionArray.filter(
      (resu) =>
        resu.title.toLowerCase().indexOf(state.value.toLowerCase()) > -1 ||
        resu.Options.toLowerCase().indexOf(state.value.toLowerCase()) > -1
    );
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = () => {
    setFilteredSuggestions([]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    navigate("/details");
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  // END OF SUGGESTIONS

  useEffect(() => {
    if (state.value !== "") {
      const searchRes = vehiclesList.map((res) => res);
      setTransitionArray(searchRes);
      if (transitionArray.length > 0) {
        const finalRes = transitionArray?.filter(
          (resFilter) =>
            resFilter.title.toLowerCase().includes(state.value.toLowerCase()) ||
            resFilter.Options.toLowerCase().includes(state.value.toLowerCase())
        );
        setSearchResult(finalRes);
      }
      if (searchResult.length > 0) {
        setSelectedVehicle(searchResult);
      }
    }
    if (
      state.value === "" &&
      transitionArray.length > 0 &&
      searchResult.length > 0
    ) {
      handleDeleteSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchResult, state]);

  const handleDeleteSearch = () => {
    setTransitionArray([]);
    setSearchResult([]);
    setState({ value: "" });
  };
  return {
    handleChange,
    onKeyDown,
    state,
    showSuggestions,
    setSelectedVehicle,
    filteredSuggestions,
    activeSuggestionIndex,
    onClick,
    searchResult,
    setCliked,
    navigate,
  };
};

export default useSearchVehicles;
