import { atom } from "recoil";

export const searchValueAtom = atom({
  key: "searchValueAtom",
  default: 0,
});

export const clickedSearchAtom = atom({
  key: "clickedSearchAtom",
  default: false,
});

export const searchStringValueAtom = atom({
  key: "searchStringValueAtom",
  default: { value: "" },
});
