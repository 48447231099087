import ScrollAnimation from "react-animate-on-scroll";

const SubscribeSection = () => {
  return (
    <div
      className="row  justify-content-center"
      style={{ marginTop: "6%", marginBottom: "10vh" }}
    >
      <div className="container">
        <div className="newsletter_form1">
          <ScrollAnimation animateIn="fadeIn">
            <h3
              className="form_title mb_30 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Inscrivez-vous à la NewsLetter
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <p
              className="mb_30 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Phasellus efficitur dolor sit amet odio scelerisque, et luctus
              eros lobortis
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <ul
              className="primary_social_links ul_li mb_30 clearfix aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <li>
                <a href="#!">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </ScrollAnimation>

          <div
            className="row buttonGroup aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            {" "}
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <form>
                <ScrollAnimation animateIn="zoomIn">
                  <div className="form_item mb-0">
                    <input
                      type="email"
                      name="email"
                      placeholder="Entrez votre email"
                      required
                    />
                  </div>
                </ScrollAnimation>
              </form>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <ScrollAnimation animateIn="zoomIn">
                <button
                  onClick={() => alert("Hello World")}
                  type="submit"
                  className="custom_btn bg_default_red text-uppercase w-100 d-block subscribeButton"
                >
                  Envoyer{" "}
                  <img
                    src={require("../assets/images/icons/icon_01.png")}
                    alt="icon_not_found"
                  />
                </button>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeSection;
