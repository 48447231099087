import axios from "axios";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BASE_URL } from "../../helpers/fetchUrls";
import { ordersPastAtom } from "../../stateManager/shoppingCartAtom";
import Compte from "./Compte";
import Historiques from "./Historiques";
import Profile from "./Profile";
import { v4 as uuidv4 } from "uuid";

const AccountSection = React.memo(
  ({ arrayData, user_id, rentHistory, setArrayData }) => {
    const [orderInfos, setOrderInfo] = useState({});
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [ordered, setOrdered] = useRecoilState(ordersPastAtom);

    useEffect(() => {
      if (arrayData) {
        const lastname = arrayData
          .map((res) => res.order[0])
          .map((r) => r.lastname)[0];
        const firstname = arrayData
          .map((res) => res.order[0])
          .map((r) => r.firstname)[0];
        const email = arrayData
          .map((res) => res.order[0])
          .map((r) => r.email)[0];
        const tel = arrayData.map((res) => res.order[0]).map((r) => r.tel)[0];

        setOrderInfo({
          name: lastname,
          firstname: firstname,
          email: email,
          phone: tel,
        });
      }
    }, [arrayData]);

    const handleDeleteAllInfos = () => {
      MySwal.fire({
        title: "Suppression des infos",
        icon: "info",
        html: `
        <div>
        <p>Toutes les infos de réservation vont êtres effacées de cet appareil !</p>
      </div>`,
        footer: "",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
        cancelButtonText: "annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("reservation");
          localStorage.removeItem("userId");
          navigate("/");
          MySwal.fire({
            html: `
              <div style="display:flex;flex-direction:column; align-items:center; justify-content:center">
                <p style="font-size:18px; max-width:260px">Toutes les données sont effacées</p>
              </div>`,
            icon: "success",
          }).then(() => localStorage.setItem("userId", uuidv4()));
        }
      });
    };

    const isIdUser = ordered
      .map((t) => t.user_order)
      .filter((w) => w === user_id);
    const isStatus = ordered
      .filter((e) => e.user_order === user_id)
      .map((a) => a.status);
    const lastStatus = arrayData.map((r) => r.status);

    useEffect(() => {
      const getUpatedData = async () => {
        const orders = await axios.get(`${BASE_URL}/session`);
        setOrdered(orders.data.data);
      };

      if (isIdUser.includes(user_id)) {
        if (isStatus !== lastStatus) {
          getUpatedData();
          setArrayData(ordered.filter((o) => o.user_order === user_id));
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isLoaded, setIsloaded] = useState(false);

    useEffect(() => {
      setIsloaded(true);

      return () => setIsloaded(false);
    }, []);

    return (
      <section className="account_section sec_ptb_100 clearfix">
        <div className="container">
          {isMobile ? (
            <>
              <button
                onClick={handleDeleteAllInfos}
                className="custom_btn bg_default_red"
                style={{ marginBottom: 15 }}
              >
                Supprimer toutes les infos
              </button>
              <button
                onClick={() => navigate("/synchronisation")}
                className="custom_btn bg_default_blue"
                style={{ marginTop: 2, marginBottom: 15, fontSize: 12.5 }}
              >
                Synchroniser toutes les infos
              </button>
            </>
          ) : null}
          <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
            <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
              <div
                className="account_tabs_menu clearfix aos-init aos-animate"
                data-bg-color="#F2F2F2"
                data-aos="fade-up"
                data-aos-delay="100"
                style={{ backgroundColor: "rgb(242, 242, 242)" }}
              >
                <h3 className="list_title mb_15">Détails du compte:</h3>
                <ul className="ul_li_block nav" role="tablist">
                  <li>
                    <a className="active" data-toggle="tab" href="#admin_tab">
                      <i className="fas fa-user"></i>  
                      {orderInfos && isLoaded
                        ? `${orderInfos.name}  ${orderInfos.firstname}`
                        : " Chargement..."}
                    </a>
                  </li>
                  <li></li>
                  <li>
                    <a data-toggle="tab" href="#profile_tab">
                      <i className="fas fa-address-book"></i> Profils de
                      réservation
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#history_tab">
                      <i className="fas fa-file-alt"></i> Historique des
                      réservations
                    </a>
                  </li>
                </ul>
              </div>
              {!isMobile ? (
                <>
                  <button
                    onClick={handleDeleteAllInfos}
                    className="custom_btn bg_default_red"
                    style={{ marginTop: 15 }}
                  >
                    Supprimer toutes les infos
                  </button>
                  <button
                    onClick={() => navigate("/synchronisation")}
                    className="custom_btn bg_default_blue"
                    style={{ marginTop: 15, fontSize: 14.5 }}
                  >
                    Synchroniser toutes les infos
                  </button>
                </>
              ) : null}
            </div>

            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12">
              <div className="account_tab_content tab-content">
                <div id="admin_tab" className="tab-pane active">
                  <Compte isLoaded={isLoaded} orderInfos={orderInfos} />
                  <Profile
                    isLoaded={isLoaded}
                    user_id={user_id}
                    orderInfos={orderInfos}
                  />
                  <Historiques
                    navigate={navigate}
                    arrayData={arrayData}
                    rentHistory={rentHistory}
                    ordered={ordered}
                    isLoaded={isLoaded}
                  />
                </div>
                <div id="profile_tab" className="tab-pane">
                  <Profile
                    isLoaded={isLoaded}
                    user_id={user_id}
                    orderInfos={orderInfos}
                  />

                  <Historiques
                    navigate={navigate}
                    arrayData={arrayData}
                    rentHistory={rentHistory}
                    ordered={ordered}
                    isLoaded={isLoaded}
                  />

                  <Compte orderInfos={orderInfos} isLoaded={isLoaded} />
                </div>
                <div id="history_tab" className="tab-pane">
                  <Historiques
                    navigate={navigate}
                    arrayData={arrayData}
                    rentHistory={rentHistory}
                    ordered={ordered}
                    isLoaded={isLoaded}
                  />

                  <Profile
                    isLoaded={isLoaded}
                    user_id={user_id}
                    orderInfos={orderInfos}
                  />

                  <Compte orderInfos={orderInfos} isLoaded={isLoaded} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default AccountSection;
