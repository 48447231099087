import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ASSETS_URL, BASE_URL } from "../helpers/fetchUrls";
import {
  reservationAtom,
  selectedIdAtom,
} from "../stateManager/reservationAtom";
import {
  shoppingCartAtom,
  totalDaysAtom,
} from "../stateManager/shoppingCartAtom";
import { vehiclesListAtom } from "../stateManager/vehiclesListAtom";
import { useLocalStorage } from "./useLocalStorage";
import { toTimestamp } from "./useToTimestamp";
import { PERCENTAGE_TAXE } from "../helpers/currentTimeVariables";

const useShoppingCart = () => {
  const [isReserved, setIsReserved] = useRecoilState(reservationAtom);
  const [vehiclesList] = useRecoilState(vehiclesListAtom);
  const [shoppingCarInfos, setShoppingCarInfo] =
    useRecoilState(shoppingCartAtom);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [nbOfDays, setNbOfDays] = useState(0);
  const dayPrice = shoppingCarInfos?.map((res) => res.price);
  const totalDays = nbOfDays / 86400; // 86 400 = 60*60*24
  // eslint-disable-next-line no-unused-vars
  const [totalDaysCart, setTotalDaysCart] = useRecoilState(totalDaysAtom);
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);
  let navigate = useNavigate();
  const [clickedConfirmOrder, setClickedConfirmOrder] = useState(false);
  const PicturesAddress = `${ASSETS_URL}`;
  const MySwal = withReactContent(Swal);

  const toggleConfirmOrder = () => {
    setClickedConfirmOrder((clickedConfirmOrder) => !clickedConfirmOrder);
  };

  useEffect(() => {
    const handleCheckCarInfo = () => {
      const infosCar = vehiclesList.filter(
        (res) => res.title === isReserved.vehicleName
      );
      setShoppingCarInfo(infosCar);
    };

    const handleTimeduration = () => {
      const dateRetrait = toTimestamp(isReserved.dateRetrait);
      const dateDepot = toTimestamp(isReserved.dateDepot);
      setStartDate(dateRetrait);
      setEndDate(dateDepot);
      const nbDayTimestamp = dateDepot - dateRetrait;
      setNbOfDays(nbDayTimestamp);
    };

    if (shoppingCarInfos.length <= 0) {
      handleCheckCarInfo();
      handleTimeduration();
    }
    if (nbOfDays !== 0) {
      setTotalDaysCart(totalDays);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReserved,
    vehiclesList,
    shoppingCarInfos,
    endDate,
    startDate,
    totalDays,
    dayPrice,
  ]);

  function refreshPage() {
    setShoppingCarInfo([]);
    setTotalDaysCart(0);
    setSelectedId(undefined);
    setIsReserved([]);
    navigate("/");
  }

  const htPrice =
    parseInt(dayPrice[0]) * totalDays -
    (parseInt(dayPrice[0]) * totalDays * PERCENTAGE_TAXE) / 100; // 20% de tva
  const taxePrice = (parseInt(dayPrice[0]) * totalDays * PERCENTAGE_TAXE) / 100; // 20% de tva
  const totalPrice = parseInt(dayPrice[0]) * totalDays;
  const [dataToSend, setDataToSend] = useState({});
  const [reservedDatas, setReservedDatas] = useState({});
  const [arrayData, setArrayData] = useLocalStorage("reservation", []);
  const [paymentMethod] = useState(
    JSON.parse(localStorage.getItem("payment")) ?? {}
  );

  useEffect(() => {
    const user_id = localStorage.getItem("userId");
    if (isReserved) {
      const obj = {
        status: "draft",
        user_order: user_id,
        validation: false,
        order: [
          {
            vehicleName: isReserved.vehicleName,
            id_vehicle: selectedId,
            dateRetrait: isReserved.dateRetrait,
            heureRetrait: isReserved.heureRetrait,
            dateDepot: isReserved.dateDepot,
            heureDepot: isReserved.heureDepot,
            totalDays: totalDays,
            htPrice: htPrice,
            taxePrice: taxePrice,
            totalPrice: totalPrice,
            mr: isReserved.mr,
            ms: isReserved.ms,
            lastname: isReserved.lastname,
            firstname: isReserved.firstname,
            email: isReserved.email,
            tel: isReserved.tel,
            information: isReserved.information,
            offer1: isReserved.offer1,
            offer2: isReserved.offer2,
            offer3: isReserved.offer3,
            offer4: isReserved.offer4,
            offer5: isReserved.offer5,
            offer6: isReserved.offer6,
          },
        ],
      };
      const reservedDates = {
        status: "draft",
        title: isReserved.vehicleName,
        startDate: startDate,
        endDate: endDate,
      };

      setDataToSend(obj);
      setReservedDatas(reservedDates);
    }
  }, [
    isReserved,
    totalDays,
    taxePrice,
    htPrice,
    totalPrice,
    endDate,
    startDate,
    selectedId,
  ]);

  const postDataOrder = async () => {
    await axios
      .post(`${BASE_URL}/alreadyReserved`, reservedDatas)
      .then((res) => {
        if (!res) {
          console.error("error");
        }
      });

    await axios.post(`${BASE_URL}/session`, dataToSend).then((res) => {
      if (res) {
        setArrayData([...arrayData, res.data.data]);

        MySwal.fire({
          title: "Réservation Envoyée",
          html: `
            <div>
            <p>Vous avez maintenant accès à un compte invité, toutes les infos nécessaires sont présentent.</p>
          </div>`,
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          confirmButtonColor: "#7367f0",
          cancelButtonColor: "#ee0979",
          confirmButtonText: "Ok, J'ai compris !",
          footer: "Merci pour votre confiance.",
          timer: 3440,
        })

          .then(() => window.location.replace("/"))
          .then(() => setShoppingCarInfo([]))
          .then(() => setIsReserved([]));
      } else if (!res) {
        MySwal.fire({
          title: "Erreur durant l'envoi",
          html: `
            <div>
            <p >Quelques chose s'est mal passée durant l'envoi de la réservation.</p>
          </div>`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          confirmButtonColor: "#7367f0",
          cancelButtonColor: "#ee0979",
          confirmButtonText: "Ok, J'ai compris !",
          footer: "Veuillez réessayer s'il vous plaît !",
          timer: 2540,
        });
      }
    });
  };

  const sendOrder = () => {
    sessionStorage.setItem("reserved", JSON.stringify(isReserved));
    sessionStorage.setItem("totalDays", JSON.stringify(totalDays));
    sessionStorage.setItem("htPrice", JSON.stringify(htPrice));
    sessionStorage.setItem("taxePrice", JSON.stringify(taxePrice));
    sessionStorage.setItem("totalPrice", JSON.stringify(totalPrice));
    postDataOrder();
  };

  return {
    shoppingCarInfos,
    refreshPage,
    PicturesAddress,
    isReserved,
    totalDays,
    dayPrice,
    htPrice,
    paymentMethod,
    toggleConfirmOrder,
    clickedConfirmOrder,
    taxePrice,
    totalPrice,
    sendOrder,
  };
};

export default useShoppingCart;
