import React from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import AccountSection from "./accountComponents/AccountSection";
import Breadcrumb from "./accountComponents/Breadcrumb";
import BaseLayout from "./BaseLayout";

const AccountPage = React.memo(() => {
  const [arrayData, setArrayData] = useLocalStorage("reservation", []);
  const user_id = localStorage.getItem("userId");

  const rentHistory =
    arrayData && arrayData.map((res) => res.order).map((r) => r[0]);

  return (
    <BaseLayout>
      <div style={{ marginTop: 90 }}>
        <Breadcrumb />
        {user_id === arrayData[0].user_order ? (
          <AccountSection
            arrayData={arrayData}
            setArrayData={setArrayData}
            user_id={user_id}
            rentHistory={rentHistory}
          />
        ) : (
          <div>Aucune réservation n'a été faite...</div>
        )}
      </div>
    </BaseLayout>
  );
});

export default AccountPage;
