import React from "react";

const Compte = React.memo(({ orderInfos, isLoaded }) => {
  return (
    <div
      className="account_info_list aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <h3 className="list_title mb_30">Le compte:</h3>
      <ul className="ul_li_block clearfix">
        <li>
          <span>Nom:</span>{" "}
          {orderInfos && isLoaded
            ? `${orderInfos.name}  ${orderInfos.firstname}`
            : " Chargement..."}
        </li>
        <li>
          <span>E-mail:</span>{" "}
          {orderInfos && isLoaded ? `${orderInfos.email}` : " Chargement..."}
        </li>
        <li>
          <span>Téléphone:</span>{" "}
          {orderInfos && isLoaded ? `${orderInfos.phone}` : " Chargement..."}
        </li>
        <li>
          <span>Pays:</span> France
        </li>
      </ul>
    </div>
  );
});

export default Compte;
