import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ScrollAnimation from "react-animate-on-scroll";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../helpers/fetchUrls";

const FormSection = () => {
  const MySwal = withReactContent(Swal);
  // eslint-disable-next-line no-unused-vars
  const [clicked, setClicked] = useState(false);

  const schema = yup
    .object({
      firstname: yup.string().required("Votre prénom est requis"),
      lastname: yup.string().required("Votre Nom de famille est requis"),
      email: yup
        .string()
        .email("Email non valide")
        .required("Votre Email est requis"),
      phone: yup.string().required("votre numéro de téléphone est requis"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  function resetAllInput() {
    document.getElementById("contact_form").reset();
  }

  const onSubmit = (data) => {
    const postDataOrder = async () => {
      const obj = {
        status: "draft",
        message: [data],
      };
      await axios
        .post(`${BASE_URL}/messages`, obj)
        .then((res) => {
          if (res) {
            setClicked(true);
            MySwal.fire({
              title: "Message Envoyée",
              html: `
              <div>
              <p >Nous traitons votre demande et revenons vers vous rapidement.</p>
            </div>`,
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
              confirmButtonColor: "#7367f0",
              cancelButtonColor: "#ee0979",
              confirmButtonText: "Ok, J'ai compris !",
              footer: "Merci pour votre confiance.",
              timer: 2640,
            });
          } else if (!res) {
            MySwal.fire({
              title: "Erreur durant l'envoi",
              html: `
              <div>
              <p >Quelques chose s'est mal passée durant l'envoi du message.</p>
            </div>`,
              icon: "error",
              showCancelButton: false,
              showConfirmButton: false,
              confirmButtonColor: "#7367f0",
              cancelButtonColor: "#ee0979",
              confirmButtonText: "Ok, J'ai compris !",
              footer: "Veuillez réessayer s'il vous plaît !",
              timer: 2540,
            });
          }
        })
        .then(() => resetAllInput())
        .then(() => setClicked(false))
        .then(() => window.location.replace("/"));
    };
    postDataOrder();
  };

  return (
    <section className="contact_form_section sec_ptb_100 clearfix">
      <div className="container">
        <div
          className="section_title mb_60 text-center aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <ScrollAnimation animateIn="zoomIn">
            <h2 className="title_text mb-0">
              <span>Envoyez-nous un message</span>
            </h2>
          </ScrollAnimation>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="contact_form">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="form_item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <input
                    type="text"
                    name="firstname"
                    {...register("firstname")}
                    placeholder="Prénom"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: 12,
                      fontWeight: "bold",
                      width: 250,
                    }}
                  >
                    {errors.firstname?.message}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="form_item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <input
                    type="text"
                    name="lastname"
                    {...register("lastname")}
                    placeholder="Nom"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: 12,
                      fontWeight: "bold",
                      width: 250,
                    }}
                  >
                    {errors.lastname?.message}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="form_item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <input
                    type="email"
                    name="email"
                    {...register("email")}
                    placeholder="E-mail"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: 12,
                      fontWeight: "bold",
                      width: 250,
                    }}
                  >
                    {errors.email?.message}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div
                className="form_item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <input
                    type="tel"
                    name="phone"
                    {...register("phone")}
                    placeholder="Téléphone"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: 12,
                      fontWeight: "bold",
                      width: 250,
                    }}
                  >
                    {errors.phone?.message}
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div
            className="form_item aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <ScrollAnimation animateIn="zoomIn">
              <textarea
                name="message"
                {...register("message")}
                placeholder="Ecrivez votre message"
              ></textarea>
            </ScrollAnimation>
          </div>
          <div
            className="abtn_wrap text-center clearfix aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <ScrollAnimation animateIn="zoomIn">
              <button className="custom_btn btn_width bg_default_red text-uppercase">
                Envoyer{" "}
                <img
                  src={require("../../assets/images/icons/icon_01.png")}
                  alt="icon_not_found"
                />
              </button>
            </ScrollAnimation>
          </div>
        </form>
      </div>
    </section>
  );
};

export default FormSection;
