import { selector } from "recoil";
import { vehiclesListAtom } from "../vehiclesListAtom";

const getVehiclesLogicSelector = selector({
  key: "getVehiclesLogicSelector",
  get: ({ get }) => {
    try {
      const vehicles = get(vehiclesListAtom);
      new Promise((resolve, reject) => {
        const vehiclesList = vehicles.map((res) => res);
        if (!vehiclesList) {
          return reject(new Error("Vehicles not found"));
        }
        resolve(vehiclesList.data);
      });
    } catch (e) {
      console.error("ERROR", e);
    }
  },
});

export default getVehiclesLogicSelector;
