/* eslint-disable no-unused-vars */
import BaseLayout from "./BaseLayout";
import FeaturedVehicles from "./FeaturedVehicles";
import SearchSection from "./SearchSection";
import ServiceSection from "./ServiceSection";
import Button from "./sliderComponents/Button";
import SliderSection from "./SliderSection";
import SubscribeSection from "./SubscribeSection";
import ScrollAnimation from "react-animate-on-scroll";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import { useRecoilState } from "recoil";
import { thisIsVehicleAtom } from "../stateManager/thisIsVehicleAtom";
import { selectedIdAtom } from "../stateManager/reservationAtom";
import { useEffect } from "react";
import {
  searchResultAtom,
  vehiclesListAtom,
} from "../stateManager/vehiclesListAtom";
import { shoppingCartAtom } from "../stateManager/shoppingCartAtom";
import {
  searchStringValueAtom,
  searchValueAtom,
} from "../stateManager/searchValueAtom";

const HomePage = () => {
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  const [isThisVehicle, setIsThisVehicle] = useRecoilState(thisIsVehicleAtom);
  const [vehiclesList] = useRecoilState(vehiclesListAtom);
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);
  const [shoppingCarInfos] = useRecoilState(shoppingCartAtom);
  const [searchResult, setSearchResult] = useRecoilState(searchResultAtom);
  const [state, setState] = useRecoilState(searchStringValueAtom);
  const [value, onChange] = useRecoilState(searchValueAtom);

  useEffect(() => {
    setSelectedId(undefined);
    setSelectedVehicle(null);
    setIsThisVehicle([]);
    setSearchResult([]);
    setState({ value: "" });
    onChange(0);
  }, [
    setIsThisVehicle,
    setSelectedId,
    setSelectedVehicle,
    setSearchResult,
    setState,
    onChange,
  ]);

  return (
    <BaseLayout>
      <div
        className="App"
        data-aos-easing="ease"
        data-aos-duration="800"
        data-aos-delay="0"
      >
        <main className="mt-0">
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button />
          </div>
          <ScrollAnimation offset={0} animateIn="fadeIn">
            <div className="sliderContainer">
              <SliderSection />
            </div>
          </ScrollAnimation>
          <div className="searchContainer">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                <div
                  className="section_title mb_30 text-center aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <ScrollAnimation animateIn="zoomIn">
                    <h2 className="title_text mb_15">
                      <span style={{ fontSize: "42px", marginTop: "25px" }}>
                        Recherche rapide
                      </span>
                    </h2>

                    <p className="mb-0">
                      Rechercher un véhicule avec le prix comme critère...
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <ScrollAnimation animateIn="fadeIn">
              <SearchSection />
            </ScrollAnimation>
          </div>
          <FeaturedVehicles />
          <ServiceSection />
          <SubscribeSection />
        </main>
      </div>
    </BaseLayout>
  );
};

export default HomePage;
