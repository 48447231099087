import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobile } from "react-device-detect";
import AddComparButton from "./addComparButton/AddComparButton";
import ReservedController from "./reservationsComponents/ReservedController";
import useFeaturedVehicles from "../hooks/useFeaturedVehicles";
import React from "react";

const FeaturedVehicles = React.memo(() => {
  const {
    car1ToCompare,
    car2ToCompare,
    showHeader,
    AppearCompareFloatingButton,
    clicked,
    value,
    searchResult,
    state,
    eraseFilter,
    vehicles,
    setSelectedVehicle,
    eraseCar1,
    eraseCar2,
    eraseCar3,
    setEraseCar1,
    setEraseCar2,
    setEraseCar3,
    setShowHeader,
    PicturesAddress,
  } = useFeaturedVehicles();

  return (
    <section className="feature_section sec_ptb_150 clearfix">
      <div className="container">
        {car1ToCompare.carId !== null &&
        car2ToCompare.carId !== null &&
        showHeader ? (
          <div id="infoBande" className="floatingButton">
            <AppearCompareFloatingButton />
          </div>
        ) : null}
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
            <ScrollAnimation animateIn="zoomIn">
              <div
                className="section_title mb_30 text-center "
                data-aos-delay="100"
              >
                <h2 className="title_text mb_15">
                  <span style={{ fontSize: "42px" }}>Véhicules proposés</span>
                </h2>
                <p className="mb-0">
                  Nous proposons une gamme de véhicules exceptionnels !
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        {clicked && value !== 0 && searchResult !== [] ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: isMobile ? "100%" : "36%",
              border: "1px dotted black",
              borderRadius: 8,
              padding: 4,
            }}
          >
            <p className="filterText" style={{ marginRight: 50 }}>
              <strong>Filtre:</strong> {value}€/jour maximum
            </p>{" "}
            <p style={{ fontSize: 13, marginTop: 11, color: "blue" }}>
              {" "}
              Résultat:{searchResult.length}
            </p>
            <button onClick={eraseFilter}>
              <strong
                style={{
                  color: "red",
                  fontSize: 20,
                  marginTop: -20,
                  cursor: "pointer",
                }}
              >
                X
              </strong>
            </button>
          </div>
        ) : null}
        {clicked && searchResult.length > 0 && value === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: isMobile ? "100%" : "45%",
              border: "1px dotted black",
              borderRadius: 8,
              padding: 4,
            }}
          >
            <p className="filterText" style={{ marginRight: 50 }}>
              <strong>Recherche:</strong> {state.value}
            </p>{" "}
            <p style={{ fontSize: 13, marginTop: 11, color: "blue" }}>
              {" "}
              Résultat:{searchResult.length}
            </p>
            <button onClick={eraseFilter}>
              <strong
                style={{
                  color: "red",
                  fontSize: 20,
                  marginTop: -20,
                  cursor: "pointer",
                }}
              >
                X
              </strong>
            </button>
          </div>
        ) : null}
        {clicked &&
        value > 0 &&
        (searchResult.length === 0 || vehicles.length === 0) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <p style={{ marginTop: 10, fontSize: 20, color: "red" }}>
              Aucun véhicule disponibles dans cette fourchette de prix!
            </p>
          </div>
        ) : null}
        <div
          className="feature_vehicle_filter clearfix"
          style={{ position: "relative", height: "auto" }}
        >
          {vehicles.length > 0
            ? vehicles.map((res) => (
                <div
                  key={res.id}
                  className="element-item "
                  data-category="sedan"
                >
                  <ScrollAnimation animateIn="zoomIn">
                    <div
                      onClick={() => setSelectedVehicle(res.id)}
                      className="feature_vehicle_item "
                      data-aos-delay="100"
                    >
                      <AddComparButton
                        eraseCar1={eraseCar1}
                        eraseCar2={eraseCar2}
                        eraseCar3={eraseCar3}
                        setEraseCar1={setEraseCar1}
                        setEraseCar2={setEraseCar2}
                        setEraseCar3={setEraseCar3}
                        setShowHeader={setShowHeader}
                        idVehicle={res.id}
                      />
                      <Link to={"/details"}>
                        <h3 className="item_title mb-0">
                          <p style={{ fontSize: 21 }}>{res.title}</p>
                        </h3>
                        <div className="item_image position-relative">
                          <div className="image_wrap">
                            <img
                              src={`${PicturesAddress}${res.img}`}
                              alt="image_not_found"
                            />
                            <svg
                              width="18%"
                              height="18%"
                              viewBox="0 0 130.05699 135.46693"
                              version="1.1"
                              id="svg5"
                            >
                              <defs id="defs2" />
                              <g
                                id="layer1"
                                transform="translate(-36.410689,-60.916955)"
                              >
                                <g
                                  id="g828"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                  style={{ fill: "#0048b9" }}
                                >
                                  <g id="g826" style={{ fill: "#0048b9" }}>
                                    <path
                                      d="M 256,0 C 120.477,0 10.224,110.255 10.224,245.778 c 0,62.171 23.12,121.186 65.206,166.725 l -13.707,88.342 c -0.558,3.601 0.953,7.208 3.91,9.336 1.677,1.207 3.657,1.82 5.647,1.82 1.52,0 3.046,-0.357 4.447,-1.083 l 79.241,-41.036 c 31.825,14.386 65.788,21.675 101.032,21.675 135.521,0 245.778,-110.255 245.778,-245.778 C 501.778,110.255 391.523,0 256,0 Z m 0,472.211 c -33.973,0 -66.646,-7.347 -97.114,-21.835 -2.731,-1.3 -5.914,-1.245 -8.601,0.146 l -66.524,34.45 11.51,-74.177 c 0.463,-2.982 -0.493,-6.007 -2.582,-8.184 C 51.985,360.238 29.568,304.539 29.568,245.776 29.568,120.922 131.145,19.344 256,19.344 c 124.855,0 226.433,101.577 226.433,226.433 0,124.856 -101.577,226.434 -226.433,226.434 z"
                                      id="path824"
                                      style={{ fill: "#0048b9" }}
                                    />
                                  </g>
                                </g>
                                <g
                                  id="g834"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                  style={{ fill: "#0048b9" }}
                                >
                                  <g id="g832" style={{ fill: "#0048b9" }}>
                                    <path
                                      d="m 256,70.708 c -28.236,0 -51.208,22.972 -51.208,51.208 0,28.236 22.972,51.208 51.208,51.208 28.238,0 51.208,-22.972 51.208,-51.208 0,-28.238 -22.972,-51.208 -51.208,-51.208 z m 0,83.072 c -17.57,0 -31.864,-14.294 -31.864,-31.864 0,-17.57 14.294,-31.864 31.864,-31.864 17.571,0 31.864,14.294 31.864,31.864 0,17.57 -14.294,31.864 -31.864,31.864 z"
                                      id="path830"
                                      style={{ fill: "#0048b9" }}
                                    />
                                  </g>
                                </g>
                                <g
                                  id="g840"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                  style={{ fill: "#0048b9" }}
                                >
                                  <g id="g838" style={{ fill: "#0048b9" }}>
                                    <path
                                      d="M 312.758,357.74 H 301.745 V 199.148 c 0,-5.342 -4.331,-9.672 -9.672,-9.672 h -92.828 c -5.342,0 -9.672,4.331 -9.672,9.672 v 32.143 c 0,5.342 4.331,9.672 9.672,9.672 h 11.013 V 357.74 h -11.013 c -5.342,0 -9.672,4.331 -9.672,9.672 v 32.143 c 0,5.343 4.331,9.673 9.672,9.673 h 113.514 c 5.342,0 9.672,-4.331 9.672,-9.672 v -32.143 c -10e-4,-5.342 -4.332,-9.673 -9.673,-9.673 z m -9.673,32.144 h -94.17 v -12.8 h 11.013 c 5.342,0 9.672,-4.331 9.672,-9.672 V 231.291 c 0,-5.342 -4.331,-9.672 -9.672,-9.672 H 208.915 V 208.821 H 282.4 v 158.593 c 0,5.342 4.331,9.672 9.672,9.672 h 11.013 z"
                                      id="path836"
                                      style={{ fill: "#0048b9" }}
                                    />
                                  </g>
                                </g>
                                <g
                                  id="g842"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g844"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g846"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g848"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g850"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g852"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g854"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g856"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g858"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g860"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g862"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g864"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g866"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g868"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                                <g
                                  id="g870"
                                  transform="matrix(0.26458333,0,0,0.26458333,33.705589,60.916955)"
                                ></g>
                              </g>
                            </svg>
                          </div>

                          <span className="item_price bg_default_blue">
                            {res.price}€/jour
                          </span>
                          <span>
                            <ReservedController res={res} />
                          </span>
                        </div>
                      </Link>
                      <ul
                        style={{ fontSize: "11px !important" }}
                        className="info_list ul_li_center clearfix"
                      >
                        <li>{res.vehicleType}</li>
                        <li>{res.speeds}</li>
                        <li>{res.passensers} personnes</li>
                        <li>{res.fuel}</li>
                      </ul>
                    </div>
                  </ScrollAnimation>
                </div>
              ))
            : null}
          <ScrollAnimation animateIn="slideInUp">
            <div className="abtn_wrap text-center" style={{ opacity: 1 }}>
              <Link
                className="custom_btn bg_default_red btn_width text-uppercase"
                to={"/reservations"}
              >
                <p style={{ fontSize: "18px" }}>Réserver un véhicule</p>{" "}
              </Link>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
});

export default FeaturedVehicles;
