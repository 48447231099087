import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { ASSETS_URL } from "../helpers/fetchUrls";
import { selectedIdAtom } from "../stateManager/reservationAtom";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import { thisIsVehicleAtom } from "../stateManager/thisIsVehicleAtom";
import { vehiclesListAtom } from "../stateManager/vehiclesListAtom";

const useDetailVehicles = () => {
  const [vehiclesList] = useRecoilState(vehiclesListAtom);
  const [isThisVehicle, setIsThisVehicle] = useRecoilState(thisIsVehicleAtom);
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);
  const [selectedVehicle] = useRecoilState(selectedVehicleAtom);
  let navigate = useNavigate();
  const [minified, setMinified] = useState(true);
  const PicturesAddress = `${ASSETS_URL}`;

  useEffect(() => {
    if (selectedVehicle !== null && isThisVehicle.length <= 0) {
      const select = vehiclesList.filter((res) => res.id === selectedVehicle);
      setIsThisVehicle(select);
    }
  }, [
    selectedVehicle,
    vehiclesList,
    isThisVehicle,
    setIsThisVehicle,
    selectedId,
  ]);

  const handleMinified = () => {
    setMinified((minified) => !minified);
  };
  return {
    isThisVehicle,
    minified,
    handleMinified,
    navigate,
    PicturesAddress,
    setSelectedId,
  };
};

export default useDetailVehicles;
