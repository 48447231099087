import { Link } from "react-router-dom";
import Bg11 from "../../assets/images/breadcrumb/bg_11.jpg";

const BreadCrumb = () => {
  return (
    <section className="breadcrumb_section text-center clearfix">
      <div
        className="page_title_area has_overlay d-flex align-items-center clearfix"
        data-bg-image={Bg11}
        style={{ backgroundImage: `url(${Bg11})` }}
      >
        <div className="overlay"></div>
        <div
          className="container aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h1 className="page_title text-white mb-0">Votre panier</h1>
        </div>
      </div>
      <div
        className="breadcrumb_nav clearfix"
        data-bg-color="#F2F2F2"
        style={{ backgroundColor: "rgb(242, 242, 242)" }}
      >
        <div className="container">
          <ul className="ul_li clearfix">
            <li>
              <Link to={"/"}>Accueil</Link>
            </li>
            <li>Votre panier</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
