import { isMobile } from "react-device-detect";
import { useRecoilState } from "recoil";
import { reservationAtom } from "../stateManager/reservationAtom";
import BaseLayout from "./BaseLayout";
import BreadCrumb from "./shoppingCartComponents/BreadCrumb";
import EmptyCart from "./shoppingCartComponents/EmptyCart";
import EmptyCartMobile from "./shoppingCartComponents/EmptyCartMobile";
import ShoppingCartSection from "./shoppingCartComponents/ShoppingCartSection";

const ShoppingCart = () => {
  const [selectedId] = useRecoilState(reservationAtom);
  return (
    <BaseLayout>
      <div
        style={{ marginTop: isMobile && selectedId.length === 0 ? 112 : 80 }}
      >
        {selectedId.length !== 0 ? (
          <>
            <BreadCrumb />
            <ShoppingCartSection />
          </>
        ) : !isMobile ? (
          <EmptyCart text={`Votre panier est vide !`} />
        ) : (
          <EmptyCartMobile />
        )}
      </div>
    </BaseLayout>
  );
};

export default ShoppingCart;
