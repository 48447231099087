import { isMobile } from "react-device-detect";
import ScrollAnimation from "react-animate-on-scroll";

import { useRecoilState } from "recoil";

import { whyPageAtom } from "../../stateManager/whyPageAtom";
import { ASSETS_URL } from "../../helpers/fetchUrls";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  const [whyContent] = useRecoilState(whyPageAtom);
  const PicturesAddress = `${ASSETS_URL}`;
  const {
    titre1,
    titre2,
    titre3,
    titre4,
    titre5,
    titre6,
    sousTitre1,
    sousTitre2,
    sousTitre3,
    sousTitre4,
    sousTitre5,
    sousTitre6,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    img_ordi,
    img_mobile,
    prix,
    titre,
  } = whyContent[0];

  return (
    <section
      className="service_section sec_ptb_150 pb-0 mb_100 text-white clearfix"
      data-bg-gradient="linear-gradient(0deg, #0C0C0F, #292D45)"
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgb(12, 12, 15), rgb(41, 45, 69))",
        transform: "translate(0px, 0vh)",
      }}
    >
      <div className="container">
        <ScrollAnimation animateIn="fadeIn">
          <div
            className="section_title mb_30 text-center aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2 className="title_text text-white mb-0">
              <span>Pourquoi NégoLoc</span>
            </h2>
          </div>
        </ScrollAnimation>
        {whyContent && whyContent[0] && (
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon1}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre1}</h3>
                  <p className="mb-0">{sousTitre1}</p>
                </ScrollAnimation>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon2}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre2}</h3>
                  <p className="mb-0">{sousTitre2}</p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon3}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre3}</h3>
                  <p className="mb-0">{sousTitre3}</p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon4}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre4}</h3>
                  <p className="mb-0">{sousTitre4}</p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon5}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre5}</h3>
                  <p className="mb-0">{sousTitre5}</p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div
                className="service_primary text-center text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {" "}
                <ScrollAnimation animateIn="zoomIn">
                  <div className="item_icon">
                    <i className={`${icon6}`}></i>
                  </div>
                  <h3 className="item_title text-white">{titre6}</h3>
                  <p className="mb-0">{sousTitre6}</p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        )}
        <div className="feature_carousel_wrap position-relative clearfix">
          <div
            className="slideshow1_slider slick-initialized slick-slider slick-dotted aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="slick-list draggable">
              <ScrollAnimation animateIn="zoomIn">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    width: "7700px",
                    transform: "translate3d(-2200px, 0px, 0px)",
                  }}
                >
                  <div
                    className="item slick-slide slick-cloned"
                    data-slick-index="-1"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide"
                    data-slick-index="0"
                    aria-hidden="true"
                    tabIndex="-1"
                    role="tabpanel"
                    id="slick-slide10"
                    aria-describedby="slick-slide-control10"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide slick-current slick-active"
                    data-slick-index="1"
                    aria-hidden="false"
                    tabIndex="0"
                    role="tabpanel"
                    id="slick-slide11"
                    aria-describedby="slick-slide-control11"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide"
                    data-slick-index="2"
                    aria-hidden="true"
                    tabIndex="-1"
                    role="tabpanel"
                    id="slick-slide12"
                    aria-describedby="slick-slide-control12"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide slick-cloned"
                    data-slick-index="3"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide slick-cloned"
                    data-slick-index="4"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slick-slide slick-cloned"
                    data-slick-index="5"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                    style={{ width: "1100px" }}
                  >
                    <div className="feature_fullimage">
                      {!isMobile ? (
                        <img
                          src={`${PicturesAddress}${img_ordi}`}
                          alt="image_not_found"
                        />
                      ) : (
                        <img
                          src={`${PicturesAddress}${img_mobile}`}
                          alt="image_not_found"
                        />
                      )}
                      <div className="item_content text-white">
                        <span className="item_price bg_default_blue">
                          {prix}€/jour
                        </span>
                        <h3 className="item_title text-white">{titre}</h3>
                        <Link
                          to={"/galerie"}
                          className="text_btn text-uppercase"
                          href="#!"
                          tabIndex="-1"
                        >
                          <span>Réserver cette voiture</span>{" "}
                          <img
                            src={require("../../assets/images/icons/icon_02.png")}
                            alt="icon_not_found"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <ul className="slick-dots" role="tablist">
              <li className="" role="presentation">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control10"
                  aria-controls="slick-slide10"
                  aria-label="1 of 3"
                  tabIndex="-1"
                >
                  1
                </button>
              </li>
              <li role="presentation" className="slick-active">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control11"
                  aria-controls="slick-slide11"
                  aria-label="2 of 3"
                  tabIndex="0"
                  aria-selected="true"
                >
                  2
                </button>
              </li>
              <li role="presentation" className="">
                <button
                  type="button"
                  role="tab"
                  id="slick-slide-control12"
                  aria-controls="slick-slide12"
                  aria-label="3 of 3"
                  tabIndex="-1"
                >
                  3
                </button>
              </li>
            </ul>
          </div>
          <div className="carousel_nav">
            <button
              style={{ display: "none" }}
              type="button"
              className="s1_left_arrow slick-arrow"
            >
              <i className="fal fa-angle-left"></i>
            </button>{" "}
            <button
              style={{ display: "none" }}
              type="button"
              className="s1_right_arrow slick-arrow"
            >
              <i className="fal fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
