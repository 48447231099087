/* eslint-disable jsx-a11y/anchor-is-valid */

const BackToTop = () => {
  return (
    <div className="backtotop" style={{ display: "none" }}>
      <a href="#" className="scroll">
        <i className="far fa-arrow-up"></i>
      </a>
    </div>
  );
};

export default BackToTop;
