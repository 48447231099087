import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  currentDate,
  endRentDate,
  startRentDate,
} from "../../helpers/currentTimeVariables";
import CustomConfirm from "../../helpers/CustomConfirm";
import { ASSETS_URL } from "../../helpers/fetchUrls";
import { reservedListAtom } from "../../stateManager/reservationAtom";
import { selectedVehicleAtom } from "../../stateManager/selectedVehicleAtom";
import getVehiclesLogicSelector from "../../stateManager/selectors/getVehiclesLogicSelector";
import { vehiclesListAtom } from "../../stateManager/vehiclesListAtom";

const CheckboxList = ({
  choosedCar,
  setChoosedCar,
  selectedId,
  setSelectedId,
  clickedCars,
  setClickedCars,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  const [vehiclesList] = useRecoilState(getVehiclesLogicSelector);
  const [vehicles, setVehicles] = useRecoilState(vehiclesListAtom);
  let navigate = useNavigate();
  const { JSalert } = CustomConfirm();
  const [reservedList] = useRecoilState(reservedListAtom);
  const PicturesAddress = `${ASSETS_URL}`;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (vehiclesList !== undefined) {
      setVehicles(vehiclesList.data);
    }

    return () => {
      controller.abort(signal);
    };
  }, [vehicles, vehiclesList, setVehicles]);

  const handleselectedCars = () => {
    setClickedCars((clickedCars) => !clickedCars);
  };

  function handleId(id) {
    if (selectedId !== id) {
      setSelectedId(id);
    }

    return selectedId;
  }

  const ControlChoiceButton = ({ result }) => {
    const isRes = reservedList
      .filter((res) => result.title === res.title)
      .map(
        (res) =>
          currentDate >= res.startDate - startRentDate &&
          currentDate <= res.endDate + endRentDate
      );

    return isRes[0] !== true ? (
      <>
        <span
          onClick={() =>
            choosedCar !== result.title
              ? setChoosedCar(result.title)
              : JSalert({
                  title: "Véhicule déjà ajouté...",
                  htmlContent: `
                    <div>
                        <strong>Vous avez déjà choisi: ${choosedCar}</strong>,
                    </div>`,
                  footerText: `<div style="font-size:17px; color:red">Veuillez remplir le formulaire pour achever votre réservation!</div>`,
                })
          }
          className="choiceButton bg_default_red"
          style={
            choosedCar === result.title
              ? {
                  opacity: 1,
                  backgroundColor: "#ea001e",
                  fontWeight: "bold",
                  fontSize: 20,
                }
              : { opacity: 1 }
          }
        >
          {choosedCar !== result.title ? "Choisir" : "Confirmé"}
        </span>
        <span className="item_price bg_default_blue">{result.price}€/jour</span>
      </>
    ) : (
      <>
        <span
          className="choiceButton bg_default_red"
          style={{
            opacity: 1,
            backgroundColor: "#ea001e",
            fontWeight: "bold",
            fontSize: 20,
          }}
        >
          Déjà loué
        </span>
        <span className="item_price bg_default_blue">{result.price}€/jour</span>
      </>
    );
  };

  useEffect(() => {
    if (selectedId !== undefined && !clickedCars) {
      setClickedCars(true);
    }
  }, [selectedId, clickedCars, setClickedCars]);

  return (
    <div className="form-container">
      {vehicles.map((result) => (
        <div
          onClick={() => {
            handleselectedCars();
            handleId(result.id);
          }}
          key={result.id}
          className="checkbox-container"
        >
          <input
            checked={selectedId === result.id ? true : false}
            type="checkbox"
            id={result.id}
            onChange={() => handleId(result.id)}
          />
          <label className="checkbox" htmlFor={result.id}>
            {result.title}
          </label>
          {selectedId === result.id ? (
            <div
              className="feature_vehicle_item checkboxImg mt-0 ml-0 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 className="item_title mb-0">
                <p
                  style={{
                    fontSize: 22,
                    color: choosedCar !== "" ? "red" : "black",
                  }}
                >
                  {result.title}
                </p>
              </h3>
              <div className="item_image position-relative">
                <button
                  className="image_wrap"
                  onClick={() => {
                    setSelectedVehicle(result.id);
                    navigate("/details");
                  }}
                >
                  <img
                    src={`${PicturesAddress}${result.img}`}
                    alt="image_not_found"
                  />
                  {choosedCar === result.title ? (
                    <div className="overlay-choosedCar" />
                  ) : null}
                </button>
                <ControlChoiceButton result={result} />
                <span className="item_price bg_default_blue">
                  {result.price}€/jour
                </span>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
