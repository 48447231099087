export const toTimestamp = (strDate) => {
  const dt = new Date(strDate).getTime();
  return dt / 1000;
};

export function timeStringToMs(t) {
  let ms =
    Number(t.split(":")[0]) * 60 * 60 * 1000 +
    Number(t.split(":")[1]) * 60 * 1000;
  return ms;
}
