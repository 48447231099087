import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  clickedSearchAtom,
  searchValueAtom,
} from "../stateManager/searchValueAtom";
import {
  searchResultAtom,
  vehiclesListAtom,
} from "../stateManager/vehiclesListAtom";
import SliderRange from "./sharedComponents/SliderRange";

const SearchSection = () => {
  let navigate = useNavigate();
  const [vehiclesList] = useRecoilState(vehiclesListAtom);
  const [value] = useRecoilState(searchValueAtom);
  // eslint-disable-next-line no-unused-vars
  const [searchResult, setSearchResult] = useRecoilState(searchResultAtom);
  const [clicked, setClicked] = useRecoilState(clickedSearchAtom);

  useEffect(() => {
    setClicked(false);
    if (value !== 0) {
      const result = vehiclesList.filter((res) => res.price <= value);
      setSearchResult(result);
    }
  }, [vehiclesList, value, setSearchResult, clicked, setClicked]);

  return (
    <section className="search_section clearfix">
      <div className="container searchComponent">
        <div
          className="row align-items-center"
          style={{
            backgroundColor: "rgb(22, 24, 41)",
            padding: "41px",
          }}
        >
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="form_item">
              <h4 className="input_title text-white">Lieu de retrait</h4>
              <div className="position-relative">
                <input
                  id="location_two"
                  type="text"
                  name="location"
                  placeholder="Chez Négoloc"
                  readOnly
                />{" "}
                <label htmlFor="location_two" className="input_icon">
                  <i className="fas fa-map-marker-alt"></i>
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="form_item">
              <h4 className="input_title text-white">Choisir une date</h4>
              <input type="date" name="date" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="price-range-area clearfix">
              <h4 className="input_title text-white">Prix</h4>
              <SliderRange />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <button
              onClick={() => {
                setClicked(true);
                navigate("/galerie");
              }}
              className="custom_btn bg_default_red text-uppercase"
            >
              <div style={{ fontSize: "10px", marginTop: "-1vh" }}>
                {" "}
                Trouver un véhicule{" "}
              </div>
              <img
                style={{ marginTop: "-8vh" }}
                src={require("../assets/images/icons/icon_01.png")}
                alt="icon_not_found"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchSection;
