import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Logo from "../assets/images/logo/61768dc2c8290.png";

const CustomConfirm = () => {
  const MySwal = withReactContent(Swal);

  function JSalert({
    title,
    htmlContent,
    footerText,
    showCancelButton,
    showConfirmButton,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
    timer,
  }) {
    MySwal.fire({
      position: "top",
      title: `${title}`,
      html: `${htmlContent}`,
      text: "",
      imageUrl: Logo,
      imageWidth: 200,
      imageHeight: 100,
      imageAlt: "Custom image",
      footer: `${footerText}`,
      showCancelButton: showCancelButton,
      showConfirmButton: showConfirmButton,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      timer: timer,
    });
  }
  return { JSalert };
};

export default CustomConfirm;
