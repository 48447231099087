const { useRecoilState } = require("recoil");
const {
  currentDate,
  startRentDate,
  endRentDate,
} = require("../../helpers/currentTimeVariables");
const { reservedListAtom } = require("../../stateManager/reservationAtom");

const ControlRentButton = ({ res, setSelectedId, navigate }) => {
  const [reservedList] = useRecoilState(reservedListAtom);
  const isRes = reservedList
    .filter((result) => res.title === result.title)
    .map(
      (result) =>
        currentDate >= result.startDate - startRentDate &&
        currentDate <= result.endDate + endRentDate
    );

  return isRes[0] !== true ? (
    <button
      key={res.id}
      onClick={() => {
        setSelectedId(res.id);
        navigate("/reservations");
      }}
      className="custom_btn btnReserveDetail btn_width bg_default_red text-uppercase slideDetailsCarButton"
      tabIndex="-1"
    >
      Réservez ce véhicule
      <img
        src={require("../../assets/images/icons/icon_01.png")}
        alt="icon_not_found"
      />
    </button>
  ) : (
    <button
      key={res.id}
      className="custom_btn btn_width  text-uppercase slideDetailsCarButton"
      tabIndex="-1"
      style={{ backgroundColor: "#e9e9e9", color: "#ea001e" }}
    >
      Déjà loué
    </button>
  );
};

export default ControlRentButton;
