import { useRecoilState } from "recoil";
import {
  carCompare1Atom,
  carCompare2Atom,
  carCompare3Atom,
} from "../../stateManager/comparatorAtom";

const AddComparButton = ({
  idVehicle,
  setShowHeader,
  eraseCar1,
  eraseCar2,
  eraseCar3,
  setEraseCar1,
  setEraseCar2,
  setEraseCar3,
}) => {
  const [car1ToCompare, setCar1ToCompare] = useRecoilState(carCompare1Atom);
  const [car2ToCompare, setCar2ToCompare] = useRecoilState(carCompare2Atom);
  const [car3ToCompare, setCar3ToCompare] = useRecoilState(carCompare3Atom);

  const handleCarsToCompare = () => {
    if (
      car1ToCompare.carId === null &&
      car2ToCompare.carId === null &&
      car3ToCompare.carId === null
    ) {
      setCar1ToCompare({ carId: idVehicle });
      setShowHeader(true);
    } else if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId === null &&
      car3ToCompare.carId === null
    ) {
      setCar2ToCompare({ carId: idVehicle });
      setShowHeader(true);
    } else if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId !== null &&
      car3ToCompare.carId === null
    ) {
      setCar3ToCompare({ carId: idVehicle });
      setShowHeader(true);
    }
  };

  const handleDeleteCarFormCompareList = () => {
    if (car1ToCompare.carId === idVehicle) {
      setEraseCar1(true);
    } else if (car2ToCompare.carId === idVehicle) {
      setEraseCar2(true);
    } else if (car3ToCompare.carId === idVehicle) {
      setEraseCar3(true);
    }
  };

  return (
    <span className="compareButton" data-compare-id="4180">
      <button
        disabled={
          car1ToCompare.carId === idVehicle ||
          car2ToCompare.carId === idVehicle ||
          car3ToCompare.carId === idVehicle
            ? true
            : false
        }
        key={idVehicle}
        className="car-action-unit add-to-compare"
        to={"/compare"}
        data-id="4180"
        data-action="add"
        data-post-type="listings"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "65%",
          height: 45,
          opacity:
            car1ToCompare.carId === idVehicle ||
            car2ToCompare.carId === idVehicle ||
            car3ToCompare.carId === idVehicle
              ? 0.8
              : 1,
        }}
        onClick={handleCarsToCompare}
      >
        <img
          src={require("../../assets/images/icons/two-cars-in-line.png")}
          style={{
            width: 28,
            height: 28,
            marginRight:
              car1ToCompare.carId === idVehicle ||
              car2ToCompare.carId === idVehicle ||
              car3ToCompare.carId === idVehicle
                ? 3
                : 10,
          }}
          alt="icon"
        />
        {(car1ToCompare.carId === idVehicle && !eraseCar1) ||
        (car2ToCompare.carId === idVehicle && !eraseCar2) ||
        (car3ToCompare.carId === idVehicle && !eraseCar3)
          ? "Dans la liste de comparaison"
          : "Ajouter au comparateur"}
        {(car1ToCompare.carId === idVehicle && !eraseCar1) ||
        (car2ToCompare.carId === idVehicle && !eraseCar2) ||
        (car3ToCompare.carId === idVehicle && !eraseCar3) ? (
          <span
            onClick={() => {
              handleDeleteCarFormCompareList();
            }}
            type="button"
            className="remove_btn"
            style={{ marginLeft: "28px" }}
          >
            <i
              className="fal fa-times"
              style={{ color: "red", fontSize: "24px" }}
            ></i>
          </span>
        ) : null}
      </button>
    </span>
  );
};

export default AddComparButton;
