import ScrollAnimation from "react-animate-on-scroll";

const InfosContact = () => {
  return (
    <section className="contact_section clearfix">
      <div
        className="contact_details_wrap text-white aos-init aos-animate"
        data-bg-color="#1F2B3E"
        data-aos="fade-up"
        data-aos-delay="100"
        style={{
          backgroundColor: "rgb(31, 43, 62)",
          marginTop: "0.5vh",
          borderRadius: 0,
        }}
      >
        <ScrollAnimation animateIn="zoomIn">
          <div className="row justify-content-lg-between">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="image_area">
                <div className="brand_logo mb_15">
                  <a href="index.html">
                    <img
                      src={require("../../assets/images/logo/logo_01_1x.png")}
                      srcSet={require("../../assets/images/logo/logo_01_2x.png")}
                      alt="logo_not_found"
                    />
                  </a>
                </div>
                <p className="mb_30">
                  Mauris dignissim condimentum viverra. Curabitur blandit eu
                  justo id porta
                </p>
                <div className="image_wrap">
                  <img
                    src={require("../../assets/images/about/img_02.jpg")}
                    alt="image_not_found"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 contactArea">
              <div className="content_area">
                <h3 className="item_title text-white mb_30">
                  Coordonnées de contact :
                </h3>
                <ul className="ul_li_block mb_30 clearfix">
                  <li>
                    <i className="fas fa-map-marker-alt"></i> Unit 9, Manor
                    Industrial Estate, Lower Wash Lane, Warrington, WA4
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> 8:00 - 19:30
                  </li>
                  <li>
                    <i className="fas fa-phone"></i> 06-55-55-55-55
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i> negoloc37@email.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  );
};

export default InfosContact;
