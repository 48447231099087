import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  carCompare1Atom,
  carCompare2Atom,
  carCompare3Atom,
  optionsSupp1Atom,
  optionsSupp2Atom,
  optionsSupp3Atom,
} from "../stateManager/comparatorAtom";
import { vehiclesListAtom } from "../stateManager/vehiclesListAtom";

const useMoreOtions = () => {
  const [vehicles] = useRecoilState(vehiclesListAtom);
  const [car1ToCompare, setCar1ToCompare] = useRecoilState(carCompare1Atom);
  const [car2ToCompare, setCar2ToCompare] = useRecoilState(carCompare2Atom);
  const [car3ToCompare, setCar3ToCompare] = useRecoilState(carCompare3Atom);
  const [otionsSupp1, setOptionsSupp1] = useRecoilState(optionsSupp1Atom);
  const [otionsSupp2, setOptionsSupp2] = useRecoilState(optionsSupp2Atom);
  const [otionsSupp3, setOptionsSupp3] = useRecoilState(optionsSupp3Atom);

  useEffect(() => {
    if (vehicles) {
      if (car1ToCompare.carId !== null) {
        const moreOptionsList1 = vehicles
          .filter((res) => res.id === car1ToCompare?.carId)
          .map((result) => result?.optionsSupplementaires)
          .map((f) => f);
        setOptionsSupp1(moreOptionsList1);
      }

      if (car2ToCompare.carId !== null) {
        const moreOptionsList2 =
          vehicles &&
          vehicles
            .filter((res) => res.id === car2ToCompare.carId)
            .map((result) => result?.optionsSupplementaires)
            .map((f) => f);
        setOptionsSupp2(moreOptionsList2);
      }

      if (car3ToCompare.carId !== null) {
        const moreOptionsList3 =
          vehicles &&
          vehicles
            .filter((res) => res.id === car3ToCompare.carId)
            .map((result) => result?.optionsSupplementaires)
            .map((f) => f);
        setOptionsSupp3(moreOptionsList3);
      }
    }
  }, [
    car1ToCompare,
    car2ToCompare,
    car3ToCompare,
    setOptionsSupp1,
    setOptionsSupp2,
    setOptionsSupp3,
    vehicles,
  ]);
  return {
    vehicles,
    otionsSupp1,
    otionsSupp2,
    otionsSupp3,
    setOptionsSupp1,
    setOptionsSupp2,
    setOptionsSupp3,
    car1ToCompare,
    car2ToCompare,
    car3ToCompare,
    setCar1ToCompare,
    setCar2ToCompare,
    setCar3ToCompare,
  };
};

export default useMoreOtions;
