import Breadcrumb from "./aboutComponents/Breadcrumb";
import FunFactSection from "./aboutComponents/FunFactSection";
import GallerySection from "./aboutComponents/GallerySection";
import ServiceSection from "./aboutComponents/ServiceSection";
import BaseLayout from "./BaseLayout";

const AboutPage = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <Breadcrumb />
        <ServiceSection />
        <GallerySection />
        <FunFactSection />
      </div>
    </BaseLayout>
  );
};

export default AboutPage;
