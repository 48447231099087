import { Link } from "react-router-dom";
import Bg08 from "../assets/images/breadcrumb/bg_08.jpg";
import BaseLayout from "../components/BaseLayout";
const ConditionsGenerales = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <section className="breadcrumb_section text-center clearfix">
          <div
            className="page_title_area has_overlay d-flex align-items-center clearfix"
            data-bg-image={Bg08}
            style={{ backgroundImage: `url(${Bg08})` }}
          >
            <div className="overlay"></div>
            <div
              className="container aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h1 className="page_title text-white mb-0">
                CONDITIONS GÉNÉRALES DE LOCATION
              </h1>
            </div>
          </div>
          <div
            className="breadcrumb_nav clearfix"
            data-bg-color="#F2F2F2"
            style={{ backgroundColor: "rgb(242, 242, 242)" }}
          >
            <div className="container">
              <ul className="ul_li clearfix">
                <li>
                  <Link to={"/"}>Accueil</Link>
                </li>

                <li>Conditions générales de location</li>
              </ul>
            </div>
          </div>
        </section>
        <div
          className="container"
          style={{
            marginTop: 50,
            paddingBottom: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "100%",
          }}
        >
          <div>
            Le présent document expose les droits et obligations du Loueur et du
            Locataire concernant la mise à disposition du véhicule de tourisme
            ou utilitaire identifié dans les Conditions Particulières.
          </div>
          <div>
            Les présentes Conditions Générales de Location constituent avec les
            Conditions Particulières, dont le Locataire reconnaît avoir reçu un
            exemplaire, un document unique appelé "Contrat de Location" ; le
            Locataire s’engageant à respecter les dispositions de ce document
            unique.
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 1 - ÉTAT DU VÉHICULE{" "}
            </div>
            <div>
              Le Loueur déclare que le véhicule est en bon état de marche, muni
              des accessoires d’origine et éventuellement des équipements
              optionnels (siège bébé,...) tels que définis dans les Conditions
              Particulières. Le véhicule loué est sans dommage apparent à
              l’exception de ceux identifiés et précisés sur les silhouettes des
              véhicules figurant sur l’état descriptif annexé aux Conditions
              Particulières. Un état descriptif du Véhicule est joint à votre
              Contrat de Location. Le Locataire s’engage à y consigner par
              écrit, avant votre départ de la station, toute défectuosité
              apparente qui n'y figurerait pas. À défaut, le Loueur est réputé
              avoir délivré un véhicule conforme à l'état descriptif. le
              Locataire peut s’assurer de l’état du véhicule en procédant à un
              essai qui ne peut être supérieur à 4 kilomètres. Tout dépassement
              de cette distance permettra au Loueur de considérer que le
              Locataire accepte le véhicule qui sera dès lors considéré comme
              exempt de défauts apparents de fonctionnement.
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 2 - CONDUITE ET UTILISATION DU VÉHICULE
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              a) Obligations du Locataire :{" "}
            </div>
            <div>
              À compter de la mise à disposition du véhicule, le Locataire est
              seul responsable du véhicule et des conséquences pouvant résulter
              de son utilisation. Le véhicule ne peut être conduit que par toute
              personne préalablement et expressément agréée par le Loueur et
              identifié(s) aux Conditions Particulières. Le Locataire ou tout
              conducteur agréé par le Loueur doit être âgé d’au moins 23 ans et
              être titulaire d’un permis de conduire B en cours de validité d’au
              moins 3 ans de permis. Attention : les franchises Responsabilité
              Civile, dommages et vol seront doublées si l’un des conducteurs a
              moins de 25 ans, y compris en cas de rachat partiel de franchises.
              Pour les catégories , C Premium et D Premium, le Locataire ou tout
              conducteur autorisé devra être âgé d’au moins 25 ans et être
              titulaire d’un permis de conduire depuis au moins 5 ans. Pour les
              catégories à partir de la E Premium, le Locataire ou tout
              conducteur autorisé devra être âgé d’au moins 30 ans et être
              titulaire d’un permis de conduire depuis au moins 5 ans. Le
              Locataire est seul responsable des infractions au Code de la Route
              ayant trait à la conduite ou à la garde du véhicule, ou de toute
              autre infraction à des dispositions législatives, fiscales et
              réglementaires en vigueur, commises pendant la durée du contrat, à
              l’exception toutefois de celles qui ne seraient pas légalement à
              sa charge. Le Locataire s’engage à prendre toutes les dispositions
              pour éviter la détérioration, le vol ou la soustraction
              frauduleuse du véhicule. Il s’engage notamment, lorsque le
              véhicule est en stationnement, à mettre en service le système
              d’alarme éventuellement livré avec le véhicule, à verrouiller les
              portes et glaces et à ne pas y laisser les documents du véhicule
              ou des objets ou effets personnels apparents. Le Locataire utilise
              le véhicule dans le strict respect des normes et recommandations
              du constructeur, en « bon père de famille » et notamment, à ne pas
              être sous influence éthylique ou narcotique ou de toute substance
              susceptible d'affecter la conduite conformément aux dispositions
              du Code de la route. Le Locataire s’engage à prendre toutes
              précautions que le Loueur est en droit d’attendre. Nous attirons
              tout particulièrement votre attention sur les dimensions des
              Véhicules Utilitaires (figurant à l'intérieur de l'habitacle et/ou
              sur la fiche "état du véhicule" pour la hauteur) qui obligent à
              une attention accrue lors de certaines manœuvres (marche arrière
              par exemple) et peuvent rendre impossible le franchissement de
              certaines infrastructures routières (tunnels, ponts, etc.), dont
              la hauteur maximum est, suivant la réglementation en vigueur,
              signalée en avance.
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              b) Interdiction
            </div>
            <div>
              Le Locataire s’engage à ne rien modifier ou adjoindre au véhicule
              ou à ses équipements (exemple : attelage de remorque). Le présent
              contrat exclut expressément l’usage du véhicule pour la
              sous-location, pour le transport de passagers à titre onéreux,
              pour le remorquage ou la traction de tout objet ou pour
              l’apprentissage de la conduite. Le véhicule ne peut être utilisé,
              sans autorisation préalable du Loueur mentionnée sur les
              Conditions Particulières, que sur le territoire de la Suisse,
              Andorre, Monaco, Liechtenstein et dans les pays de l’Union
              Européenne à l’exception de : Chypre, Estonie, Hongrie, Lettonie,
              Lituanie, Malte, Pologne, République Tchèque, Slovaquie, Slovénie,
              Italie, Bulgarie et Roumanie. Attention : en cas de violation de
              cette clause, le Locataire sera responsable, en particulier en cas
              de dommages ou de vol du véhicule, à concurrence de la valeur du
              véhicule à dire d’expert et de la perte d’exploitation subie par
              le Loueur.
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              c) Entretien du véhicule – problème mécanique{" "}
            </div>
            <div>
              Au cours de la location, le Locataire doit procéder régulièrement
              à la vérification des niveaux de lubrifiant et de liquide de
              refroidissement moteur, de liquide de frein et lave-glace en
              fonction du signalement des témoins lumineux et selon les
              prescriptions du carnet d’entretien du constructeur qu’il
              reconnaît avoir reçu avec le véhicule. À ce titre, le Locataire
              restera vigilant à tout signal émis par les voyants d'alerte
              apparaissant sur le tableau de bord du Véhicule, le cas échéant,
              et prendra toutes les mesures conservatoires nécessaires, s'il y a
              lieu, telles que l'arrêt d'urgence. Le Véhicule vous est fourni
              avec des pneumatiques dont l'état et le nombre sont conformes à la
              règlementation routière. En cas de détérioration de l'un d'entre
              eux pour une cause autre que l'usure normale, vice caché ou cas de
              force majeure, leur remplacement par un pneumatique de même
              dimension, même type, même marque, et d'usure égale sera à la
              charge du Locataire. Toute autre intervention, notamment,
              transformation ou intervention mécanique sur le Véhicule est
              interdite sans autorisation préalable du Loueur. Le locataire sera
              responsable des dégradations ou des pertes subies par le véhicule
              loué, sauf à prouver qu’elles ont eu lieu sans sa faute, et ce
              conformément à l’article 1732 du Code Civil.
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 3 - DÉPÔT DE GARANTIE - DOCUMENTS À FOURNIR
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              a) Dépôt de garantie
            </div>
            <div>
              Le dépôt de garantie, dont le montant correspond à la plus élevée
              des franchises, est déposé à la signature du contrat par le
              Locataire. Il garantit la bonne exécution des obligations mises à
              la charge du Locataire (respect des interdictions et des
              obligations). Il sera restitué en fin de contrat si aucune somme
              n’est due au Loueur. À défaut, le Locataire autorise expressément
              le Loueur à prélever, en les justifiant, les sommes restant dues
              sur ce dépôt.
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              b) Documents à fournir
            </div>
            <div>
              Pour un particulier : Carte de crédit (excepté : Electron, Aurore
              et Maestro) · Pièces d’identité · Permis de conduire original
              (excepté : déclaration de Perte ou de vol) · Justificatif récent
              de domicile. Pour une société : Carte de crédit (excepté :
              Electron, Aurore et Maestro) · Bon de commande, daté et signé par
              le représentant légal, indiquant le(s) conducteur(s) agréé(s) ·
              Extrait K BIS de moins de 3 mois du Locataire · Permis de conduire
              original du ou des conducteurs désignés par la société (excepté :
              déclaration de Perte ou de vol).
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 4 - DURÉE DE LA LOCATION{" "}
            </div>
            <div>
              La location est consentie pour la durée déterminée aux Conditions
              Particulières. Sauf stipulation contraire figurant dans les
              Conditions Particulières, la durée minimale de location est de 24
              heures. Une franchise de 59 minutes supplémentaires est accordée,
              au-delà, une journée complète sera facturée aux Conditions
              Particulières.
            </div>
            <div>
              Attention : le Locataire doit restituer le véhicule aux dates et
              heures prévues. Tout dépassement de la durée de la location qui ne
              serait pas accepté préalablement par le Loueur pourrait constituer
              un détournement pouvant exposer le Locataire à des sanctions
              pénales et civiles.
            </div>
            <div>
              Le Loueur se réserve le droit de refuser toute demande de
              prolongation anormale ou faite de mauvaise foi.
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 5 – FIN DE LOCATION - MODALITÉS DE LA RESTITUTION{" "}
            </div>
            <div>
              La location se termine par la restitution du Véhicule, de ses
              clefs et de ses papiers au comptoir du Loueur, à un agent NégoLoc,
              à l’exclusion de toute autre personne. Dans l'hypothèse où le
              Véhicule serait restitué sans ses clés, celles-ci seront facturées
              au Locataire ainsi que, s'il y a lieu, les frais de rapatriement
              du Véhicule. Le Loueur ne peut en aucune façon être tenu
              responsable des biens qui auraient été oubliés dans le Véhicule à
              l'issue de la location. Le véhicule doit être restitué dans un
              état identique à celui constaté contradictoirement par le
              Locataire au départ de la location. En cas de contestation sur les
              détériorations relevées, le Locataire autorise expressément le
              Loueur à choisir un expert automobile indépendant aux fins
              d’examiner le véhicule et d’établir un rapport descriptif et
              estimatif ; les frais de la mission de l’expert étant à la charge
              du Locataire. Le véhicule devra impérativement être restitué
              pendant les heures d’ouverture de l’agence.
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 6 - SOMMES DUES
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              Le Locataire paiera au Loueur :
            </div>
            <div>
              1) Le loyer mentionné aux Conditions Particulières pour la
              location du véhicule mis à disposition comprenant le coût des
              assurances selon le tarif en vigueur au jour de la location. Le
              prix est calculé en fonction d’une catégorie de véhicule, d’une
              durée et d’un nombre de kilomètres. Le nombre de kilomètres
              parcourus pendant la durée du contrat étant la différence entre le
              kilométrage d’arrivée et celui constaté lors de la mise à
              disposition du véhicule mentionnés sur le compteur installé sur le
              véhicule par le fabricant. Sauf stipulation contraire figurant
              dans les Conditions Particulières, les dépassements horaires
              entraîneront la facturation d’au moins une journée supplémentaire
              au tarif journalier général en vigueur. Attention : En cas de
              débranchement volontaire du compteur, le véhicule sera réputé
              avoir parcouru une distance de 500 kilomètres par jour depuis la
              date de sa mise à disposition, le Locataire restant dans ce cas
              responsable envers le Loueur de toutes conséquences préjudiciables
              résultant pour ce dernier d’une telle dissimulation et des frais
              de remise en état.{" "}
            </div>
            <div>
              2) Toute somme complémentaire pour la fourniture d’équipements
              optionnels mentionnés dans les Conditions Particulières.{" "}
            </div>
            <div>
              3) Toutes les contraventions et amendes mises à la charge du
              Locataire en raison des violations des règles du Code de la Route.
              Dans ce cas le loueur facturera des frais de gestion d’un montant
              de 15 € par contravention ou amende.{" "}
            </div>
            <div>4) Tous impôts et taxes dus sur les paiements susvisés. </div>
            <div>
              5) Des frais de carburant manquant qui sont à la charge du
              Locataire. Le niveau de carburant est établi contradictoirement
              lors de la mise à disposition et à la restitution en fonction de
              l’indication de la jauge du véhicule. Aucun remboursement au titre
              du carburant éventuellement excédentaire à la restitution par
              rapport à la mise à disposition ne sera effectué. La différence de
              niveau de carburant entre la mise à disposition et la restitution
              sera facturée au Locataire au prix en vigueur et affiché en agence
              au moment du retour du véhicule.{" "}
            </div>
            <div>
              6) En cas de panne ou d’accident sans avoir fait appel à
              l’Assistance prévue à l’article 8.d ci-après, les frais de
              stationnement, de dépannage, de gardiennage et de péage ainsi que
              ceux engagés pour rapatrier le véhicule du lieu du dépôt jusqu’à
              l’agence de location mentionnée aux Conditions Particulières.{" "}
            </div>
            <div>
              7) Les frais de stationnement, de péage, de gardiennage, ainsi que
              de ceux engagés pour le retour du véhicule du lieu de restitution
              jusqu’à l’agence de location mentionnée aux Conditions
              Particulières.{" "}
            </div>
            <div>
              8) Les franchises d’assurances, les frais d’expertise et de
              réparation du véhicule pour les dommages non couverts par
              l’assurance ainsi que les pertes d’exploitation du Loueur pendant
              le temps d’immobilisation du véhicule.{" "}
            </div>
            <div>
              9) Les Frais de gestion d’un montant de 50 € lors de la survenance
              de tout sinistre responsable ou sans recours et en l’absence de
              souscription à l’option rachat de franchise.{" "}
            </div>
            <div>
              10) Les réparations inférieures à la franchise Dommages indiquée
              aux Conditions Particulières seront arrêtées sur la base d’un
              devis établi par un carrossier indépendant agréé par NégoLoc.{" "}
            </div>
            <div>
              11) L’intégralité du préjudice subit par le Loueur dans les cas
              d’exclusion ou de déchéance de garantie tels que prévus à
              l’article 10 ci-après.{" "}
            </div>
            <div>
              12) Le dépôt de garantie indiqué aux Conditions Particulières sera
              restitué au Locataire en fin de contrat si celui-ci a satisfait à
              toutes ses obligations au sens du présent contrat. A défaut, il
              sera affecté, pour tout ou partie, au paiement de toute somme
              énumérée ci-dessus due au Loueur par le Locataire.{" "}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 7 - MODALITÉS DE PAIEMENT, FACTURATION
            </div>
            <div>
              A la mise à disposition du véhicule, le Locataire effectuera un
              prépaiement correspondant au montant estimé de la location et
              indiqué dans les Conditions Particulières. A la fin de la location
              une facturation sera établie. Toutefois le Loueur peut adresser au
              Locataire une facturation intermédiaire si la location excède un
              mois. Les factures sont établies en Euro et sont payables comptant
              à réception de la facture, déduction faite du montant du
              prépaiement. A défaut de règlement du solde éventuellement dû par
              le Locataire, et après expiration d’un délai de 30 jours décompté
              à partir de la date de facture et d’une mise en demeure restée
              sans effet, le Loueur appliquera des pénalités au taux de
              l’intérêt légal majoré de 5 points.{" "}
            </div>
            <div>
              Le Locataire accepte expressément que tout défaut de paiement ou
              d’impayé entraîne :{" "}
              <ul>
                <li>
                  la déchéance du terme pour les factures non échues et la
                  résiliation du contrat de plein droit,{" "}
                </li>
                <li>
                  la restitution immédiate des véhicules en cours de location.{" "}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 8 - ASSURANCES
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              a) Responsabilité civile{" "}
            </div>
            <div>
              Le Locataire et le(s) conducteur(s) supplémentaire(s) du véhicule
              désigné(s) aux Conditions Particulières et agréé(s) par le Loueur,
              conformément à l’article 2 ci-dessus, bénéficient d’une police
              d’assurance automobile satisfaisant à l’obligation prescrite à
              l’article L. 211-1 du Code des Assurances et couvrant les dommages
              matériels et corporels qu’il pourrait causer à des tiers en ou
              hors circulation avec le véhicule loué.{" "}
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              b) Dommages subis par le véhicule loué{" "}
            </div>
            <div>
              Le Locataire est garanti :
              <ul>
                <li>
                  • contre le vol, l’incendie et le vandalisme du véhicule,
                  déduction faite de la franchise{" "}
                </li>
                <li>
                  • vol/incendie prévue aux Conditions Particulières pour la
                  catégorie du véhicule loué.{" "}
                </li>
                <li>
                  • pour les dommages consécutifs à un accident, une explosion,
                  les dommages occasionnés au véhicule du fait de forces de la
                  nature ou de catastrophes naturelles, déduction faite de la
                  franchise dommage précisée aux Conditions Particulières si le
                  Locataire est déclaré responsable de l’accident ou s’il
                  n’existe pas de recours contre un tiers identifié.{" "}
                </li>
              </ul>
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              c) Défense Recours - Individuelle Accident - Conducteur et
              Passagers{" "}
            </div>
            <div>
              En complément des garanties accordées à l’alinéa a), le Locataire
              est également assuré :
              <ul>
                <li>
                  • pour sa Défense à l’amiable et devant les tribunaux par
                  suite d’accident mettant en jeu sa responsabilité et pour le
                  Recours de ses préjudices subis lors d’un accident non
                  responsable,
                </li>
                <li>
                  • pour un capital Décès et Invalidité Permanente Totale de 12
                  195 € (réductible selon le barème de Droit Commun)
                  garantissant le conducteur et les passagers à l’occasion d’un
                  accident survenu avec le véhicule loué.{" "}
                </li>
              </ul>
              <div>
                Attention : le Locataire reconnaît avoir été dûment averti :{" "}
                <ul>
                  <li>
                    • que toute fausse déclaration relative au permis de
                    conduire et à son âge autoriserait le Loueur ou son assureur
                    à exercer un recours contre le Locataire pour obtenir le
                    remboursement de sommes versées aux tiers au titre des
                    garanties assurées à l’alinéa a) et entraînera la déchéance
                    des garanties mentionnées aux alinéas b) et c),
                  </li>
                  <li>
                    • de l’existence de cas ou de circonstances entraînant
                    l’exclusion ou la déchéance des garanties et assurances
                    prévus à l’article 10 ci-dessous.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              d) Assistance au véhicule, au conducteur et aux passagers{" "}
            </div>
            <div>
              Une garantie d’assistance au véhicule et aux personnes circulant à
              bord du véhicule loué en cas de panne mécanique ou d’accident est
              également acquise au Locataire (sauf dans les cas d’erreur de
              carburant ou de crevaison du véhicule). Les coordonnées
              téléphoniques de l’Assistance à contacter sont mentionnées sur les
              Conditions Particulières.{" "}
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>e) Franchise</div>
            <div>
              En cas de sinistre responsable ou sans possibilité de recours
              contre un tiers identifié le Locataire sera alors responsable à
              concurrence de la franchise dommage mentionnée aux Conditions
              Particulières. Cette franchise (ces franchises en cas de pluralité
              de sinistres lors d’un même contrat de location) sera (ont)
              également applicable(s) sur les dommages occasionnés à des tiers,
              même en l’absence de dégâts sur le véhicule loué.
            </div>
            <div>
              Le Locataire a la faculté de souscrire aux produits Pack Assurance
              proposé par SS2A COURTAGE N° ORIAS 07 009 103 (www.orias.fr) 10,
              rue Louis Pasteur - 92100 Boulogne - RCS Nanterre 431 246 966. La
              souscription n’est valide que lorsque le pack assurance a été
              souscrit au départ de la location et que la prestation figure sur
              les conditions particulières du contrat de location. Le locataire
              reconnaît avoir reçu un exemplaire des conditions générales
              disponibles en agence, d’en avoir pris connaissance et d’en avoir
              accepté tous les termes et conditions.{" "}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 9 - OBLIGATIONS EN CAS DE SINISTRE{" "}
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              9.1 En cas d’accident{" "}
            </div>
            <div>
              En cas d’accident, le Locataire s’engage :
              <ul>
                <li>
                  • à prévenir dans les plus brefs délais les autorités de
                  police ou de gendarmerie s’il y a des blessés pour faire
                  procéder aux constatations d’usage,
                </li>
                <li>
                  • à informer le Loueur au plus tard dans les cinq jours
                  ouvrables suivant la survenance du sinistre,{" "}
                </li>
                <li>
                  • à rédiger lisiblement, même en l’absence de tiers impliqué,
                  un constat amiable spécifiant les circonstances détaillées de
                  l’accident et contresigné si possible par le ou les
                  conducteurs de(s) l’autre(s) véhicule(s) impliqué(s) dans
                  l’accident avec les coordonnées des témoins éventuels.{" "}
                </li>
              </ul>
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              9.2 En cas de vol
            </div>
            <div>
              En cas de vol du véhicule ou de ses équipements et accessoires ou
              de vandalisme, le Locataire et/ou tout conducteur autorisé est
              tenu de déclarer le vol ou le vandalisme dès la constatation des
              dommages ou de la disparition aux autorités de police ou de
              gendarmerie et de déclarer et fournir le certificat de dépôt de
              plainte à l’agence de location mentionnée aux Conditions
              Particulières dans les vingt quatre heures de la constatation des
              dommages ou de la disparition et de restituer dans le délai le
              plus bref les documents du véhicule, les clefs originales. Le
              locataire ne sera alors tenu responsable qu’à concurrence de la
              franchise vol spécifiée aux Conditions Particulières.{" "}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 10 - EXCLUSIONS - DÉCHÉANCES
            </div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              a) Exclusions{" "}
            </div>
            <div>
              Sont toujours exclus :
              <ul>
                <li>
                  • les dommages causés aux parties hautes (au dessus du
                  pare-brise) et basses du véhicule (dessous de caisse),
                </li>
                <li>
                  • les dommages occasionnés au véhicule par suite d’une erreur
                  de carburant,{" "}
                </li>
                <li>
                  • les détériorations causées à l’intérieur du véhicule,
                  notamment du fait de brûlures, déchirures ou dégradations,{" "}
                </li>
                <li>
                  • les vols ou dommages causés à tous biens et valeurs
                  quelconques transportés ou laissés par le Locataire ou par
                  toute autre personne, dans ou sur le véhicule pendant la durée
                  de la location,{" "}
                </li>
                <li>• les bris de glace du véhicule, </li>
                <li>• les rétroviseurs, </li>
                <li>• les effets personnels. </li>
              </ul>
              <div>
                Sont exclus en cas de sinistre responsable ou d’absence de tiers
                identifié :
                <ul>
                  <li>
                    • les dommages causés aux pneumatiques, enjoliveurs et
                    jantes,
                  </li>
                  <li>• les dépannages et frais de rapatriement, </li>
                  <li>
                    • les dommages d’un coût inférieur au montant de la
                    franchise dommage ou vol incendie indiqué aux Conditions
                    Particulières.{" "}
                  </li>
                </ul>
              </div>
              <div style={{ fontWeight: "bold", padding: 18 }}>
                b) Déchéances
              </div>
              <div>
                Attention : le bénéfice de tout ou partie des garanties et
                assurances visées à l’article 8 ci-dessus peut être retiré au
                Locataire qui s’expose au recours des assureurs ou du Loueur
                dans les cas suivants :
                <ul>
                  <li>
                    • dégradation volontaire sur et dans le véhicule loué,
                  </li>
                  <li>
                    • utilisation du véhicule dans un pays non-autorisé, (cf
                    article 2){" "}
                  </li>
                  <li>
                    • utilisation du véhicule après la date de retour prévue et
                    en l’absence de prolongation expressément autorisée par le
                    Loueur,
                  </li>
                  <li>
                    • utilisation du véhicule pour l’apprentissage de la
                    conduite, ou le transport de passagers ou de marchandise à
                    titre onéreux,{" "}
                  </li>
                  <li>
                    • utilisation du véhicule par une personne non agréée par le
                    Loueur et/ou non titulaire d’un permis de conduire en cours
                    de validité pour la catégorie de véhicule concerné, par une
                    personne conduisant sous l’emprise d’un état alcoolique ou
                    sous l’effet de substances toxiques, euphorisantes ou
                    médicamenteuses dont les effets sont incompatibles avec la
                    conduite d’un véhicule (qu’elles aient ou non été prescrites
                    médicalement) et dont la notice précise que leur usage est
                    de nature à provoquer un état de somnolence,{" "}
                  </li>
                  <li>
                    • en cas de fausse déclaration intentionnelle du Locataire
                    et/ou du conducteur agréé concernant son identité ou la
                    validité de son permis de conduire,{" "}
                  </li>
                  <li>
                    • défaut de remise par le Locataire au Loueur du constat
                    amiable au plus tard dans les quinze jours de la restitution
                    du véhicule ou de la demande qui lui est adressée à cet
                    effet par le Loueur,{" "}
                  </li>
                  <li>
                    • utilisation du véhicule en violation caractérisée du code
                    de la route (utilisation en surcharge de passager et (ou)
                    d’une charge supérieure à celle autorisée, ou de vitesse
                    excessive par exemple){" "}
                  </li>
                  <li>
                    • en cas de non observation des obligations mentionnées à
                    l’article 2 a) 7° alinéa, ou en cas d’impossibilité de
                    restituer les clefs originales et les documents du véhicule,
                    le Locataire sera déchu de son droit à garantie Vol et sera
                    responsable de l’intégralité des préjudices subis par le
                    Loueur du fait de la disparition,{" "}
                  </li>
                  <li>
                    • en cas d’absence ou de caractère tardif de la déclaration
                    de vol, sauf si le Locataire rapporte la preuve qu’il n’a
                    commis aucune imprudence ou négligence.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 11 - INFORMATIQUE ET LIBERTÉS
            </div>
            <div>
              a) Les informations nominatives concernant les personnes
              physiques, recueillies à l’occasion du contrat, ne seront
              utilisées et ne feront l’objet de communication aux destinataires
              déclarés à la Commission Nationale de l’Informatique et des
              Libertés, que pour les seules nécessités de gestion administrative
              ou d’actions commerciales ou pour satisfaire aux obligations
              légales ou réglementaires. Elles pourront donner lieu à l’exercice
              du droit d’accès et de rectifications aux conditions prévues par
              la loi 78-17 relative à l’informatique, aux fichiers et aux
              libertés, du 6 janvier 1978 adressé à NégoLoc Luxe - Service
              Consommateurs - 10 rue Louis Pasteur – 92774 Boulogne Billancourt
              Cedex.{" "}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold", padding: 18 }}>
              ARTICLE 12 - JURIDICTION - LOI APPLICABLE
            </div>
            <div>
              Le présent contrat est soumis à la loi française. En cas de
              contestation relative à l’exécution du présent contrat, la
              juridiction compétente sera celle désignée par application des
              règles de droit commun si le Locataire est un particulier. Si le
              Locataire a qualité de commerçant la juridiction compétente pour
              connaître des litiges relatifs au présent contrat sera le Tribunal
              de commerce du lieu du siège social du Loueur.
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ConditionsGenerales;
