import HistoryRentStatus from "./HistoryRentStatus";
import QRCode from "react-qr-code";
import React, { useEffect, useState } from "react";
import { qrCodeValuesAtom } from "../../../stateManager/qrCodeValuesAtom";
import { useRecoilState } from "recoil";
import { veNameAtom } from "../../../stateManager/thisIsVehicleAtom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router";

const HistoryDetailsComponent = React.memo(
  ({
    rentHistory,
    fullList,
    isMobile,
    ordered,
    toTimestamp,
    startD,
    currentDate,
    user_id,
    stopD,
    clicked,
    setClicked,
    isLoaded,
    arrayData,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const [qrCodeValue, setQrCodeValue] = useRecoilState(qrCodeValuesAtom);
    // eslint-disable-next-line no-unused-vars
    const [veName, setVeName] = useRecoilState(veNameAtom);
    const [migrationProcedure, setMigrationProcedure] = useState(false);
    let navigate = useNavigate();

    const MigrationSteps = async () => {
      const MySwal = withReactContent(Swal);

      return await MySwal.fire({
        title: "Option Qr Code non compatible !",
        html: `
        <div>
        <p>Pour <strong>pouvoir effectuer les différentes étapes</strong> durant la location, <strong>nous utilison un QrCode</strong>...</p>
        <p><strong>Vous êtes avec un ordinateur</strong>, le QrCode est <strong>accessible seulement via un mobile...</strong></p>
        <br/>
        <p>Cliquez sur <strong>le bouton "Ok, J'ai compris"</strong> afin d'<strong>être guidé(e)</strong> pour les <strong>2 étapes</strong> de <strong>synchronisation des données</strong>.</p>
      </div>`,
        icon: "error",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
        cancelButtonText: "Non, Pas maintenant.",
        footer: "À très bientôt pour une nouvelle réservation.",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/synchronisation");
        }
        setMigrationProcedure(false);
      });
    };

    useEffect(() => {
      if (migrationProcedure) {
        MigrationSteps();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [migrationProcedure]);

    return (
      <>
        {rentHistory.map((result, index) => (
          <ul
            key={result.id_vehicle || index}
            style={{ transition: "all 0.6s ease-in-out" }}
            className={`historyList swing-in-top-fwd `}
          >
            <span
              style={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                alignItems: "center",
                justifyContent: "space-around",
                lineHeight: isMobile ? 1.4 : "",
                position: "relative",
              }}
            >
              <p style={{ fontSize: 12.5 }}>Véhicule id: {result.id_vehicle}</p>

              <HistoryRentStatus
                ordered={ordered}
                user_id={user_id}
                toTimestamp={toTimestamp}
                startD={startD}
                index={index}
                currentDate={currentDate}
                isMobile={isMobile}
                stopD={stopD}
                isLoaded={isLoaded}
                arrayRentStatus={
                  arrayData
                    .map((arr) => arr)
                    .map((res) => res.order)
                    .map((resul) => resul)
                    [index].map((fin) => fin.id_vehicle)
                    .includes(result.id_vehicle)
                    ? arrayData.map((arr) => arr)
                    : null
                }
              />

              {isMobile &&
              result.id_vehicle &&
              arrayData
                .map((r) => r)
                [index].status.includes("archived") ? null : (
                <QRCode
                  id={`${result.id_vehicle}`}
                  style={{
                    cursor: "pointer",
                    transform: "scale(0.2) translate(174vw, 0px)",
                    position: "absolute",
                  }}
                  onClick={() => {
                    setQrCodeValue(`${user_id}`);
                    setVeName(result.vehicleName);
                    setClicked((clicked) => !clicked);
                  }}
                  value={`${user_id}`}
                />
              )}
            </span>
            {isMobile ? (
              <li
                className="vehicleName "
                style={{ fontSize: 15, marginBottom: 10, paddingTop: 10 }}
              >
                Véhicule : {result.vehicleName}
                <p className="dateTimeTitles" style={{ fontSize: 14 }}>
                  Prix /jour(s): {result.totalPrice / result.totalDays}€
                </p>
              </li>
            ) : (
              <div
                className={`container ${
                  fullList ? "subHeaderNormal" : "subHeader"
                }`}
              >
                <span
                  className={`vehicleName ${
                    fullList ? "slideFromLeft" : "reduceAndLeft"
                  }`}
                  style={{ display: "flex" }}
                >
                  <span className="vehicleNameContent">
                    Véhicule : {result.vehicleName}
                    <p className="dateTimeTitles">
                      Prix /jour(s): {result.totalPrice / result.totalDays}€
                    </p>
                  </span>
                </span>
                {!isMobile &&
                result &&
                result.vehicleName &&
                arrayData
                  .map((r) => r)
                  [index].status.includes("archived") ? null : (
                  <span
                    className="qrCode"
                    style={{
                      transform: "translate(-20px, 0) !important",
                      display: arrayData
                        .map((r) => r)
                        [index].status.includes("archived")
                        ? "none"
                        : "",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 10,
                        transform: "scale(0.25)",
                      }}
                    >
                      <QRCode
                        id={`${result.id_vehicle}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setMigrationProcedure(true);
                        }}
                        value={`${user_id}`}
                      />
                    </div>
                  </span>
                )}
                <span
                  className={`pricesSection ${
                    fullList ? "hideSubHeader" : "viewSubHeader"
                  }`}
                >
                  Prix total :{" "}
                  <p style={{ fontSize: 13 }}>{result.totalPrice} €</p>
                </span>
              </div>
            )}
            <div
              className={`rentDetailContent ${fullList ? "full" : "little"}`}
            >
              <span
                className="dateTimeList"
                style={{ padding: isMobile ? 0 : 12 }}
              >
                <li style={{ fontSize: isMobile ? 14 : 16 }}>
                  <p
                    className="dateTimeTitles"
                    style={{
                      fontSize: isMobile ? 14 : 16,
                      textAlign: isMobile ? "center" : "",
                    }}
                  >
                    date de retrait
                  </p>{" "}
                  <p className="dateTimeValues">{result.dateRetrait}</p>
                </li>
                <li
                  style={{
                    fontSize: isMobile ? 14 : 16,
                  }}
                >
                  <p
                    className="dateTimeTitles"
                    style={{
                      fontSize: isMobile ? 13.5 : 16,
                      textAlign: isMobile ? "center" : "",
                    }}
                  >
                    Heure de retrait
                  </p>{" "}
                  <p className="dateTimeValues">{result.heureRetrait}</p>
                </li>

                <li style={{ fontSize: isMobile ? 14 : 16 }}>
                  <p
                    className="dateTimeTitles"
                    style={{
                      fontSize: isMobile ? 14 : 16,
                      textAlign: isMobile ? "center" : "",
                    }}
                  >
                    date de retour
                  </p>{" "}
                  <p className="dateTimeValues">{result.dateDepot}</p>
                </li>
                <li style={{ fontSize: isMobile ? 14 : 16 }}>
                  <p
                    className="dateTimeTitles"
                    style={{
                      fontSize: isMobile ? 14 : 16,
                      textAlign: isMobile ? "center" : "",
                    }}
                  >
                    heure de retour
                  </p>
                  <p className="dateTimeValues">{result.heureDepot}</p>
                </li>
              </span>

              <li className="daysNumber">
                <p
                  className="daysTitle"
                  style={{ fontSize: isMobile ? 14 : 16 }}
                >
                  Nbrs de jour(s) :
                </p>{" "}
                <p style={{ fontSize: isMobile ? 20 : 16 }}>
                  {result.totalDays}
                </p>
              </li>
              {isMobile ? (
                <span className="priceList">
                  <li className="pricesSection" style={{ textAlign: "center" }}>
                    Prix hors taxes :<p> {result.htPrice} €</p>
                  </li>
                  <li className="pricesSection" style={{ textAlign: "center" }}>
                    Taxes : <p>{result.taxePrice} €</p>
                  </li>
                  <li className="pricesSection" style={{ textAlign: "center" }}>
                    Prix total :{" "}
                    <p style={{ fontSize: 20 }}>{result.totalPrice} €</p>
                  </li>
                </span>
              ) : (
                <span className="priceList">
                  <li className="pricesSection">
                    Prix hors taxes : {result.htPrice} €
                  </li>
                  <li className="pricesSection">
                    Taxes : {result.taxePrice} €
                  </li>
                  <li className="pricesSection">
                    Prix total : {result.totalPrice} €
                  </li>
                </span>
              )}
            </div>
          </ul>
        ))}
      </>
    );
  }
);

export default HistoryDetailsComponent;
