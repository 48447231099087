import { Link } from "react-router-dom";
import Bg05 from "../../assets/images/breadcrumb/bg_05.jpg";

const Breadcrumb = () => {
  return (
    <section className="breadcrumb_section text-center clearfix">
      <div
        className="page_title_area has_overlay d-flex align-items-center clearfix"
        data-bg-image={Bg05}
        style={{ backgroundImage: `url(${Bg05})` }}
      >
        <div className="overlay"></div>
        <div
          className="container aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h1 className="page_title text-white mb-0">À propos de nous</h1>
        </div>
      </div>
      <div
        className="breadcrumb_nav clearfix"
        data-bg-color="#F2F2F2"
        style={{ backgroundColor: "rgb(242, 242, 242)" }}
      >
        <div className="container">
          <ul className="ul_li clearfix">
            <li>
              <Link to={"/"}>Accueil</Link>
            </li>
            <li>À propos de nous</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
