import { Link } from "react-router-dom";

const EmptyCart = ({ text }) => {
  return (
    <div className="hero">
      <div className="moon"></div>
      <div className="highway"></div>

      <div className="car">
        <img
          src={require("../../assets/images/emptyCart/new-sports-car.png")}
          alt="car"
        />
      </div>
      <div className="wheel">
        <img
          src={require("../../assets/images/emptyCart/carWheel.png")}
          alt="weel"
          className="back-wheel"
        />
        <img
          src={require("../../assets/images/emptyCart/carWheel.png")}
          alt="weel"
          className="front-wheel"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          transform: "translate(0,10vh)",
        }}
      >
        <div
          className="abtn_wrap "
          data-animation="fadeInUp"
          data-delay=".7s"
          style={{
            animationDelay: "0.7s",
            position: "absolute",
            top: "5%",
            zIndex: 99999,
          }}
        >
          <Link
            className="custom_btn bg_default_red btn_width text-uppercase"
            to={"/"}
          >
            Retournez à l'acceuil{" "}
            <img
              src={require("../../assets/images/icons/icon_01.png")}
              alt="icon_not_found"
            />
          </Link>
        </div>
        <p
          style={{
            fontSize: 45,
            color: "white",
            textAlign: "center",
            marginTop: "10vh",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default EmptyCart;
