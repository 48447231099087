import BaseLayout from "./BaseLayout";
import Breadcrumb from "./contactComponents/Breadcrumb";
import FormSection from "./contactComponents/FormSection";
import InfosContact from "./contactComponents/InfosContact";

const ContactPage = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <Breadcrumb />

        <FormSection />
        <InfosContact />
      </div>
    </BaseLayout>
  );
};

export default ContactPage;
