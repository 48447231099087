import BaseLayout from "./BaseLayout";
import BreadCrumbSection from "./vehiclesDetailsComponents/BreadCrumbSection";
import DetailsSection from "./vehiclesDetailsComponents/DetailsSection";
import { useRecoilState } from "recoil";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import EmptyCart from "./shoppingCartComponents/EmptyCart";
import { isMobile } from "react-device-detect";

const VehiclesDetails = () => {
  const [selectedVehicle] = useRecoilState(selectedVehicleAtom);

  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        {selectedVehicle !== null ? (
          <>
            <BreadCrumbSection />
            <DetailsSection />
          </>
        ) : !isMobile ? (
          <EmptyCart text={`Aucun vehicule n'a été sélectionné.`} />
        ) : null}
      </div>
    </BaseLayout>
  );
};

export default VehiclesDetails;
