import { atom } from "recoil";

export const vehiclesListAtom = atom({
  key: "vehiclesListAtom",
  default: [],
});

export const searchResultAtom = atom({
  key: "searchResultAtom",
  default: [],
});

export const categoriesListAtom = atom({
  key: "categoriesListAtom",
  default: [],
});
