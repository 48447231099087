import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { searchValueAtom } from "../../stateManager/searchValueAtom";

const SliderRange = ({ val }) => {
  const [value, onChange] = useRecoilState(searchValueAtom);
  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(val ? val / 4.4 : value / 6)}px`;
    }
  });

  return (
    <div className="slider-parent">
      <input
        readOnly={val ? true : false}
        type="range"
        min="1"
        max="1000"
        value={val ?? value}
        onChange={({ target: { value: radius } }) => {
          onChange(radius);
        }}
      />
      <div className="buble">{val ?? value}€/jour</div>
    </div>
  );
};

export default SliderRange;
