import { useRecoilState } from "recoil";
import {
  currentDate,
  endRentDate,
  startRentDate,
} from "../../helpers/currentTimeVariables";
import { reservedListAtom } from "../../stateManager/reservationAtom";
import { v4 as uuidv4 } from "uuid";

const ReservedController = ({ res }) => {
  const [reservedList] = useRecoilState(reservedListAtom);

  return (
    <span>
      {reservedList &&
        reservedList
          .filter((result) => res.title === result.title)
          .map((result) =>
            currentDate >= result.startDate - startRentDate &&
            currentDate <= result.endDate + endRentDate ? (
              <span
                key={`${res.id}${uuidv4()}`}
                className={`${"item_price bg_default_red"}`}
              >
                Déjà loué !
              </span>
            ) : (
              <span
                key={`${res.id}${uuidv4()}`}
                className="item_price bg_default_blue"
              >
                {res.price}€/jour
              </span>
            )
          )}
    </span>
  );
};

export default ReservedController;
