import { Link } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const AccountButton = () => {
  const [arrayData] = useLocalStorage("reservation", []);

  const lastname = arrayData
    .map((res) => res.order[0])
    .map((r) => r.lastname)[0];
  const firstname = arrayData
    .map((res) => res.order[0])
    .map((r) => r.firstname)[0];

  return (
    <li className="dropdown">
      <button
        type="button"
        className="user_btn"
        id="user_dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fal fa-user"></i>
      </button>
      <div
        className="user_dropdown rotors_dropdown dropdown-menu clearfix"
        aria-labelledby="user_dropdown"
      >
        <div className="profile_info clearfix">
          <Link to={"/mon-compte"} className="user_thumbnail">
            <img
              style={{ transform: "scale(1.6)" }}
              src={require("../../assets/images/meta/avatar.png")}
              alt="thumbnail_not_found"
            />
          </Link>
          <div className="user_content">
            <h4 className="user_name">
              <Link to={"/mon-compte"}>
                {lastname} {firstname}
              </Link>
            </h4>
            <span className="user_title">Utilisateur</span>
          </div>
        </div>
        <ul className="ul_li_block clearfix">
          <li>
            <Link to={"/mon-compte"}>
              <i className="fal fa-user-circle"></i> Profil
            </Link>
          </li>
          <li
            style={{
              display: "flex",
              alignitems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Link to={"/mon-compte"}>
              <i className="fal fa-user-cog"></i> Réservations{" "}
            </Link>
            <p
              style={{ marginLeft: -10, marginTop: 8 }}
              className="badge bg_default_red"
            >
              {arrayData.length}
            </p>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default AccountButton;
