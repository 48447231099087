import { isMobile } from "react-device-detect";
import BaseLayout from "./BaseLayout";
import Error404Section from "./error404Components/Error404Section";
import Header from "./error404Components/Header";

const Error404 = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: !isMobile ? 120 : 80, marginBottom: 50 }}>
        <Header />
      </div>
      <Error404Section />
    </BaseLayout>
  );
};

export default Error404;
