import { useEffect, useState } from "react";
import BackToTop from "./BackToTop";
import Footer from "./Footer";
import Header from "./Header";
import Preloader from "./Preloader";
import Sidebar from "./Sidebar";

const BaseLayout = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedEnd, setIsLoadedEnd] = useState(false);
  const [clickedMenu, setClickedMenu] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2850);

    return () => {
      setIsLoaded(false);
      clearTimeout(setTimeout);
    };
  }, []);

  return isLoaded ? (
    <div className="baseLayout">
      <BackToTop />
      <Header setClickedMenu={setClickedMenu} clickedMenu={clickedMenu} />
      <Sidebar setClickedMenu={setClickedMenu} clickedMenu={clickedMenu} />
      {children}
      <Footer />
    </div>
  ) : (
    <Preloader
      isLoaded={isLoaded}
      isLoadedEnd={isLoadedEnd}
      setIsLoadedEnd={setIsLoadedEnd}
    />
  );
};

export default BaseLayout;
