import { Link } from "react-router-dom";

const Error404Section = () => {
  return (
    <section className="error_404 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-5">
            <div className="error_text_m">
              <h2 style={{ color: "#fa2837", fill: "#fa2837" }}>
                4<span>0</span>4
              </h2>
              <div className="background_icon">
                <i className="fa fa-road" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-7">
            <div className="not_found_msg">
              <div className="error_icon">
                {" "}
                <i className="fa fa-smile-o" aria-hidden="true"></i>{" "}
              </div>
              <div className="error_msg_div">
                <h3>
                  Oops, <span>Page introuvable</span>
                </h3>
                <p>
                  Il n'y a rien ici, vous avez empreinté un cul-de-sac je
                  pense... Pas de panique, vous pouvez revenir sur la bonne
                  route en cliquant sur le bouton ci-dessous!
                </p>
                <Link to={"/"} className="btn">
                  Revenir sur la bonne route{" "}
                  <span className="angle_arrow">
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error404Section;
