import { useEffect } from "react";

const Preloader = ({ isLoadedEnd, setIsLoadedEnd }) => {
  useEffect(() => {
    setIsLoadedEnd(true);

    return () => {
      setIsLoadedEnd(false);
    };
  }, [setIsLoadedEnd]);
  return (
    <div className="container" style={{ backgroundColor: "transparent" }}>
      <div className={`preloader ${isLoadedEnd ? "loaded" : ""} `}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="animation_preloader">
            <p className="text-center">Chargement...</p>
          </div>
          <div>
            <div className="cell-loader">
              <span className="gauge-loader">Chargement&#8230;</span>
            </div>
          </div>
        </div>
        <div className="loader">
          <div className="row vh-100">
            <div className="col-3 loader_section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader_section section-left">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader_section section-right">
              <div className="bg"></div>
            </div>
            <div className="col-3 loader_section section-right">
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
