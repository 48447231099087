import { atom } from "recoil";

export const thisIsVehicleAtom = atom({
  key: "thisIsVehicleAtom",
  default: [],
});

export const veNameAtom = atom({
  key: "veNameAtom",
  default: "",
});
