// Pourcentage des taxes sur la location
export const PERCENTAGE_TAXE = 20; // 20%

// LOGIQUE DES DATES DE RÉSERVATION

// La date d'aujourd'hui (en millisecondes)
export const currentDate = Math.round(Date.now() / 1000);

// L'heure actuelle
export const currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

// Quand un véhicule est réservé, il est indiqué déjà loué 4 jours avant le jour j,
// sauf si la date de départ de location débute avant les 4 jours, alors il est directement marqué déjà loué !
//Ceci, afin d'être sûr de ne pas louer deux fois le même véhicule pour une même période !
export const startRentDate = 338400; //date de début de location - 4 jours (338 400 s)

// Le véhicule ne peut être loué de nouveau qu'un jour après son retour,
// il est indiqué déjà loué durant ce jour en plus !
export const endRentDate = 84600; //date de retour de location + 1 jour (84 600 s)

// début de journée de travail 9:00 (32400000 millisecondes)
export const startTimeToWork = 32400000;
// fin de la journée de travail 18:00 (64800000 millisecondes)
export const endTimeToWork = 64800000;

// LIMITATIONS DURANT L'ÉTAPE DE RÉSERVATION

// LIMITE DE TEMPS DES RESERVATIONS
// Le véhicule ne peut pas être réservé pour le jour même...
// Le véhicule ne peut pas être réservé plus de 4 jours à l'avance...
// Le véhicule ne peut pas être réservé ou déposé en dehors des heures d'ouverture.
// le véhicule ne peut pas être réservé ou déposé le samedi ou dimanche.

// INCOHÉRENCES DE DATES
// La date de début de location ne peut pas commencer avant aujourd'hui + 1 jour
// La date de fin de location, ne peut pas commencer avant, ni être la même que la date de début.
