import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const Footer = () => {
  return (
    <footer className="footer_section clearfix">
      <div
        className="footer_widget_area sec_ptb_100 clearfix"
        data-bg-color="#F2F2F2"
        style={{ backgroundColor: "rgb(242, 242, 242)" }}
      >
        <div className="container">
          <div className="row justify-content-lg-between">
            <div className="col-lg-4 col-md-4 col-sm-12 col-sm-12">
              <ScrollAnimation animateIn="zoomIn">
                <div
                  className="footer_about aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="brand_logo mb_30">
                    <a href="index.html">
                      <img
                        src={require("../assets/images/logo/logo_02_1x.png")}
                        srcSet={require("../assets/images/logo/logo_02_2x.png")}
                        alt="logo_not_found"
                      />
                    </a>
                  </div>
                  <p className="mb_15">
                    Cras sit amet mi non orci pretium consectetur. Donec iaculis
                    ante ac sollicitudin luctus. Phasellus ut lacus lacus.
                    Phasellus sagittis ex id tortor tincidunt luctus. Donec
                    consectetur consequat bibendum
                  </p>
                  <div className="footer_useful_links mb_30">
                    <ul className="ul_li_block clearfix">
                      <li>
                        <a href="#!">
                          <i className="fal fa-angle-right"></i> Information de
                          Location
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fal fa-angle-right"></i> F.A.Q.
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="form_item mb-0">
                    <form>
                      <input
                        id="footer_search"
                        type="search"
                        name="search"
                        placeholder="Chercher"
                        autoComplete="off"
                      />{" "}
                      <label htmlFor="footer_search" className="input_icon">
                        <i className="fal fa-search"></i>
                      </label>
                    </form>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 col-sm-12">
              <ScrollAnimation animateIn="zoomIn">
                <div
                  className="footer_contact_info aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h3 className="footer_widget_title">Contactez-nous:</h3>
                  <ul className="ul_li_block clearfix">
                    <li>
                      <strong>
                        <i className="fas fa-map-marker-alt"></i> Adresse:
                      </strong>
                      <p className="mb-0">
                        Unit 9, Manor Industrial Estate, Lower Wash Lane,
                        Warrington, WA4
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i> 8:00am-9:30pm
                    </li>

                    <li>
                      <i className="fas fa-envelope"></i>{" "}
                      <strong>negoloc37@email.fr</strong>
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>{" "}
                      <strong>+06-55-55-55-55</strong>
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-sm-12">
              <ScrollAnimation animateIn="zoomIn">
                <div
                  className="footer_useful_links aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <h3 className="footer_widget_title">Information:</h3>
                  <ul className="ul_li_block clearfix">
                    <li>
                      <Link to={"/galerie"}>
                        <i className="fal fa-angle-right"></i> Catalogue des
                        vehicules
                      </Link>
                    </li>

                    <li>
                      <Link to={"/a-propos"}>
                        <i className="fal fa-angle-right"></i> À propos de nous
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>
                        <i className="fal fa-angle-right"></i> Nous contacter
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>
                        <i className="fal fa-angle-right"></i> Centre
                        d'assistance
                      </Link>
                    </li>
                    <li>
                      <Link to={"/conditions"}>
                        <i className="fal fa-angle-right"></i>
                        Conditions générales
                      </Link>
                    </li>
                    <li>
                      <Link to={"/mentions-legales"}>
                        <i className="fal fa-angle-right"></i>Mentions légales
                      </Link>
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer_bottom text-white clearfix"
        data-bg-color="#000C21"
        style={{ backgroundColor: "rgb(0, 12, 33)" }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="copyright_text mb-0">
                Copyright © 2022. NÉGOLOC37 -
                <a className="author_links text-white" href="#!">
                  {" "}
                  Développé par Rodolphe Augusto
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <ul className="primary_social_links ul_li_right clearfix">
                <li>
                  <a href="#!">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
