import { atom } from "recoil";

export const shoppingCartAtom = atom({
  key: "shoppingCartAtom",
  default: [],
});

export const totalDaysAtom = atom({
  key: "totalDaysAtom",
  default: 0,
});

export const ordersPastAtom = atom({
  key: "ordersPastAtom",
  default: [],
});

export const orderPaymentMethodsAtom = atom({
  key: "orderPaymentMethodsAtom",
  default:
    localStorage.getItem("payment") !== null
      ? JSON.parse(localStorage.getItem("payment"))
      : {
          cash: false,
          bankTransfert: false,
          check: false,
          paypal: false,
        },
});
