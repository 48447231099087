import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import CustomConfirm from "../helpers/CustomConfirm";
import { ASSETS_URL } from "../helpers/fetchUrls";
import useGetData from "../helpers/GetData";
import {
  carCompare1Atom,
  carCompare2Atom,
  carCompare3Atom,
} from "../stateManager/comparatorAtom";
import {
  clickedSearchAtom,
  searchStringValueAtom,
  searchValueAtom,
} from "../stateManager/searchValueAtom";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import getVehiclesLogicSelector from "../stateManager/selectors/getVehiclesLogicSelector";
import {
  searchResultAtom,
  vehiclesListAtom,
} from "../stateManager/vehiclesListAtom";

const useFeaturedVehicles = () => {
  let Navigate = useNavigate();
  const [vehicles, setVehicles] = useRecoilState(vehiclesListAtom);
  const [vehiclesList] = useRecoilState(getVehiclesLogicSelector);
  const [state] = useRecoilState(searchStringValueAtom);
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  const [value, onChange] = useRecoilState(searchValueAtom);
  const [searchResult, setSearchResult] = useRecoilState(searchResultAtom);
  const [clicked, setCliked] = useRecoilState(clickedSearchAtom);
  const [erased, setErased] = useState(false);
  const { getData } = useGetData();
  const [car1ToCompare, setCar1ToCompare] = useRecoilState(carCompare1Atom);
  const [car2ToCompare, setCar2ToCompare] = useRecoilState(carCompare2Atom);
  const [car3ToCompare, setCar3ToCompare] = useRecoilState(carCompare3Atom);
  const [eraseCar1, setEraseCar1] = useState(false);
  const [eraseCar2, setEraseCar2] = useState(false);
  const [eraseCar3, setEraseCar3] = useState(false);
  const [firstAppear, setFirstAppear] = useState(false);
  const { JSalert } = CustomConfirm();

  useEffect(() => {
    if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId === null &&
      car3ToCompare.carId === null &&
      !firstAppear
    ) {
      if (selectedVehicle) {
        JSalert({
          title: "Ajouté à la liste de comparaison",
          htmlContent: `
                <div>
                  <p >Vous venez d'ajouter 
                    <strong>${vehicles
                      .filter((res) => res.id === selectedVehicle)
                      .map((result) => result.title)}</strong>,
                    à votre liste de comparaison.
                  </p>
                </div>`,
          footerText: `<p style="font-weight:bold; color:red;">Vous pouvez comparer jusqu'à 3 véhicules.</p>`,
          timer: 4540,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: "#7367f0",
          cancelButtonColor: "#ee0979",
          confirmButtonText: "Ok, J'ai compris !",
        });
      }
      setFirstAppear(true);
    }
  }, [
    JSalert,
    selectedVehicle,
    vehicles,
    firstAppear,
    car1ToCompare,
    car2ToCompare,
    car3ToCompare,
  ]);

  useLayoutEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setCar1ToCompare({ carId: null });
    setCar2ToCompare({ carId: null });
    setCar3ToCompare({ carId: null });

    if (vehiclesList !== undefined) {
      setVehicles(vehiclesList);
    }
    if (clicked && value > 0) {
      setVehicles(searchResult);
    }
    if (clicked && searchResult) {
      setVehicles(searchResult);
    }
    return () => {
      controller.abort(signal);
      setEraseCar1(false);
      setEraseCar2(false);
      setEraseCar3(false);
    };
  }, [
    vehicles,
    searchResult,
    value,
    setVehicles,
    clicked,
    erased,
    setCar1ToCompare,
    setCar2ToCompare,
    setCar3ToCompare,
    vehiclesList,
  ]);

  const CalculateNbVehicles = () => {
    let numb = 0;
    if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId === null &&
      car3ToCompare.carId === null
    ) {
      return (numb = 1);
    } else if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId !== null &&
      car3ToCompare.carId === null
    ) {
      return (numb = 2);
    } else if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId !== null &&
      car3ToCompare.carId !== null
    ) {
      return (numb = 3);
    }
    return <p style={{ color: "#888" }}>{numb}</p>;
  };

  const eraseFilter = () => {
    setErased(true);
    setCliked(false);
    setSearchResult([]);
    onChange(0);
    getData();
  };

  const AppearCompareFloatingButton = () => {
    return (
      <Link to={"/compare"}>
        <button
          className="see-compare"
          to={"/compare"}
          data-id="4180"
          data-action="add"
          data-post-type="listings"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: 45,
            border: "none !important",
          }}
          onClick={() => Navigate("/compare")}
        >
          <img
            src={require("../assets/images/icons/two-cars-in-line-white.png")}
            style={{ width: 30, height: 30, marginRight: 7 }}
            alt="icon"
          />
          Cliquez pour comparer les &nbsp;
          <CalculateNbVehicles /> modèles choisis
        </button>
      </Link>
    );
  };

  const PicturesAddress = `${ASSETS_URL}`;

  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    if (showHeader) {
      setTimeout(() => setShowHeader(false), 4000);
    }
    return () => {
      clearTimeout(setTimeout);
    };
  }, [showHeader]);

  useEffect(() => {
    if (eraseCar1) {
      setCar1ToCompare({ carId: null });
    }
    if (eraseCar2) {
      setCar2ToCompare({ carId: null });
    }
    if (eraseCar3) {
      setCar3ToCompare({ carId: null });
    }
    return () => {
      setEraseCar1(false);
      setEraseCar2(false);
      setEraseCar3(false);
    };
  }, [
    eraseCar1,
    eraseCar2,
    eraseCar3,
    setCar1ToCompare,
    setCar2ToCompare,
    setCar3ToCompare,
  ]);

  return {
    car1ToCompare,
    car2ToCompare,
    car3ToCompare,
    showHeader,
    AppearCompareFloatingButton,
    clicked,
    value,
    searchResult,
    state,
    eraseFilter,
    vehicles,
    setSelectedVehicle,
    eraseCar1,
    eraseCar2,
    eraseCar3,
    setEraseCar1,
    setEraseCar2,
    setEraseCar3,
    setShowHeader,
    PicturesAddress,
  };
};

export default useFeaturedVehicles;
