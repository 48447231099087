import BaseLayout from "./BaseLayout";
import Breadcrumb from "./reservationsComponents/Breadcrumb";
import ReservationSection from "./reservationsComponents/ReservationSection";

const ReservationPage = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <Breadcrumb />
        <ReservationSection />
      </div>
    </BaseLayout>
  );
};

export default ReservationPage;
