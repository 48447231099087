import { atom } from "recoil";

export const reservationAtom = atom({
  key: "reservationAtom",
  default: [],
});

export const selectedIdAtom = atom({
  key: "selectedIdAtom",
  default: undefined,
});
export const reservedListAtom = atom({
  key: "reservedListAtom",
  default: [],
});
