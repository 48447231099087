import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { orderPaymentMethodsAtom } from "../../stateManager/shoppingCartAtom";

const OrderPaymentMethode = () => {
  const [paymentMethod, setPaymentMethod] = useRecoilState(
    orderPaymentMethodsAtom
  );

  useEffect(() => {
    const listInApp =
      localStorage.getItem("payment") !== null
        ? localStorage.getItem("payment")
        : {};
    if (paymentMethod !== listInApp) {
      localStorage.setItem("payment", JSON.stringify(paymentMethod));
    }
  }, [paymentMethod]);

  return (
    <div className="order-payment-method" style={{ marginBottom: 14 }}>
      <div className="single-payment-method show">
        <div className="payment-method-name">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="cashon"
              name="paymentmethod"
              onChange={() =>
                setPaymentMethod({
                  cash: !paymentMethod.cash,
                  bankTransfert: false,
                  check: false,
                  paypal: false,
                })
              }
              className="custom-control-input"
              checked={paymentMethod.cash ? true : false}
              value={paymentMethod.cash}
            />
            <label className="custom-control-label" htmlFor="cashon">
              Espèce au retrait de véhicule
            </label>
          </div>
        </div>
        <div
          className="payment-method-details scale-in-ver-center"
          style={{ display: paymentMethod.cash ? "inherit" : "none" }}
          data-method="cash"
        >
          <p>Payez en espèce le jour de départ de location</p>
        </div>
      </div>
      <div className="single-payment-method">
        <div className="payment-method-name">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="directbank"
              name="paymentmethod"
              onChange={() =>
                setPaymentMethod({
                  cash: false,
                  bankTransfert: !paymentMethod.bankTransfert,
                  check: false,
                  paypal: false,
                })
              }
              className="custom-control-input"
              value={paymentMethod.bankTransfert}
              checked={paymentMethod.bankTransfert ? true : false}
            />
            <label className="custom-control-label" htmlFor="directbank">
              Transfert bancaire
            </label>
          </div>
        </div>
        <div
          className="payment-method-details scale-in-ver-center"
          data-method="bank"
          style={{ display: paymentMethod.bankTransfert ? "inherit" : "none" }}
        >
          <p>
            Effectuez votre paiement directement sur notre compte bancaire.
            Veuillez utiliser votre ID de compte comme référence de paiement.
          </p>
        </div>
      </div>
      <div className="single-payment-method">
        <div className="payment-method-name">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="checkpayment"
              name="paymentmethod"
              onChange={() =>
                setPaymentMethod({
                  cash: false,
                  bankTransfert: false,
                  check: !paymentMethod.check,
                  paypal: false,
                })
              }
              value={paymentMethod.check}
              checked={paymentMethod.check ? true : false}
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="checkpayment">
              Payer par chèque
            </label>
          </div>
        </div>
        <div
          className="payment-method-details scale-in-ver-center"
          data-method="check"
          style={{ display: paymentMethod.check ? "inherit" : "none" }}
        >
          <p>Veuillez nous envoyer un chèque sous deux jours.</p>
        </div>
      </div>
      <div className="single-payment-method">
        <div className="payment-method-name">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="paypalpayment"
              name="paymentmethod"
              onChange={() =>
                setPaymentMethod({
                  cash: false,
                  bankTransfert: false,
                  check: false,
                  paypal: !paymentMethod.paypal,
                })
              }
              value={paymentMethod.paypal}
              checked={paymentMethod.paypal ? true : false}
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="paypalpayment">
              Paypal{" "}
              <img
                src={require("../../assets/images/paypal-card.jpg")}
                className="img-fluid paypal-card"
                alt="Paypal"
              />
            </label>
          </div>
        </div>
        <div
          className="payment-method-details scale-in-ver-center"
          data-method="paypal"
          style={{ display: paymentMethod.paypal ? "inherit" : "none" }}
        >
          <p>
            Payez via PayPal ; vous pouvez payer avec votre carte de crédit si
            vous n'avez pas un compte PayPal.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderPaymentMethode;
