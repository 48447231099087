import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  shoppingCartAtom,
  totalDaysAtom,
} from "../stateManager/shoppingCartAtom";
import {
  reservationAtom,
  selectedIdAtom,
} from "../stateManager/reservationAtom";
import { isMobileOnly } from "react-device-detect";
import SearchByName from "./SearchByName";
import { ASSETS_URL } from "../helpers/fetchUrls";
import {
  carCompare1Atom,
  carCompare2Atom,
  carCompare3Atom,
} from "../stateManager/comparatorAtom";
import AccountButton from "./accountComponents/AccountButton";
import { useLocalStorage } from "../hooks/useLocalStorage";
import React from "react";

const Header = React.memo(({ setClickedMenu }) => {
  const [shoppingCarInfos, setShoppingCarInfo] =
    useRecoilState(shoppingCartAtom);
  let navigate = useNavigate();
  const [totalDaysCart, setTotalDaysCart] = useRecoilState(totalDaysAtom);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);
  // eslint-disable-next-line no-unused-vars
  const [isReserved, setIsReserved] = useRecoilState(reservationAtom);
  const [car1ToCompare] = useRecoilState(carCompare1Atom);
  const [car2ToCompare] = useRecoilState(carCompare2Atom);
  const [car3ToCompare] = useRecoilState(carCompare3Atom);
  const [arrayData] = useLocalStorage("reservation", []);
  const PicturesAddress = `${ASSETS_URL}`;

  function refreshPage() {
    setShoppingCarInfo([]);
    setTotalDaysCart(0);
    setSelectedId(undefined);
    setIsReserved([]);
    navigate("/");
  }

  const CalculateNbVehicles = () => {
    let numb = 0;

    if (
      (car1ToCompare.carId !== null &&
        car2ToCompare.carId === null &&
        car3ToCompare.carId === null) ||
      (car1ToCompare.carId === null &&
        car2ToCompare.carId !== null &&
        car3ToCompare.carId === null) ||
      (car1ToCompare.carId == null &&
        car2ToCompare.carId === null &&
        car3ToCompare.carId !== null)
    ) {
      return (numb = 1);
    } else if (
      (car1ToCompare.carId !== null &&
        car2ToCompare.carId !== null &&
        car3ToCompare.carId === null) ||
      (car1ToCompare.carId === null &&
        car2ToCompare.carId !== null &&
        car3ToCompare.carId !== null) ||
      (car1ToCompare.carId !== null &&
        car2ToCompare.carId === null &&
        car3ToCompare.carId !== null)
    ) {
      return (numb = 2);
    } else if (
      car1ToCompare.carId !== null &&
      car2ToCompare.carId !== null &&
      car3ToCompare.carId !== null
    ) {
      return (numb = 3);
    }

    return <p style={{ color: "#888" }}>{numb}</p>;
  };

  return (
    <header className="header_section secondary_header sticky text-white clearfix">
      <div className="header_top clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <ul className="header_contact_info ul_li clearfix">
                <li>
                  <i className="fal fa-envelope"></i>
                  negoloc37@email.com
                </li>
                <li>
                  <i className="fal fa-phone"></i>
                  06-55-55-55-55
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <ul className="primary_social_links ul_li_right clearfix">
                <li>
                  <a href="#!">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header_bottom clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="brand_logo">
                <a href="/">
                  <img
                    src={require("../assets/images/logo/logo_01_1x.png")}
                    srcSet={require("../assets/images/logo/logo_01_1x.png")}
                    alt="logo_not_found"
                  />
                  <img
                    src={require("../assets/images/logo/logo_02_1x.png")}
                    srcSet={require("../assets/images/logo/logo_02_1x.png")}
                    alt="logo_not_found"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-6 order-last">
              <ul className="header_action_btns ul_li_right clearfix">
                {(car1ToCompare.carId !== null &&
                  car2ToCompare.carId !== null) ||
                (car2ToCompare.carId !== null &&
                  car3ToCompare.carId !== null) ||
                (car1ToCompare.carId !== null &&
                  car3ToCompare.carId !== null) ? (
                  <li style={{ position: "relative" }}>
                    <Link to={"/compare"}>
                      <span
                        className="badge bg_default_red"
                        style={{
                          position: "absolute",
                          marginLeft: 18,
                          marginTop: -5,
                        }}
                      >
                        <CalculateNbVehicles />
                      </span>
                      <img
                        src={require("../assets/images/icons/two-cars-in-line.png")}
                        style={{
                          width: 28,
                          height: 28,
                        }}
                        alt="icon"
                      />
                    </Link>
                  </li>
                ) : null}
                {arrayData.length > 0 ? <AccountButton /> : null}

                <li>
                  <button
                    type="button"
                    className="search_btn"
                    data-toggle="collapse"
                    data-target="#collapse_search_body"
                    aria-expanded="false"
                    aria-controls="collapse_search_body"
                  >
                    <i className="fal fa-search"></i>
                  </button>
                </li>
                {shoppingCarInfos.length > 0 ? (
                  <li className="dropdown">
                    <button
                      type="button"
                      className="cart_btn"
                      id="cart_dropdown"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fal fa-shopping-cart"></i>
                      <span className="cart_counter bg_default_red">
                        {shoppingCarInfos.length}
                      </span>
                    </button>
                    <div
                      className="cart_dropdown rotors_dropdown dropdown-menu"
                      aria-labelledby="cart_dropdown"
                    >
                      <h4 className="wrap_title">
                        Articles du panier: ({shoppingCarInfos.length})
                      </h4>
                      {shoppingCarInfos.map((resCart) => (
                        <ul
                          key={resCart.id}
                          className="cart_items_list ul_li_block clearfix"
                        >
                          <li>
                            <div className="item_image">
                              <img
                                src={`${PicturesAddress}${resCart.img}`}
                                alt="image_not_found"
                              />
                            </div>
                            <div className="item_content">
                              <h4 className="item_title">{resCart.title}</h4>
                              <span className="item_price">
                                {resCart.price}€ / jour
                              </span>
                              <br />
                              <span className="item_price">
                                {" "}
                                forfait {totalDaysCart} jours
                              </span>
                            </div>
                            <button
                              onClick={refreshPage}
                              type="button"
                              className="remove_btn"
                            >
                              <i className="fal fa-times"></i>
                            </button>
                          </li>
                        </ul>
                      ))}
                      <ul className="btns_group ul_li_block clearfix">
                        <li>
                          <a
                            href="#!"
                            className="custom_btn bg_default_black text-uppercase"
                          >
                            Validez
                            <img
                              src={require("../assets/images/icons/icon_01.png")}
                              alt="icon_not_found"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : null}
                <li style={isMobileOnly ? { opacity: 1 } : { opacity: 0 }}>
                  <button
                    onClick={() => setClickedMenu(true)}
                    type="button"
                    className="mobile_sidebar_btn"
                  >
                    <i className="fal fa-align-right"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12">
              <nav className="main_menu clearfix">
                <ul className="ul_li_center clearfix">
                  <li className="active has_child">
                    <Link to={"/"}>Accueil</Link>
                  </li>
                  <li>
                    <Link to={"/galerie"}>Nos Véhicules</Link>
                  </li>
                  <li
                    className="has_child"
                    style={{ fontSize: 16, color: "#007bff" }}
                  >
                    Nos services
                    <ul className="submenu">
                      <li>
                        <Link
                          style={{ marginLeft: "15px" }}
                          className="subLink"
                          to={"/reservations"}
                        >
                          Réservation
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ marginLeft: "15px" }}
                          className="subLink"
                          to={"/compare"}
                        >
                          Comparer des véhicules
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ marginLeft: "15px" }}
                          className="subLink"
                          to={"/synchronisation"}
                        >
                          synchronisation des données
                        </Link>
                      </li>
                      <li>
                        <Link style={{ fontSize: 16 }} to={"/a-propos"}>
                          À propos de nous
                        </Link>
                      </li>
                      <li>
                        <Link style={{ fontSize: 16 }} to={"/panier"}>
                          Panier
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      style={{ marginLeft: "25px", fontSize: 16 }}
                      to={"/contact"}
                    >
                      Contactez-nous
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div id="collapse_search_body" className="collapse_search_body collapse">
        <div className="search_body">
          <div className="container">
            <SearchByName />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
