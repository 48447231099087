import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  currentDate,
  endRentDate,
  startRentDate,
} from "../helpers/currentTimeVariables";
import { ASSETS_URL } from "../helpers/fetchUrls";
import useMoreOtions from "../hooks/useMoreOtions";
import { reservedListAtom } from "../stateManager/reservationAtom";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import { thisIsVehicleAtom } from "../stateManager/thisIsVehicleAtom";
import Breadcrumb from "./addComparButton/Breadcrumb";
import BaseLayout from "./BaseLayout";

const ComparComponent = React.memo(() => {
  const {
    vehicles,
    otionsSupp1,
    otionsSupp2,
    otionsSupp3,
    car1ToCompare,
    car2ToCompare,
    car3ToCompare,
    setCar1ToCompare,
    setCar2ToCompare,
    setCar3ToCompare,
  } = useMoreOtions();
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  // eslint-disable-next-line no-unused-vars
  const [isThisVehicle, setIsThisVehicle] = useRecoilState(thisIsVehicleAtom);
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [mounted, setMounted] = useState(false);
  const [reservedList] = useRecoilState(reservedListAtom);
  const PicturesAddress = `${ASSETS_URL}`;

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, [setMounted]);

  const handleSelectedCar = (id) => {
    if (selectedVehicle !== null) {
      setSelectedVehicle(null);
      setIsThisVehicle([]);
      setTimeout(() => {
        setSelectedVehicle(id);
      }, 100);
      setTimeout(() => {
        navigate("/details");
      }, 400);
    }
  };

  return (
    <BaseLayout>
      <div id="main" style={{ marginTop: 80 }}>
        <Breadcrumb />
        <div className="container">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1466145513210">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                {car1ToCompare.carId !== null ||
                car2ToCompare.carId !== null ||
                car3ToCompare.carId !== null ? (
                  <div className="wpb_wrapper">
                    <div className="stm-compare-cars-module ">
                      <div className="container">
                        <div className="row row-4 car-listing-row stm-car-compare-row">
                          <div className="col-md-3 col-sm-3 col-xs-12">
                            <h2
                              className="compare-title"
                              style={{ fontSize: 24 }}
                            >
                              Comparateur
                            </h2>
                            <div className="colored-separator text-left">
                              <div className="first-long"></div>
                              <div className="last-short"></div>
                            </div>
                          </div>

                          {vehicles.length > 0
                            ? vehicles
                                .filter((res) => res.id === car1ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm compare-col-stm-4127"
                                  >
                                    <button
                                      onClick={() =>
                                        handleSelectedCar(result.id)
                                      }
                                      className="rmv_txt_drctn external"
                                    >
                                      <div className="compare-col-stm-empty">
                                        <div
                                          className="image"
                                          style={{
                                            position: "relative",
                                            overflow: "visible",
                                          }}
                                        >
                                          {reservedList
                                            .filter(
                                              (re) => result.title === re.title
                                            )
                                            .map((re) =>
                                              currentDate >=
                                                re.startDate - startRentDate &&
                                              currentDate <=
                                                re.endDate + endRentDate ? (
                                                <div
                                                  key={re.id}
                                                  className="ribbon ribbon-top-left slide-in-fwd-center"
                                                >
                                                  <span>Déjà loué</span>
                                                </div>
                                              ) : null
                                            )}
                                          <div className="stm-compare-car-img">
                                            <img
                                              width="255"
                                              height="135"
                                              src={`${PicturesAddress}${result.img}`}
                                              className="img-responsive  wp-post-image"
                                              alt=""
                                              srcSet={`${PicturesAddress}${result.img}`}
                                              sizes="(max-width: 255px) 100vw, 255px"
                                            />
                                            <img
                                              className="viewIcon"
                                              src={require("../assets/images/icons/info.png")}
                                              style={{
                                                width: 50,
                                                height: 50,
                                                position: "absolute",
                                                top: "34%",
                                                left: "40%",
                                                zIndex: 8,
                                              }}
                                              alt="icon"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <div className="remove-compare-unlinkable">
                                      <span
                                        onClick={() =>
                                          setCar1ToCompare({ carId: null })
                                        }
                                        className="remove-from-compare"
                                        data-action="remove"
                                        data-id="4127"
                                        data-post-type="listings"
                                      >
                                        <img
                                          src={require("../assets/images/icons/remove.png")}
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: -15,
                                            background: "white",
                                            borderRadius: 6,
                                          }}
                                          alt="icon"
                                        />
                                        <span style={{ marginLeft: 10 }}>
                                          Supprimer
                                        </span>
                                      </span>
                                    </div>
                                    <button
                                      onClick={() =>
                                        handleSelectedCar(result.id)
                                      }
                                      className="rmv_txt_drctn external"
                                    >
                                      <div className="listing-car-item-meta">
                                        <div className="car-meta-top heading-font clearfix">
                                          <div className="price">
                                            <div className="normal-price">
                                              {result.price}€/jour
                                            </div>
                                          </div>
                                          <div className="car-title">
                                            {result.title}
                                          </div>
                                        </div>
                                      </div>
                                    </button>

                                    <span
                                      className="btn btn-default add-to-compare hidden"
                                      data-action="remove"
                                      data-id="4127"
                                    >
                                      Supprimer de la comparaison{" "}
                                    </span>
                                  </div>
                                ))
                            : null}
                          {vehicles.length > 0
                            ? vehicles
                                .filter((res) => res.id === car2ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm compare-col-stm-4117"
                                  >
                                    <button
                                      onClick={() =>
                                        handleSelectedCar(result.id)
                                      }
                                      className="rmv_txt_drctn external"
                                    >
                                      <div className="compare-col-stm-empty">
                                        <div
                                          className="image"
                                          style={{
                                            position: "relative",
                                            overflow: "visible",
                                          }}
                                        >
                                          <div className="stm-compare-car-img">
                                            {reservedList
                                              .filter(
                                                (re) =>
                                                  result.title === re.title
                                              )
                                              .map((re) =>
                                                currentDate >=
                                                  re.startDate -
                                                    startRentDate &&
                                                currentDate <=
                                                  re.endDate + endRentDate ? (
                                                  <div
                                                    key={re.id}
                                                    className="ribbon ribbon-top-left slide-in-fwd-center"
                                                  >
                                                    <span>Déjà loué</span>
                                                  </div>
                                                ) : null
                                              )}
                                            <img
                                              width="255"
                                              height="135"
                                              src={`${PicturesAddress}${result.img}`}
                                              className="img-responsive  wp-post-image"
                                              alt=""
                                              loading="lazy"
                                              srcSet={`${PicturesAddress}${result.img}`}
                                              sizes="(max-width: 255px) 100vw, 255px"
                                            />
                                            <img
                                              className="viewIcon"
                                              src={require("../assets/images/icons/info.png")}
                                              style={{
                                                width: 50,
                                                height: 50,
                                                position: "absolute",
                                                top: "34%",
                                                left: "40%",
                                                zIndex: 8,
                                              }}
                                              alt="icon"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <div className="remove-compare-unlinkable">
                                      <span
                                        onClick={() =>
                                          setCar2ToCompare({ carId: null })
                                        }
                                        className="remove-from-compare"
                                        data-action="remove"
                                        data-id="4117"
                                        data-post-type="listings"
                                      >
                                        <img
                                          src={require("../assets/images/icons/remove.png")}
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: -15,
                                            background: "white",
                                            borderRadius: 6,
                                          }}
                                          alt="icon"
                                        />
                                        <span style={{ marginLeft: 10 }}>
                                          Supprimer
                                        </span>
                                      </span>
                                    </div>
                                    <button className="rmv_txt_drctn external">
                                      <div className="listing-car-item-meta">
                                        <div className="car-meta-top heading-font clearfix">
                                          <div className="price">
                                            <div className="normal-price">
                                              {result.price}€/jour
                                            </div>
                                          </div>
                                          <div className="car-title">
                                            {result.title}
                                          </div>
                                        </div>
                                      </div>
                                    </button>

                                    <span
                                      className="btn btn-default add-to-compare hidden"
                                      data-action="remove"
                                      data-id="4117"
                                    >
                                      Supprimer de la comparaison{" "}
                                    </span>
                                  </div>
                                ))
                            : null}

                          {car3ToCompare.carId !== null
                            ? vehicles
                                .filter((res) => res.id === car3ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm compare-col-stm-3616"
                                  >
                                    <button
                                      onClick={() =>
                                        handleSelectedCar(result.id)
                                      }
                                      className="rmv_txt_drctn external"
                                    >
                                      <div className="compare-col-stm-empty">
                                        <div
                                          className="image"
                                          style={{
                                            position: "relative",
                                            overflow: "visible",
                                          }}
                                        >
                                          <div className="stm-compare-car-img">
                                            {reservedList
                                              .filter(
                                                (re) =>
                                                  result.title === re.title
                                              )
                                              .map((re) =>
                                                currentDate >=
                                                  re.startDate -
                                                    startRentDate &&
                                                currentDate <=
                                                  re.endDate + endRentDate ? (
                                                  <div
                                                    key={re.id}
                                                    className="ribbon ribbon-top-left slide-in-fwd-center"
                                                  >
                                                    <span>Déjà loué</span>
                                                  </div>
                                                ) : null
                                              )}
                                            <img
                                              width="255"
                                              height="135"
                                              src={`${PicturesAddress}${result.img}`}
                                              className="img-responsive  wp-post-image"
                                              alt=""
                                              loading="lazy"
                                              srcSet={`${PicturesAddress}${result.img}`}
                                              sizes="(max-width: 255px) 100vw, 255px"
                                            />
                                            <img
                                              className="viewIcon"
                                              src={require("../assets/images/icons/info.png")}
                                              style={{
                                                width: 50,
                                                height: 50,
                                                position: "absolute",
                                                top: "34%",
                                                left: "40%",
                                                zIndex: 8,
                                              }}
                                              alt="icon"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <div className="remove-compare-unlinkable">
                                      <span
                                        onClick={() =>
                                          setCar3ToCompare({ carId: null })
                                        }
                                        className="remove-from-compare"
                                        data-action="remove"
                                        data-id="3616"
                                        data-post-type="listings"
                                      >
                                        <img
                                          src={require("../assets/images/icons/remove.png")}
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: -15,
                                            background: "white",
                                            borderRadius: 6,
                                          }}
                                          alt="icon"
                                        />
                                        <span style={{ marginLeft: 10 }}>
                                          Supprimer
                                        </span>
                                      </span>
                                    </div>
                                    <button className="rmv_txt_drctn external">
                                      <div className="listing-car-item-meta">
                                        <div className="car-meta-top heading-font clearfix">
                                          <div className="price">
                                            <div className="normal-price">
                                              {result.price}€/jour
                                            </div>
                                          </div>
                                          <div className="car-title">
                                            {result.title}
                                          </div>
                                        </div>
                                      </div>
                                    </button>

                                    <span
                                      className="btn btn-default add-to-compare hidden"
                                      data-action="remove"
                                      data-id="3616"
                                    >
                                      Supprimer de la comparaison{" "}
                                    </span>
                                  </div>
                                ))
                            : null}
                        </div>
                        <div className="row row-4 stm-compare-row">
                          {car1ToCompare.carId === null &&
                          car2ToCompare.carId === null &&
                          car3ToCompare.carId === null ? (
                            <button
                              onClick={() => navigate("/galerie")}
                              className="external"
                            >
                              <div className="image">
                                <img
                                  src={require("../assets/images/icons/add-car.png")}
                                  alt="car"
                                  className="carIcon"
                                />
                                <img
                                  className="stm-compare-empty"
                                  src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                  alt="Empty"
                                />
                              </div>
                              <div style={{ fontSize: 15 }} className="h5">
                                Ajouter pour comparer
                              </div>
                            </button>
                          ) : null}
                          <div className="col-md-3 col-sm-3 hidden-xs">
                            <div className="compare-options">
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-body"
                                      data-value="compare-value-body"
                                      style={{ height: "39.5px" }}
                                    >
                                      Type{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-ca-year"
                                      data-value="compare-value-ca-year"
                                      style={{ height: "40px" }}
                                    >
                                      Année{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-mileage"
                                      data-value="compare-value-mileage"
                                      style={{ height: "40px" }}
                                    >
                                      Kilomètrage{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-exterior-color"
                                      data-value="compare-value-exterior-color"
                                      style={{ height: "40px" }}
                                    >
                                      Couleur extérieure{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-fuel"
                                      data-value="compare-value-fuel"
                                      style={{ height: "40px" }}
                                    >
                                      Carburant{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-transmission"
                                      data-value="compare-value-transmission"
                                      style={{ height: "40px" }}
                                    >
                                      Transmission{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-engine"
                                      data-value="compare-value-engine"
                                      style={{ height: "40px" }}
                                    >
                                      Moteur{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-drive"
                                      data-value="compare-value-drive"
                                      style={{ height: "40px" }}
                                    >
                                      Boite de Vitesse{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-interior-color"
                                      data-value="compare-value-interior-color"
                                      style={{ height: "40px" }}
                                    >
                                      Couleur inérieure{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-power"
                                      data-value="compare-value-power"
                                      style={{ height: "40px" }}
                                    >
                                      Chevaux{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {vehicles.length > 0
                            ? vehicles
                                .filter((res) => res.id === car1ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-4127"
                                  >
                                    <div className="compare-values">
                                      <div
                                        onClick={() =>
                                          handleSelectedCar(result.id)
                                        }
                                        className="compare-car-visible"
                                      >
                                        <img
                                          width="798"
                                          height="466"
                                          src={`${PicturesAddress}${result.img}`}
                                          className="img-responsive stm-img-mobile-compare wp-post-image"
                                          alt=""
                                          loading="lazy"
                                          srcSet={`${PicturesAddress}${result.img}`}
                                          sizes="(max-width: 798px) 100vw, 798px"
                                        />
                                        <img
                                          className="viewIcon"
                                          src={require("../assets/images/icons/info.png")}
                                          alt="icon"
                                        />
                                        {reservedList
                                          .filter(
                                            (re) => result.title === re.title
                                          )
                                          .map((re) =>
                                            currentDate >=
                                              re.startDate - startRentDate &&
                                            currentDate <=
                                              re.endDate + endRentDate ? (
                                              <div
                                                key={re.id}
                                                className="ribbon ribbon-top-left slide-in-fwd-center"
                                              >
                                                <span>Déjà loué</span>
                                              </div>
                                            ) : null
                                          )}
                                      </div>
                                      <div className="remove-compare-unlinkable">
                                        <span
                                          onClick={() =>
                                            setCar1ToCompare({ carId: null })
                                          }
                                          className="remove-from-compare"
                                          data-id="4127"
                                          data-action="remove"
                                          data-post-type="listings"
                                        >
                                          <img
                                            src={require("../assets/images/icons/remove.png")}
                                            style={{
                                              width: 20,
                                              height: 20,
                                              marginLeft: -10,
                                            }}
                                            alt="icon"
                                          />
                                          <span style={{ marginLeft: 10 }}>
                                            Supprimer
                                          </span>
                                        </span>
                                      </div>
                                      <h4 className="text-transform compare-car-visible">
                                        {result.title}
                                      </h4>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-body"
                                              data-value="compare-value-body"
                                              style={{ height: "39.5px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Body"
                                              >
                                                {result.vehicleType}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-ca-year"
                                              data-value="compare-value-ca-year"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Year"
                                              >
                                                {result.year}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-mileage"
                                              data-value="compare-value-mileage"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Mileage"
                                              >
                                                None{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-exterior-color"
                                              data-value="compare-value-exterior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Exterior Color"
                                              >
                                                Midnight Silver Metallic{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-fuel"
                                              data-value="compare-value-fuel"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Fuel type"
                                              >
                                                {result.fuel}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-transmission"
                                              data-value="compare-value-transmission"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Transmission"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-engine"
                                              data-value="compare-value-engine"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Engine"
                                              >
                                                {result.Engine}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-drive"
                                              data-value="compare-value-drive"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Drive"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-interior-color"
                                              data-value="compare-value-interior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Interior Color"
                                              >
                                                Brown{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-power"
                                              data-value="compare-value-power"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Power"
                                              >
                                                {result.chevaux} chevaux
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))
                            : null}
                          {vehicles.length > 0
                            ? vehicles
                                .filter((res) => res.id === car2ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-4117"
                                  >
                                    <div className="compare-values">
                                      <div
                                        onClick={() =>
                                          handleSelectedCar(result.id)
                                        }
                                        className="compare-car-visible"
                                      >
                                        <img
                                          width="798"
                                          height="466"
                                          src={`${PicturesAddress}${result.img}`}
                                          className="img-responsive stm-img-mobile-compare wp-post-image"
                                          alt=""
                                          loading="lazy"
                                          srcSet={`${PicturesAddress}${result.img}`}
                                          sizes="(max-width: 798px) 100vw, 798px"
                                        />
                                        <img
                                          className="viewIcon"
                                          src={require("../assets/images/icons/info.png")}
                                          alt="icon"
                                        />
                                        {reservedList
                                          .filter(
                                            (re) => result.title === re.title
                                          )
                                          .map((re) =>
                                            currentDate >=
                                              re.startDate - startRentDate &&
                                            currentDate <=
                                              re.endDate + endRentDate ? (
                                              <div
                                                key={re.id}
                                                className="ribbon ribbon-top-left slide-in-fwd-center"
                                              >
                                                <span>Déjà loué</span>
                                              </div>
                                            ) : null
                                          )}
                                      </div>
                                      <div className="remove-compare-unlinkable">
                                        <span
                                          onClick={() =>
                                            setCar2ToCompare({ carId: null })
                                          }
                                          className="remove-from-compare"
                                          data-id="4117"
                                          data-action="remove"
                                          data-post-type="listings"
                                        >
                                          <img
                                            src={require("../assets/images/icons/remove.png")}
                                            style={{
                                              width: 20,
                                              height: 20,
                                              marginLeft: -10,
                                            }}
                                            alt="icon"
                                          />
                                          <span style={{ marginLeft: 10 }}>
                                            Supprimer
                                          </span>
                                        </span>
                                      </div>
                                      <h4 className="text-transform compare-car-visible">
                                        {result.title}
                                      </h4>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-body"
                                              data-value="compare-value-body"
                                              style={{ height: "39.5px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Body"
                                              >
                                                {result.vehicleType}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-ca-year"
                                              data-value="compare-value-ca-year"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Year"
                                              >
                                                {result.year}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-mileage"
                                              data-value="compare-value-mileage"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Mileage"
                                              >
                                                None{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-exterior-color"
                                              data-value="compare-value-exterior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Exterior Color"
                                              >
                                                Deep Blue Pearl{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-fuel"
                                              data-value="compare-value-fuel"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Fuel type"
                                              >
                                                {result.fuel}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-transmission"
                                              data-value="compare-value-transmission"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Transmission"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-engine"
                                              data-value="compare-value-engine"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Engine"
                                              >
                                                {result.Engine}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-drive"
                                              data-value="compare-value-drive"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Drive"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-interior-color"
                                              data-value="compare-value-interior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Interior Color"
                                              >
                                                Grey{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-power"
                                              data-value="compare-value-power"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Power"
                                              >
                                                {result.chevaux} chevaux
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))
                            : null}
                          {vehicles.length > 0
                            ? vehicles
                                .filter((res) => res.id === car3ToCompare.carId)
                                .map((result) => (
                                  <div
                                    key={result.id}
                                    className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-3616"
                                  >
                                    <div className="compare-values">
                                      <div
                                        onClick={() =>
                                          handleSelectedCar(result.id)
                                        }
                                        className="compare-car-visible"
                                      >
                                        <img
                                          width="797"
                                          height="465"
                                          src={`${PicturesAddress}${result.img}`}
                                          className="img-responsive stm-img-mobile-compare wp-post-image"
                                          alt=""
                                          loading="lazy"
                                          srcSet={`${PicturesAddress}${result.img}`}
                                          sizes="(max-width: 797px) 100vw, 797px"
                                        />
                                        <img
                                          className="viewIcon"
                                          src={require("../assets/images/icons/info.png")}
                                          alt="icon"
                                        />
                                        {reservedList
                                          .filter(
                                            (re) => result.title === re.title
                                          )
                                          .map((re) =>
                                            currentDate >=
                                              re.startDate - startRentDate &&
                                            currentDate <=
                                              re.endDate + endRentDate ? (
                                              <div
                                                key={re.id}
                                                className="ribbon ribbon-top-left slide-in-fwd-center"
                                              >
                                                <span>Déjà loué</span>
                                              </div>
                                            ) : null
                                          )}
                                      </div>
                                      <div className="remove-compare-unlinkable">
                                        <span
                                          onClick={() =>
                                            setCar3ToCompare({ carId: null })
                                          }
                                          className="remove-from-compare"
                                          data-id="3616"
                                          data-action="remove"
                                          data-post-type="listings"
                                        >
                                          <img
                                            src={require("../assets/images/icons/remove.png")}
                                            style={{
                                              width: 20,
                                              height: 20,
                                              marginLeft: -10,
                                            }}
                                            alt="icon"
                                          />
                                          <span style={{ marginLeft: 10 }}>
                                            Supprimer
                                          </span>
                                        </span>
                                      </div>
                                      <h4 className="text-transform compare-car-visible">
                                        {result.title}
                                      </h4>
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-body"
                                              data-value="compare-value-body"
                                              style={{ height: "39.5px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Body"
                                              >
                                                {result.vehicleType}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-ca-year"
                                              data-value="compare-value-ca-year"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Year"
                                              >
                                                {result.year}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-mileage"
                                              data-value="compare-value-mileage"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Mileage"
                                              >
                                                18000{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-exterior-color"
                                              data-value="compare-value-exterior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Exterior Color"
                                              >
                                                Pearl White{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-fuel"
                                              data-value="compare-value-fuel"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Fuel type"
                                              >
                                                {result.fuel}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-transmission"
                                              data-value="compare-value-transmission"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Transmission"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-engine"
                                              data-value="compare-value-engine"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Engine"
                                              >
                                                {result.Engine}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-drive"
                                              data-value="compare-value-drive"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Drive"
                                              >
                                                {result.speeds}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-interior-color"
                                              data-value="compare-value-interior-color"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Interior Color"
                                              >
                                                Multi-pattern{" "}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="compare-value-hover compare-value-power"
                                              data-value="compare-value-power"
                                              style={{ height: "40px" }}
                                            >
                                              <div
                                                className="h5"
                                                data-option="Power"
                                              >
                                                {result.chevaux} chevaux
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ))
                            : null}
                        </div>

                        <div className="row row-4 row-compare-features hidden-xs">
                          <div className="col-md-3 col-sm-3">
                            <h4 className="stm-compare-features">
                              Options additionnelles
                            </h4>
                          </div>

                          {car1ToCompare.carId !== null ? (
                            <div className="col-md-3 col-sm-3 compare-col-stm-4127">
                              <ul className="list-style-2">
                                {otionsSupp1
                                  ? otionsSupp1[0]?.map((r) => (
                                      <React.Fragment
                                        key={`${car1ToCompare.carId}${r.abs}`}
                                      >
                                        <li
                                          key={`${car1ToCompare.carId}${r.abs}`}
                                        >
                                          {r.abs === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; ABS
                                        </li>
                                        <li>
                                          {r.airbag === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Airbags
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Auto-radio
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Climatisation
                                        </li>
                                        <li>
                                          {r.jantes_en_alliage === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Jantes alliage
                                        </li>
                                        <li>
                                          {r.regulateur === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Régulateur de vitesse
                                        </li>
                                        <li>
                                          {r.verouillage_centralise === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Vérouillage centralisée
                                        </li>
                                      </React.Fragment>
                                    ))
                                  : null}
                              </ul>
                            </div>
                          ) : null}

                          {car2ToCompare.carId !== null ? (
                            <div className="col-md-3 col-sm-3 compare-col-stm-4117">
                              <ul className="list-style-2">
                                {otionsSupp2
                                  ? otionsSupp2[0]?.map((r) => (
                                      <React.Fragment
                                        key={`${car2ToCompare.carId}${r.abs}`}
                                      >
                                        <li
                                          key={`${car2ToCompare.carId}${r.abs}`}
                                        >
                                          {r.abs === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; ABS
                                        </li>
                                        <li>
                                          {r.airbag === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Airbags
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Auto-radio
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Climatisation
                                        </li>
                                        <li>
                                          {r.jantes_en_alliage === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Jantes alliage
                                        </li>
                                        <li>
                                          {r.regulateur === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Régulateur de vitesse
                                        </li>
                                        <li>
                                          {r.verouillage_centralise === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Vérouillage centralisée
                                        </li>
                                      </React.Fragment>
                                    ))
                                  : null}
                              </ul>
                            </div>
                          ) : null}
                          {car3ToCompare.carId !== null ? (
                            <div className="col-md-3 col-sm-3 compare-col-stm-3616">
                              <ul className="list-style-2">
                                {otionsSupp3
                                  ? otionsSupp3[0]?.map((r) => (
                                      <React.Fragment
                                        key={`${car3ToCompare.carId}${r.abs}`}
                                      >
                                        <li
                                          key={`${car3ToCompare.carId}${r.abs}`}
                                        >
                                          {r.abs === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; ABS
                                        </li>
                                        <li>
                                          {r.airbag === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Airbags
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Auto-radio
                                        </li>
                                        <li>
                                          {r.autoradio === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Climatisation
                                        </li>
                                        <li>
                                          {r.jantes_en_alliage === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Jantes alliage
                                        </li>
                                        <li>
                                          {r.regulateur === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Régulateur de vitesse
                                        </li>
                                        <li>
                                          {r.verouillage_centralise === true ? (
                                            <i
                                              className="far fa-check-circle"
                                              style={{ color: "green" }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="fal fa-times"
                                              style={{
                                                color: "red",
                                                fontSize: "24px",
                                              }}
                                            ></i>
                                          )}
                                          &nbsp; Vérouillage centralisée
                                        </li>
                                      </React.Fragment>
                                    ))
                                  : null}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="compare-empty-car-top">
                        <div className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-empty">
                          <button className="external">
                            <div className="image">
                              <img
                                src={require("../assets/images/icons/two-cars-in-line.png")}
                                style={{ width: 40, height: 40 }}
                                alt="icon"
                              />
                              <img
                                className="stm-compare-empty"
                                src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                alt="Empty"
                              />
                            </div>
                          </button>
                        </div>
                      </div>

                      <div className="compare-empty-car-bottom">
                        <div className="col-md-3 col-sm-3 col-xs-4">
                          <div className="compare-options">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="wpb_wrapper">
                    <div className="stm-compare-cars-module ">
                      <div className="container">
                        <div className="row row-4 car-listing-row stm-car-compare-row">
                          <div className="col-md-3 col-sm-3 col-xs-12">
                            <h2
                              className="compare-title"
                              style={{ fontSize: 24 }}
                            >
                              Comparateur
                            </h2>
                            <div className="colored-separator text-left">
                              <div className="first-long"></div>
                              <div className="last-short"></div>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-empty">
                            <button
                              onClick={() => navigate("/galerie")}
                              className="external buttonAddCarToCompare"
                            >
                              <div className="image">
                                <img
                                  src={require("../assets/images/icons/add-car.png")}
                                  alt="car"
                                  className="carIcon"
                                />
                                <img
                                  className="stm-compare-empty"
                                  src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                  alt="Empty"
                                />
                              </div>
                              <div className="add-compare-unlinkable">
                                <span
                                  className="add-to-compare-empty"
                                  data-action="remove"
                                  data-id="4127"
                                  data-post-type="listings"
                                >
                                  <p>Ajouter pour comparer</p>
                                </span>
                              </div>
                            </button>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-empty">
                            <button
                              onClick={() => navigate("/galerie")}
                              className="external buttonAddCarToCompare"
                            >
                              <div className="image">
                                <img
                                  src={require("../assets/images/icons/add-car.png")}
                                  alt="car"
                                  className="carIcon"
                                />
                                <img
                                  className="stm-compare-empty"
                                  src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                  alt="Empty"
                                />
                              </div>
                              <div className="add-compare-unlinkable">
                                <span
                                  className="add-to-compare-empty"
                                  data-action="remove"
                                  data-id="4127"
                                  data-post-type="listings"
                                >
                                  <p>Ajouter pour comparer</p>
                                </span>
                              </div>
                            </button>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-empty">
                            <button
                              onClick={() => navigate("/galerie")}
                              className="external buttonAddCarToCompare"
                            >
                              <div className="image">
                                <img
                                  src={require("../assets/images/icons/add-car.png")}
                                  alt="car"
                                  className="carIcon"
                                />
                                <img
                                  className="stm-compare-empty"
                                  src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                  alt="Empty"
                                />
                              </div>
                              <div className="add-compare-unlinkable">
                                <span
                                  className="add-to-compare-empty"
                                  data-action="remove"
                                  data-id="4127"
                                  data-post-type="listings"
                                >
                                  <p>Ajouter pour comparer</p>
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="row row-4 stm-compare-row">
                          {isMobile ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={() => navigate("/galerie")}
                                className="external"
                              >
                                <div className="image">
                                  <img
                                    src={require("../assets/images/icons/add-car.png")}
                                    alt="car"
                                    className="carIcon"
                                  />
                                  <img
                                    className="stm-compare-empty"
                                    src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                    alt="Empty"
                                  />
                                </div>
                                <div className="add-compare-unlinkable">
                                  <span
                                    className="add-to-compare-empty"
                                    data-action="remove"
                                    data-id="4127"
                                    data-post-type="listings"
                                  >
                                    <p>Ajouter pour comparer</p>
                                  </span>
                                </div>
                              </button>
                            </div>
                          ) : null}
                          <div className="col-md-3 col-sm-3 hidden-xs">
                            <div className="compare-options">
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-body"
                                      data-value="compare-value-body"
                                      style={{ height: "39.5px" }}
                                    >
                                      Type{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-ca-year"
                                      data-value="compare-value-ca-year"
                                      style={{ height: "40px" }}
                                    >
                                      Année{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-mileage"
                                      data-value="compare-value-mileage"
                                      style={{ height: "40px" }}
                                    >
                                      Kilomètrage{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-exterior-color"
                                      data-value="compare-value-exterior-color"
                                      style={{ height: "40px" }}
                                    >
                                      Couleur extérieure{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-fuel"
                                      data-value="compare-value-fuel"
                                      style={{ height: "40px" }}
                                    >
                                      Carburant{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-transmission"
                                      data-value="compare-value-transmission"
                                      style={{ height: "40px" }}
                                    >
                                      Transmission{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-engine"
                                      data-value="compare-value-engine"
                                      style={{ height: "40px" }}
                                    >
                                      Moteur{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-drive"
                                      data-value="compare-value-drive"
                                      style={{ height: "40px" }}
                                    >
                                      Boite de Vitesse{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-interior-color"
                                      data-value="compare-value-interior-color"
                                      style={{ height: "40px" }}
                                    >
                                      Couleur inérieure{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="compare-value-hover compare-value-power"
                                      data-value="compare-value-power"
                                      style={{ height: "40px" }}
                                    >
                                      Chevaux{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4">
                            <div className="compare-options">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4">
                            {isMobile ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={() => navigate("/galerie")}
                                  className="external"
                                >
                                  <div className="image">
                                    <img
                                      src={require("../assets/images/icons/add-car.png")}
                                      alt="car"
                                      className="carIcon"
                                    />
                                    <img
                                      className="stm-compare-empty"
                                      src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                      alt="Empty"
                                    />
                                  </div>
                                  <div className="add-compare-unlinkable">
                                    <span
                                      className="add-to-compare-empty"
                                      data-action="remove"
                                      data-id="4127"
                                      data-post-type="listings"
                                    >
                                      <p>Ajouter pour comparer</p>
                                    </span>
                                  </div>
                                </button>
                              </div>
                            ) : null}
                            <div className="compare-options">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-3 col-xs-4">
                            {isMobile ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={() => navigate("/galerie")}
                                  className="external"
                                >
                                  <div className="image">
                                    <img
                                      src={require("../assets/images/icons/add-car.png")}
                                      alt="car"
                                      className="carIcon"
                                    />
                                    <img
                                      className="stm-compare-empty"
                                      src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                      alt="Empty"
                                    />
                                  </div>
                                  <div className="add-compare-unlinkable">
                                    <span
                                      className="add-to-compare-empty"
                                      data-action="remove"
                                      data-id="4127"
                                      data-post-type="listings"
                                    >
                                      <p>Ajouter pour comparer</p>
                                    </span>
                                  </div>
                                </button>
                              </div>
                            ) : null}
                            <div className="compare-options">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="compare-value-hover">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row row-4 row-compare-features hidden-xs"
                          style={{ display: "none" }}
                        >
                          <div className="col-md-3 col-sm-3">
                            <h4 className="stm-compare-features">
                              Additional features
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div className="compare-empty-car-top">
                        <div className="col-md-3 col-sm-3 col-xs-4 compare-col-stm-empty">
                          <button className="external">
                            <div className="image">
                              <img
                                src={require("../assets/images/icons/add-car.png")}
                                style={{ width: 60, height: 60 }}
                                alt="car"
                              />
                              <img
                                className="stm-compare-empty"
                                src={require("../assets/images/backgrounds/compare-empty.jpg")}
                                alt="Empty"
                              />
                            </div>
                            <div style={{ fontSize: 15 }} className="h5">
                              Ajouter pour comparer
                            </div>
                          </button>
                        </div>
                      </div>

                      <div className="compare-empty-car-bottom">
                        <div className="col-md-3 col-sm-3 col-xs-4">
                          <div className="compare-options">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="compare-value-hover">
                                    &nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
});

export default ComparComponent;
