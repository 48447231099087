import { atom } from "recoil";

export const restoreAccountAtom = atom({
  key: "restoreAccountAtom",
  default: [],
});

export const isSyncAccountAtom = atom({
  key: "isSyncAccountAtom",
  default: false,
});
export const clickedModalLoginAtom = atom({
  key: "clickedModalLoginAtom",
  default: false,
});
