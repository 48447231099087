import { useState } from "react";
import { Link } from "react-router-dom";
import Bg3 from "../../../assets/images/breadcrumb/bg_03.jpg";

const Breadcrumb = () => {
  const [data] = useState(
    JSON.parse(localStorage.getItem("reservation")).length
  );

  return (
    <section
      className="breadcrumb_section text-center clearfix"
      style={{ marginTop: 80 }}
    >
      <div
        className="page_title_area has_overlay d-flex align-items-center clearfix"
        data-bg-image={Bg3}
        style={{ backgroundImage: `url(${Bg3})` }}
      >
        <div className="overlay"></div>
        <div
          className="container aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h1 className="page_title text-white mb-0">Synchronisation</h1>
        </div>
      </div>
      <div
        className="breadcrumb_nav clearfix"
        data-bg-color="#F2F2F2"
        style={{ backgroundColor: "rgb(242, 242, 242)" }}
      >
        <div className="container">
          <ul className="ul_li clearfix">
            <li>
              <Link to={"/"}>Accueil</Link>
            </li>
            <li>
              <Link to={data > 0 ? "/mon-compte" : "/error-page"}>
                mon compte
              </Link>
            </li>
            <li>Synchronisation</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
