import axios from "axios";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BASE_URL } from "../../helpers/fetchUrls";
import { idForDeleteAtom } from "../../stateManager/idForDeleteAtom";
import {
  clickedModalLoginAtom,
  isSyncAccountAtom,
  restoreAccountAtom,
} from "../../stateManager/restoreAccountAtom";
import BaseLayout from "../BaseLayout";
import Breadcrumb from "./synchroComponents/Breadcrumb";
import QRCode from "react-qr-code";
import QrCodeSynchro from "./synchroComponents/QrCodeSynchro";
import { useLocation } from "react-router-dom";

const SynchroAccount = () => {
  const [isSync, setIsSync] = useRecoilState(isSyncAccountAtom);
  const [sauvFromLocalStore, setSauvFromLocalStore] = useState([]);
  const MySwal = withReactContent(Swal);
  const [savDone, setSavDone] = useState(false);
  const [userID, setUserID] = useState("");
  const [historyFromServer, setHistoryFromServer] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isMyHistory, setIsMyHistory] = useRecoilState(restoreAccountAtom);
  const [user_ident, setUser_ident] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [getInfo, setGetInfo] = useState(false);
  let navigate = useNavigate();
  const [sendedBackup, setSendedBackup] = useState(false);

  let query = useQuery();

  const [clickedModalLogin, setClickedModalLogin] = useRecoilState(
    clickedModalLoginAtom
  );

  const handleClick = () => {
    setClickedModalLogin((clicked) => !clicked);
  };

  const [value, setValue] = useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    function ProfilePage() {
      setValue(query.get("userId") !== null ? query.get("userId") : undefined);
      setUser_ident(query.get("userId"));
      if (value !== "") {
        getHistoryDatas();
      }
    }
    if (value === "" && !isSync) {
      ProfilePage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setClickedModalLogin, setIsSync, query]);

  //   sending data to server section
  useEffect(() => {
    const sav = localStorage.getItem("reservation");
    const userIdentification = localStorage.getItem("userId");

    setSauvFromLocalStore(JSON.parse(sav));
    setUserID(userIdentification);
  }, []);

  const postDataOrder = async () => {
    try {
      await axios
        .post(
          `${BASE_URL}/sauvegardes`,
          sauvFromLocalStore.map((r) => r)
        )
        .then((res) => {
          if (res) {
            setSendedBackup(true);

            MySwal.fire({
              title: "Sauvegarde effectuée !",
              html: `
            <div>
            <p >Votre historique à bien été envoyé au serveur</p>
            <p>Copiez votre id de profil afin de synchronisez votre compte, ou scannez directement le QrCode.</p>
          </div>`,
              icon: "success",
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonColor: "#7367f0",
              cancelButtonColor: "#ee0979",
              confirmButtonText: "Ok, J'ai compris !",
              footer: "Rendez-vous sur votre autre appareil.",
            }).then(() => setSavDone(true));
          }
        });
    } catch (error) {
      MySwal.fire({
        title: "Erreur durant la sauvegarde !",
        html: `
          <div>
          <p >Une sauvegarde est déjà présente sur le serveur</p>
          <p>Restaurez votre session sur un autre appareil pour pouvoir sauvegarder à nouveau !</p>
        </div>`,
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
        footer: "Rendez-vous sur votre autre appareil.",
      }).then(() => setSendedBackup(false));
    }
  };

  const [copySuccess, setCopySuccess] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [idForDelete, setIdForDelete] = useRecoilState(idForDeleteAtom);

  function copyToClipboard(e) {
    navigator.clipboard.writeText(userID);
    setCopySuccess("Votre id à bien été copié !");
  }

  //  datas restauration section
  const getHistoryDatas = async () => {
    setGetInfo(true);
    const orders = await axios.get(`${BASE_URL}/sauvegardes`);
    setHistoryFromServer(orders.data.data);
    setUserID(user_ident);
    localStorage.setItem("userId", user_ident);

    setTimeout(() => {
      MySwal.fire({
        title: "Vos données sont synchronisées.",
        html: `
        <div>
        <p >Cet appareil est à présent synchronisé avec votre compte...</p>
        <p>Vous pouvez maintenant réserver sur l'appareil de votre choix, vos réservation seront accessibles sur tous vos appareils synchronisés.</p>
      </div>`,
        icon: "success",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
        footer: `<p style="text-align:center">Vous retrouverez vos réservations en cliquant sur l'icone qui vient d'apparaître à coté de la loupe.</p>`,
      });
      navigate("/");
    }, 1750);
  };

  useEffect(() => {
    if (historyFromServer !== []) {
      const myHistory = historyFromServer
        .filter((h) => h.user_order === user_ident)
        .map((res) => res);
      setIsMyHistory(myHistory);
      const sauvId = historyFromServer.map((r) => r.id).map((i) => i);
      setIdForDelete(sauvId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyFromServer, user_ident]);

  const [clickedQrCodeSync, setClickedQrCodeSync] = useState(false);

  const handleClickQrCodeSync = () => {
    setClickedQrCodeSync((clicked) => !clicked);
  };

  return (
    <BaseLayout>
      <Breadcrumb sauvFromLocalStore={sauvFromLocalStore} />
      {clickedQrCodeSync ? (
        <QrCodeSynchro
          userID={userID}
          handleClickQrCodeSync={handleClickQrCodeSync}
          clickedQrCodeSync={clickedQrCodeSync}
          setClickedQrCodeSync={setClickedQrCodeSync}
        />
      ) : null}
      {isSync ? (
        <div
          id="demo-modal"
          className={` ${clickedModalLogin ? "modalSynchro" : "hidden"}`}
        >
          <div className="modalSynchro__content">
            <div className="modalSynchroHead">
              <button
                onClick={() => {
                  handleClick();
                  setIsSync(false);
                }}
                className="modalSynchro__close"
              >
                &times;
              </button>
            </div>
            <div className="modalSynchroContain">
              <section
                className="login"
                id="login"
                style={{ marginTop: 0, marginBottom: 50 }}
              >
                <div className="head">
                  <img
                    src={require("../../assets/images/icons/telescope.png")}
                    alt="icon"
                  />
                  <h1 className="company">Synchroniser le compte</h1>
                </div>
                <p className="msg">Bienvenue à nouveau</p>
                <p style={{ textAlign: "center" }}>
                  {value !== ""
                    ? "votre id de profil viens automatiquement d'être pré-remplis"
                    : "Veulliez coller l'id de profil copié au préalable"}
                </p>
                <div className="form">
                  <input
                    type="password"
                    placeholder="Votre id de profil"
                    className="password"
                    name="idProfil"
                    value={value !== "" ? value : user_ident}
                    onChange={(e) => setUser_ident(e.target.value)}
                  />
                  <br />
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      onClick={getHistoryDatas}
                      className="btn-login"
                      style={{ height: 45 }}
                    >
                      Synchroniser
                    </button>
                    <button
                      onClick={() => {
                        handleClick();
                        setIsSync(false);
                      }}
                      className="btn-login"
                      style={{
                        width: "70%",
                        marginTop: 0,
                        marginLeft: 50,
                        padding: 5,
                      }}
                    >
                      <p
                        style={{
                          fontStyle: "lowercase",

                          padding: 5,
                          height: 20,
                        }}
                      >
                        Annuler
                      </p>
                    </button>
                  </div>

                  <p
                    style={{
                      marginTop: 30,
                      marginBottom: 0,

                      textAlign: "center",
                    }}
                  >
                    Cliquez sur synchroniser pour terminer la procédure
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container"
          style={{
            marginTop: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            width: isMobile ? "86%" : "80%",
          }}
        >
          <>
            <h3 style={{ marginBottom: 40 }}>2 étapes simples...</h3>

            {sauvFromLocalStore && sauvFromLocalStore.length > 0 ? (
              <span
                style={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isMobile ? "space-around" : "space-evenly",
                  flexDirection: !isMobile ? "row" : "column",
                  paddingBottom: 25,
                }}
              >
                <span style={{ marginBottom: 20 }}>
                  <h3 style={{ textAlign: "center" }}>1ère étape : </h3>
                  <p style={{ textAlign: "center", width: 270 }}>
                    Sauvegarder vos données de réservation sur le serveur
                  </p>
                </span>
                <button
                  disabled={savDone ? true : false}
                  style={{
                    opacity: savDone ? 0.4 : 1,
                    fontStyle: "lowercase",
                    fontSize: 12,
                    marginLeft: isMobile ? 21 : -24,
                    width: !isMobile ? "41%" : "115%",
                  }}
                  onClick={postDataOrder}
                  className="custom_btn bg_default_red"
                >
                  <p style={{ fontStyle: "lowercase", fontSize: 12 }}>
                    Envoyer vos infos au serveur!
                  </p>
                </button>
              </span>
            ) : null}

            {savDone ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 50,
                  boxShadow: "0px 1px 6px 1px rgb(108 108 108 / 15%)",
                  paddingTop: 11,
                }}
              >
                <h4 style={{ textAlign: "center" }}>
                  2 options s'offrent à vous...
                </h4>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {isMobile ? <hr /> : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ textAlign: "center", maxWidth: 280 }}>
                      Copier l'identifiant puis vous l'envoyer via email/message
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "4vh",
                      }}
                    >
                      <p style={{ maxWidth: 280, textAlign: "center" }}>
                        Si vous souhaitez synchroniser un autre ordinateur.
                      </p>
                      {copySuccess ? (
                        <div className="copiedMessage"> {copySuccess}</div>
                      ) : null}
                      <button
                        onClick={copyToClipboard}
                        style={{ maxWidth: "70%" }}
                      >
                        <p
                          style={{
                            color: copySuccess ? "lightgrey" : "black",
                            fontWeight: 400,
                          }}
                        >
                          Votre id de profil:
                          <br />{" "}
                          <strong style={{ fontSize: 12 }}>
                            {userID && userID}
                          </strong>
                        </p>
                      </button>
                      {!copySuccess ? (
                        <p
                          style={{
                            maxWidth: 280,
                            textAlign: "center",
                            fontSize: 14,
                          }}
                        >
                          Cliquez pour copier votre id de profil.{" "}
                        </p>
                      ) : null}
                    </div>

                    <span style={{ height: "2vh" }} />
                  </div>
                  <div>
                    {isMobile ? <hr /> : null}
                    <p
                      style={{
                        textAlign: "center",
                        maxWidth: 280,
                        marginTop: 10,
                      }}
                    >
                      Scanner directement le QrCode avec votre téléphone
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <QRCode
                        id={`${userID}`}
                        style={{
                          cursor: "pointer",
                          transform: "scale(0.6)",
                        }}
                        onClick={handleClickQrCodeSync}
                        value={`${userID}`}
                      />
                      <p>Cliquez dessus pour agrandir</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {sauvFromLocalStore.length === 0 ? (
              <div
                className="container"
                style={{
                  width: isMobile ? "100%" : "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: !isMobile ? "row" : "column",
                }}
              >
                <span>
                  <h3 style={{ textAlign: "center" }}>2ème étape : </h3>
                  <p
                    style={{
                      textAlign: "center",
                      width: 270,
                    }}
                  >
                    Synchroniser le serveur avec votre nouvel appareil !
                  </p>
                </span>
                <button
                  onClick={() => {
                    setIsSync(true);
                    handleClick();
                  }}
                  className="custom_btn bg_default_blue "
                  style={{
                    width: !isMobile ? "43%" : "100%",
                    marginLeft: 24,
                  }}
                >
                  <p style={{ fontStyle: "lowercase", fontSize: 12 }}>
                    {!isMobile
                      ? "Synchroniser vos infos avec cet appareil"
                      : "Synchroniser vos infos"}
                  </p>
                </button>
              </div>
            ) : (
              <div
                className="container"
                style={{
                  width: isMobile ? "100%" : "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                {!sendedBackup ? (
                  <h3 style={{ textAlign: isMobile ? "center" : "" }}>
                    Cliquez pour sauvegarder votre historique...
                  </h3>
                ) : null}
                {sendedBackup ? (
                  <>
                    <h3 style={{ textAlign: "center" }}>2ème étape : </h3>
                    <h4 style={{ textAlign: "center" }}>
                      rendez-vous sur un autre appareil pour synchroniser vos
                      données!
                    </h4>
                  </>
                ) : null}
              </div>
            )}
            <div
              style={{
                width: "100%",
                height: "100%",
                marginTop: 40,
              }}
            ></div>
            {sauvFromLocalStore.length === 0 ? (
              <div
                style={{
                  width: !isMobile ? "100%" : "80%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 35,
                  lineHeight: 2,
                  textAlign: "center",
                }}
              >
                <h3 style={{ fontSize: 22 }}>
                  L'étape 1 n'est pas accessible !
                </h3>
                <strong>
                  Vous ne pouvez sauvegarder vos données seulement que si vous
                  en avez déjà !!!
                </strong>

                <p style={{ marginBottom: -3, color: "blue" }}>
                  Vous pouvez réserver un véhicule afin de pouvoir sauvegarder
                  votre historique.
                </p>
                <p style={{ marginBottom: -3, color: "blue" }}>
                  Ou alors, vous pouvez synchroniser vos données existantes sur
                  cet appareil si vous avez déjà réserver sur un autre appareil.
                </p>
              </div>
            ) : null}
          </>
        </div>
      )}
    </BaseLayout>
  );
};

export default SynchroAccount;
