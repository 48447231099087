import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ASSETS_URL } from "../../helpers/fetchUrls";
import { selectedVehicleAtom } from "../../stateManager/selectedVehicleAtom";
import { vehiclesListAtom } from "../../stateManager/vehiclesListAtom";

const SearchSection = () => {
  const [vehicles] = useRecoilState(vehiclesListAtom);
  // eslint-disable-next-line no-unused-vars
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);

  const PicturesAddress = `${ASSETS_URL}`;

  return (
    <section
      className="search_section sec_ptb_100 clearfix"
      data-bg-color="#161829"
      style={{ backgroundColor: "rgb(22, 24, 41)" }}
    >
      <div className="container">
        <div className="section_title text-center mb_60">
          <h2
            className="title_text text-white mb-0 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <span>Trouvez le bon vehicule pour chaque occasion</span>
          </h2>
        </div>
      </div>
      <div
        className="offers_car_carousel slideshow4_slider slick-initialized slick-slider slick-dotted aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        <div className="slick-list draggable">
          <div
            className="slick-track"
            style={{
              opacity: 1,
              width: "100%",
              transform: "scale(0.98)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {vehicles.map((res) => (
              <div
                key={res.id}
                className="item slick-slide"
                data-slick-index="-4"
                id=""
                aria-hidden="true"
                tabIndex="-1"
              >
                <div className="gallery_fullimage_2">
                  <img
                    style={{ position: "relative", zIndex: 10 }}
                    src={`${PicturesAddress}${res.img}`}
                    alt="image_not_found"
                  />
                  <div className="overlay" />
                  <div
                    className="item_content text-white"
                    style={{
                      marginTop: 5,
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      zIndex: 30,
                    }}
                  >
                    <span className="item_price bg_default_blue">
                      {res.price}€/jour
                    </span>
                    <h3
                      className="item_title text-white"
                      style={{ fontSize: 12 }}
                    >
                      {res.title}
                    </h3>
                    <button
                      onClick={() => setSelectedVehicle(res.id)}
                      className="text_btn text-uppercase"
                      tabIndex="-1"
                    >
                      <Link to={"/details"}>
                        <span style={{ fontSize: 10 }}>Réserver</span>{" "}
                        <img
                          src={require("../../assets/images/icons/icon_02.png")}
                          alt="icon_not_found"
                        />
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchSection;
