import React, { useEffect, useState } from "react";

const HistoryRentStatus = React.memo(
  ({
    ordered,
    user_id,
    toTimestamp,
    startD,
    index,
    currentDate,
    isMobile,
    stopD,
    isLoaded,
    arrayRentStatus,
  }) => {
    const [dateStartRent, setDateStartRent] = useState();
    const [dateStopRent, setDateStopRent] = useState();
    const [dateActual, setDateActual] = useState();

    const getActualDate = () =>
      new Promise((resolve, reject) => {
        const ActualDate = currentDate;
        if (!ActualDate) {
          return setTimeout(() => reject(new Error("Date not found")), 50);
        }
        setTimeout(() => resolve(ActualDate), 50);
      });
    const getStartRentDate = () =>
      new Promise((resolve, reject) => {
        const StartingDate = toTimestamp(startD[index]);
        if (!StartingDate) {
          return setTimeout(() => reject(new Error("Date not found")), 50);
        }
        setTimeout(() => resolve(StartingDate), 50);
      });
    const getEndRentDate = () =>
      new Promise((resolve, reject) => {
        const StopingDate = toTimestamp(stopD[index]);
        if (!StopingDate) {
          return setTimeout(() => reject(new Error("Date not found")), 50);
        }
        setTimeout(() => resolve(StopingDate), 50);
      });

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      async function handleStatus() {
        const controller = new AbortController();
        const signal = controller.signal;
        await getActualDate({ signal }).then((d) => {
          setDateActual(d);
        });
        await getStartRentDate({ signal }).then((s) => {
          setDateStartRent(s);
        });
        await getEndRentDate({ signal }).then((f) => {
          setDateStopRent(f);
        });
      }
      if (ordered.length > 0) {
        handleStatus();
      }
      return () => {
        controller.abort(signal);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoaded ? (
      <>
        {ordered.filter((r) => r.user_order === user_id) ? (
          dateStartRent > dateActual ||
          arrayRentStatus[index].status === "draft" ? (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src={require("../../../assets/images/rentStatus/icons8-progress-64.png")}
                style={{
                  width: isMobile ? 28 : 35,
                  height: isMobile ? 28 : 35,
                }}
                alt="car"
              />
              <p
                style={{
                  fontSize: isMobile ? 14 : 13,
                  color: "#fd7e14",
                  transform: "translate(10px, 7px)",
                }}
              >
                Réservation en cours...
              </p>
            </span>
          ) : (dateStartRent < dateActual && dateStopRent > dateActual) ||
            arrayRentStatus[index].status === "published" ? (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src={require("../../../assets/images/rentStatus/icons8-car-rental-64.png")}
                style={{
                  width: isMobile ? 20 : 32,
                  height: isMobile ? 20 : 32,
                }}
                alt="car"
              />
              <p
                style={{
                  fontSize: isMobile ? 14 : 13,
                  color: "#28a745",
                  transform: "translate(10px, 7px)",
                }}
              >
                Jour de location...
              </p>
            </span>
          ) : dateActual === dateStopRent - 10575 ? (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src={require("../../../assets/images/rentStatus/icons8-important-time-50.png")}
                style={{
                  width: isMobile ? 28 : 32,
                  height: isMobile ? 28 : 32,
                }}
                alt="car"
              />
              <p
                style={{
                  fontSize: isMobile ? 14 : 13,
                  color: "#dc3545",
                  transform: "translate(10px, 7px)",
                }}
              >
                Dépot du véhicule !
              </p>
            </span>
          ) : dateActual > dateStopRent + 21150 ||
            arrayRentStatus[index].status === "archived" ? (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src={require("../../../assets/images/rentStatus/icons8-verified-account-48.png")}
                style={{
                  width: isMobile ? 28 : 35,
                  height: isMobile ? 28 : 35,
                }}
                alt="car"
              />
              <p
                style={{
                  fontSize: isMobile ? 14 : 13,
                  color: "#6610f2",
                  transform: "translate(10px, 7px)",
                }}
              >
                Terminée !
              </p>
            </span>
          ) : (
            <p
              style={{
                fontSize: isMobile ? 14 : 13,
                color: "#abb8c3",
              }}
            >
              Chargement...
            </p>
          )
        ) : (
          <p>Vous n'avez encore eu aucune activitée sur ce site !</p>
        )}
      </>
    ) : (
      <p>Chargement...</p>
    );
  }
);

export default HistoryRentStatus;
