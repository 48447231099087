/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { selectedIdAtom } from "../stateManager/reservationAtom";
import { selectedVehicleAtom } from "../stateManager/selectedVehicleAtom";
import { thisIsVehicleAtom } from "../stateManager/thisIsVehicleAtom";
import BaseLayout from "./BaseLayout";
import FeaturedVehicles from "./FeaturedVehicles";
import Breadcrumb from "./galleryComponents/Breadcrumb";
import SearchSection from "./galleryComponents/SearchSection";
import SubscribeSection from "./SubscribeSection";

const GalleryPage = () => {
  const [selectedVehicle, setSelectedVehicle] =
    useRecoilState(selectedVehicleAtom);
  const [isThisVehicle, setIsThisVehicle] = useRecoilState(thisIsVehicleAtom);
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);

  useEffect(() => {
    setSelectedId(undefined);
    setSelectedVehicle(null);
    setIsThisVehicle([]);
  }, [setIsThisVehicle, setSelectedId, setSelectedVehicle]);

  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <Breadcrumb />
        <FeaturedVehicles />
        <SearchSection />
        <div style={{ marginBottom: 100 }}>
          <SubscribeSection />
        </div>
      </div>
    </BaseLayout>
  );
};

export default GalleryPage;
