import { isMobile } from "react-device-detect";
import { homePageAtom } from "../stateManager/homePageAtom";
import { useRecoilState } from "recoil";
import { ASSETS_URL } from "../helpers/fetchUrls";

const SliderSection = () => {
  const [homeContent] = useRecoilState(homePageAtom);
  const PicturesAddress = `${ASSETS_URL}`;

  return (
    <section className="slider_section text-white text-center position-relative ">
      {homeContent.map((res) => (
        <div key={res.id} className="slick-track" style={{ opacity: 1 }}>
          <div
            className="item has_overlay d-flex align-items-center slick-slide"
            data-slick-index="0"
          >
            <img
              className="slidPict"
              src={
                isMobile
                  ? `${PicturesAddress}${res.img_mobile}`
                  : `${PicturesAddress}${res.img_ordi}`
              }
              style={{ width: "100%", height: "auto" }}
              alt="No item found"
            />

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 500,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="overlaySlider">
                <div className="textSlider">
                  <div className="sliderTextCenter">
                    <div className="slider_content text-center">
                      <h3
                        className="text-white text-uppercase"
                        data-animation="fadeInUp"
                        data-delay=".3s"
                        style={{ animationDelay: "0.3s" }}
                      >
                        {res.titre}
                      </h3>
                      <p
                        data-animation="fadeInUp"
                        data-delay=".5s"
                        className=""
                        style={{ animationDelay: "0.5s" }}
                      >
                        {res.sousTitre}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default SliderSection;
