import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import useShoppingCart from "../../hooks/useShoppingCart";

const ShoppingCartSection = React.memo(() => {
  const {
    shoppingCarInfos,
    refreshPage,
    PicturesAddress,
    isReserved,
    totalDays,
    dayPrice,
    htPrice,
    paymentMethod,
    toggleConfirmOrder,
    clickedConfirmOrder,
    taxePrice,
    totalPrice,
    sendOrder,
  } = useShoppingCart();

  return (
    <section className="cart_section sec_ptb_100 clearfix">
      <div className="container">
        <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
          <div className="col-lg-4 col-md-9 col-sm-10 col-xs-12">
            {shoppingCarInfos.map((result) => (
              <div
                key={result.id}
                className="feature_vehicle_item mt-0 ml-0 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ScrollAnimation animateIn="zoomIn">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3 className="item_title mb-0">{result.title}</h3>
                    <button
                      style={{ marginRight: 20, marginTop: -50 }}
                      onClick={refreshPage}
                      type="button"
                      className="remove_btn"
                    >
                      <i
                        className="fal fa-times"
                        style={{ color: "red", fontSize: 24 }}
                      ></i>
                    </button>
                  </div>
                  <div className="item_image position-relative">
                    <button className="image_wrap basketImg">
                      <img
                        src={`${PicturesAddress}${result.img}`}
                        alt="image_not_found"
                      />{" "}
                    </button>
                    <span className="item_price bg_default_blue">
                      {result.price}€/jour
                    </span>
                  </div>
                  <ul className="info_list ul_li_center clearfix"></ul>
                </ScrollAnimation>
              </div>
            ))}
          </div>
          <div className="col-lg-8 col-md-9 col-sm-10 col-xs-12">
            <div className="cart_info_content">
              <div className="row mt__30">
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Lieu de retrait:</h4>
                      <p className="mb-0">
                        <i className="fas fa-map-marker-alt"></i> Chez NégoLoc
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Date de retrait:</h4>
                      <p className="mb-0">
                        <i className="fas fa-calendar-alt"></i>{" "}
                        {isReserved.dateRetrait}
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Heure du retrait:</h4>
                      <p className="mb-0">
                        <i className="fas fa-clock"></i>{" "}
                        {isReserved.heureRetrait}
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Lieu de retour:</h4>
                      <p className="mb-0">
                        <i className="fas fa-map-marker-alt"></i> Chez NégoLoc
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Date du retour:</h4>
                      <p className="mb-0">
                        <i className="fas fa-calendar-alt"></i>{" "}
                        {isReserved.dateDepot}
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                  <div
                    className="cart_address_item aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <ScrollAnimation animateIn="zoomIn">
                      <h4>Heure du retour:</h4>
                      <p className="mb-0">
                        <i className="fas fa-clock"></i> {isReserved.heureDepot}
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <hr
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate"
              />
              <div className="cart_offers_include">
                <ScrollAnimation animateIn="zoomIn">
                  <h4
                    className="title_text mb-0 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Votre offre comprend :
                  </h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <ul
                        className="cart_info_list ul_li_block clearfix aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        {isReserved.offer1 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Enregistrement/ Taxe routière gratuit
                          </li>
                        ) : null}
                        {isReserved.offer2 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Assurance tous risques
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <ul
                        className="cart_info_list ul_li_block clearfix aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="500"
                      >
                        {isReserved.offer3 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Kilométrage illimité
                          </li>
                        ) : null}
                        {isReserved.offer4 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Dépôt de garantie
                          </li>
                        ) : null}
                        {isReserved.offer5 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Siège pour bébé
                          </li>
                        ) : null}
                        {isReserved.offer6 ? (
                          <li>
                            <i
                              className="far fa-check-circle"
                              style={{ color: "green" }}
                            ></i>{" "}
                            Assistance en cas de panne
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <hr
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate"
              />
              <ScrollAnimation animateIn="zoomIn">
                <div className="flex-row">
                  <ul
                    className="cart_info_list2 ul_li_block clearfix aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <li>
                      <strong>Durée de location:</strong> {totalDays} Jour(s)
                    </li>
                    <li>
                      <strong>Prix de la location:</strong> {dayPrice[0]}€ /Jour
                    </li>
                    <li>
                      <strong>Sous-total:</strong> {htPrice} € HT
                    </li>
                  </ul>
                  <ul
                    className="cart_info_list2 ul_li_block clearfix aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <li>
                      <strong>Moyen de paiement:</strong>{" "}
                      {paymentMethod && paymentMethod.cash
                        ? "Éspèces"
                        : paymentMethod.bankTransfert
                        ? "Transfert bancaire"
                        : paymentMethod.check
                        ? "Chèque"
                        : paymentMethod.paypal
                        ? "Paypal"
                        : ""}
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
              <hr
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate"
              />
              <ScrollAnimation animateIn="zoomIn">
                <div className="rental_calltoaction">
                  <form>
                    <h4
                      className="title_text aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Confirmez les informations ci-dessous:
                    </h4>

                    <div className="row" style={{ marginTop: 20 }}>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="300"
                        >
                          <label htmlFor="email">Votre Email</label>
                          <input
                            type="email"
                            name="email"
                            value={isReserved.email}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="400"
                        >
                          <label htmlFor="phone">Votre Téléphone</label>
                          <input
                            type="tel"
                            name="phone"
                            value={isReserved.tel}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="checkbox_input mb-0 aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <label htmlFor="payment_checkbox">
                        <input
                          onClick={toggleConfirmOrder}
                          onChange={(clickedConfirmOrder) =>
                            clickedConfirmOrder
                          }
                          value={clickedConfirmOrder}
                          checked={clickedConfirmOrder ? true : false}
                          id="payment_checkbox"
                          type="radio"
                        />
                        Je confirme toutes les informations présentes sur cette
                        page.
                      </label>
                    </div>
                  </form>
                </div>
              </ScrollAnimation>
              <hr
                data-aos="fade-up"
                data-aos-delay="100"
                className="aos-init aos-animate"
              />
              <div className="cart_bottom_content mt__30 clearfix">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <ScrollAnimation animateIn="zoomIn">
                      <ul
                        className="cart_price_info ul_li_block clearfix aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <li>
                          <span>Taxes:</span> {taxePrice} €
                        </li>
                        <li>
                          <span>Total:</span> {totalPrice} €
                        </li>
                      </ul>
                    </ScrollAnimation>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div
                      className="abtn_wrap clearfix aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <ScrollAnimation animateIn="zoomIn">
                        <button
                          disabled={clickedConfirmOrder ? false : true}
                          onClick={sendOrder}
                          className="orderButton custom_btn bg_default_red btn_width text-uppercase"
                          style={
                            clickedConfirmOrder
                              ? {
                                  opacity: 1,
                                }
                              : { opacity: 0.2 }
                          }
                        >
                          Commander{" "}
                          <img
                            src={require("../../assets/images/icons/icon_01.png")}
                            alt="icon_not_found"
                          />
                        </button>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ShoppingCartSection;
