import axios from "axios";
import { useRecoilState } from "recoil";
import { reservedListAtom } from "../stateManager/reservationAtom";
import { ordersPastAtom } from "../stateManager/shoppingCartAtom";
import { vehiclesListAtom } from "../stateManager/vehiclesListAtom";
import { BASE_URL } from "./fetchUrls";
import useReverseArray from "../hooks/useReverseArray";
import { homePageAtom } from "../stateManager/homePageAtom";
import { whyPageAtom } from "../stateManager/whyPageAtom";

const useGetData = () => {
  // eslint-disable-next-line no-unused-vars
  const [vehicles, setVehicles] = useRecoilState(vehiclesListAtom);
  // eslint-disable-next-line no-unused-vars
  const [ordered, setOrdered] = useRecoilState(ordersPastAtom);
  // eslint-disable-next-line no-unused-vars
  const [reservedList, setReservedList] = useRecoilState(reservedListAtom);
  const { mapRevers } = useReverseArray();
  // eslint-disable-next-line no-unused-vars
  const [homeContent, setHomeContent] = useRecoilState(homePageAtom);
  // eslint-disable-next-line no-unused-vars
  const [whyContent, setWhyContent] = useRecoilState(whyPageAtom);

  const getData = async () => {
    try {
      const resp = await axios.get(`${BASE_URL}/vehicles`);
      const reserved = await axios.get(`${BASE_URL}/alreadyReserved`);
      const orders = await axios.get(`${BASE_URL}/session`);

      setVehicles(resp.data.data);
      setReservedList(reserved.data.data);
      setOrdered(mapRevers(orders.data.data));
    } catch (err) {
      console.error(err);
    }
  };

  const getHomePageData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/accueil`);
      setHomeContent(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getWhyPageData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/pourquoi`);
      setWhyContent(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  return { getData, getHomePageData, getWhyPageData };
};

export default useGetData;
