import BaseLayout from "./BaseLayout";
import Bg10 from "../assets/images/breadcrumb/bg_10.jpg";
import { Link } from "react-router-dom";

const Mentions = () => {
  return (
    <BaseLayout>
      <div style={{ marginTop: 80 }}>
        <section className="breadcrumb_section text-center clearfix">
          <div
            className="page_title_area has_overlay d-flex align-items-center clearfix"
            data-bg-image={Bg10}
            style={{ backgroundImage: `url(${Bg10})` }}
          >
            <div className="overlay"></div>
            <div
              className="container aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h1 className="page_title text-white mb-0">Mentions Légales</h1>
            </div>
          </div>
          <div
            className="breadcrumb_nav clearfix"
            data-bg-color="#F2F2F2"
            style={{ backgroundColor: "rgb(242, 242, 242)" }}
          >
            <div className="container">
              <ul className="ul_li clearfix">
                <li>
                  <Link to={"/"}>Accueil</Link>
                </li>
                <li>Mentions légales</li>
              </ul>
            </div>
          </div>
        </section>
        <div className="container" style={{ marginTop: 50, paddingBottom: 80 }}>
          <div>© NÉGOLOC37 Tous droits réservés</div>
          <div>* *NÉGOLOC37* *</div>
          <div>5, rue de Castiglione 75001 PARIS</div>
          <div>Tél. : 01 53 45 30 26</div>
          <div>* Email : contact @ negoloc37.com</div>
          <div>* Site internet : http://www.negoloc37.com</div>
          <div>* SARL au capital de 15.000€</div>
          <div>* Siret : 498 537 661 00015</div>
          <div>* Code APE 7711A</div>
          <div>* T.V.A FR65498537661</div>
          <div>
            * Inscription au registre des voitures de transport avec chauffeur :
            EVTC075200213
          </div>
          <div>
            <div>Propriété Intellectuelle</div>
            <ul>
              <li>
                Toutes les créations logicielles présentes sur notre site sont
                protégées par le Droit d’auteur au titre de créations originales
                .
              </li>
              <li>
                Tous les éléments constitutifs du squelette du site (photos,
                images, logos…) sont la propriété exclusive de NÉGOLOC37.
              </li>
              <li>
                Les contenus du site (textes, articles…) sont la propriété
                exclusive de NÉGOLOC37 et ne peuvent être reproduits ni
                exploités sans notre accord.
              </li>
            </ul>
          </div>
          <div>
            <div>Contractualité des photos</div>
            <div>
              Les photos des véhicules présentes sur le site le sont à titre
              indicatif et ne sont pas contractuelles. Les modèles présentés
              peuvent faire l’objet de modification de la part des
              constructeurs, et les couleurs sont purement indicatives.
            </div>
          </div>
          <div>
            <div>Politique de confidentialité et de Protection des données</div>
            <div>
              Conformément aux articles 38 et 40 de la loi 2004-801 du
              06/08/2004, vous disposez d’un droit d’accès, de rectification, de
              suppression des données vous concernant. Ce droit peut s’exercer
              en ligne en adressant un mél à l’adresse contact @ negoloc37.com
              ou par courrier à l’adresse suivante:
            </div>
          </div>
          <div>
            <div>NÉGOLOC37</div>
            <div>5 rue de Castiglione</div>
            <div>75001 Paris</div>
          </div>
          <div>
            <div>Hébergement</div>
            <div>
              Notre site est hébergé sur les serveurs de la société Netlify.
            </div>
          </div>
          <div>
            <div>Service Clients</div>
            <div>
              Le service clients est joignable 7J/7 selon les conditions
              mentionnées sur le site.
            </div>
          </div>
          <div>
            <div>Contact</div>
            <div>
              Vous pouvez nous contacter à l’adresse contact @ negoloc37.com
              pour toute demande de renseignements, ou pour exercer votre droit
              d’accès.
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Mentions;
