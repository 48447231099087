import QRCode from "react-qr-code";
import { QRCODE_URL } from "../../../helpers/fetchUrls";

const QrCodeSynchro = ({
  userID,
  clickedQrCodeSync,
  handleClickQrCodeSync,
}) => {
  return (
    <div className="container">
      <div
        id="demo-modal"
        className={`modalQrcode ${
          clickedQrCodeSync ? "modalQrcode_target" : ""
        }`}
      >
        <div className="modalQrcode__content" style={{ maxWidth: 490 }}>
          <div className="modalQrcodeHead">
            <button
              onClick={handleClickQrCodeSync}
              className="modalQrcode__close"
            >
              &times;
            </button>
            <p style={{ fontSize: 14 }}>QrCode de Synchronisation!</p>
          </div>
          <div className="container">
            <p style={{ textAlign: "center" }}>
              Ce QrCode contient votre iD de profil
              <br />
              <sub style={{ color: "red" }}>(* il est confidentiel)</sub>
            </p>
          </div>
          <div className="modalQrcodeContain">
            <p style={{ textAlign: "center", maxWidth: "90%" }}>
              Une fois le QrCode scanné... via l'app photo classique, cela vous
              conduira directement à la page de synchronisation,
            </p>
            {userID ? (
              <QRCode value={`${QRCODE_URL}?userId=${userID}`} />
            ) : (
              "Chargement..."
            )}
            <div className="modalQrcode__footer">
              <span style={{ color: "black" }}>
                Votre Id de profil sera déjà entré, vous n'aurez plus qu'a
                cliquez sur le bouton "synchroniser"!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeSynchro;
