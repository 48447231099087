import { useEffect } from "react";
import "./style.css";

const PictureModal = ({ PicturesAddress, res }) => {
  useEffect(() => {
    // Get the modal
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    const openSpan = document.getElementById("mySpan");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg.src = this.src;
      captionText.innerHTML = this.alt;
    };
    openSpan.onclick = function () {
      modal.style.display = "block";
      modalImg.src = img.src;
      captionText.innerHTML = img.alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }, []);

  return (
    <>
      <div id="myModal" className="modal">
        <span className="close" style={{ color: "red" }}>
          &times;
        </span>
        <img className="modal-content" id="img01" alt="caption" />
        <div id="caption"></div>
      </div>
    </>
  );
};

export default PictureModal;
