import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  reservationAtom,
  selectedIdAtom,
} from "../stateManager/reservationAtom";
import { useForm } from "react-hook-form";
import { timeStringToMs, toTimestamp } from "./useToTimestamp";
import Logo from "../assets/images/logo/logo_01_1x.png";
import {
  endTimeToWork,
  startTimeToWork,
} from "../helpers/currentTimeVariables";
import { isEmptyPayementAtom } from "../stateManager/isEmptyPaymentAtom";

const UseReservation = () => {
  const [choosedCar, setChoosedCar] = useState("");
  const [selectedId, setSelectedId] = useRecoilState(selectedIdAtom);
  const [clickedCars, setClickedCars] = useState(false);
  const [acceptAll, setAcceptAll] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isReserved, setIsReserved] = useRecoilState(reservationAtom);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const MySwal = withReactContent(Swal);
  const currentDate = Math.round(Date.now() / 1000);

  let navigate = useNavigate();

  const schema = yup
    .object({
      vehicleName: yup.string().required("Aucun Véhicule séléctionné !"),
      dateRetrait: yup.string().required("Une date de retrait est requise"),
      dateDepot: yup.string().required("Une date de dépôt est requise"),
      heureRetrait: yup.string().required("Une heure de retrait est requise"),
      heureDepot: yup.string().required("Une heure de dépôt est requise"),
      firstname: yup.string().required("Votre prénom est requis"),
      lastname: yup.string().required("Votre Nom de famille est requis"),
      email: yup
        .string()
        .email("Email non valide")
        .required("Votre Email est requis"),
      tel: yup.string().required("votre numéro de téléphone est requis"),
      information: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsReserved(data);
    sessionStorage.setItem("datas", JSON.stringify(data));
    if (endDate >= startDate || startDate > currentDate) {
      setTimeout(() => {
        navigate("/panier");
      }, 1000);
    }
  };

  const handleAcceptAll = () => {
    setAcceptAll((acceptAll) => !acceptAll);
  };

  const [whatStartDayIsIt, setWhatStartDayISIt] = useState("");
  const [whatEndDayIsIt, setWhatEndDayISIt] = useState("");

  useEffect(() => {
    const retraitCarDate = document.getElementById("myStartDate");
    const depotCarDate = document.getElementById("myEndDate");
    const currentStartVal = retraitCarDate?.value;
    const currentEndDate = depotCarDate?.value;
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const StartDateWeekControl = new Date(`${currentStartVal}`);
    const EndDateWeekControl = new Date(`${currentEndDate}`);

    let dayNameStart = days[StartDateWeekControl.getDay()];
    let dayNameStop = days[EndDateWeekControl.getDay()];
    setWhatStartDayISIt(dayNameStart);
    setWhatEndDayISIt(dayNameStop);

    if (currentStartVal !== 0 && currentEndDate !== 0) {
      const dateRetrait = toTimestamp(currentStartVal);
      const dateDepot = toTimestamp(currentEndDate);
      setStartDate(dateRetrait);
      setEndDate(dateDepot);
    }

    if (startDate > currentDate + 338400) {
      MySwal.fire({
        title: "Délais trop long",
        icon: "info",
        html: `
          <div>
          <p>Nous ne pouvons garantir la disponibilité du véhicule, désolé !</p>
        </div>`,
        footer: "La disponibilité est garantie durant 4 jours, pas au-delà.",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
      }).then((result) => {
        if (result.isConfirmed) {
          setAcceptAll(false);
          setStartDate(0);
          MySwal.fire({
            html: `
              <div style="display:flex; align-items:center; justify-content:center">
                <p style="color:darkgrey; font-size:18px; max-width:260px">veuillez saisir une date de départ de location inférieure à 4 jours. Merci !</p>
              </div>`,
            imageUrl: Logo,
          });

          document.getElementById("myStartDate").value = "";
        }
      });
    }
    if (
      (acceptAll && endDate <= startDate) ||
      (acceptAll && startDate <= currentDate)
    ) {
      MySwal.fire({
        title: "Dates Incohérentes",
        html: `
          <div>
          <p >Les dates de retrait et de dépôt que vous avez saisies sont incohérentes...
          
          </p>
        </div>`,

        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            html: `<p style="color:red; font-size:18px">veuillez saisir des dates correctes s'il vous plaît !</p>`,
          });
          setAcceptAll(false);
          setStartDate(0);
          setEndDate(0);
          if (endDate <= startDate) {
            document.getElementById("myEndDate").value = "";
          }
          if (startDate <= currentDate) {
            document.getElementById("myStartDate").value = "";
          }
        }
      });
    }
  }, [
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    MySwal,
    acceptAll,
    currentDate,
  ]);

  const [startTimeWork, setStartTimeWork] = useState("");
  const [endTimeWork, setEndTimeWork] = useState("");
  const startWork = document.getElementById("heureRetrait");
  const endWork = document.getElementById("heureDepot");
  const workStartVal = startWork?.value;
  const workEndDate = endWork?.value;

  const startRangeTimeToWork = startTimeToWork;
  const endRangTimeToWork = endTimeToWork;

  let TotalRangeTimeWork =
    timeStringToMs(startTimeWork) < startRangeTimeToWork ||
    timeStringToMs(startTimeWork) > endRangTimeToWork ||
    timeStringToMs(endTimeWork) < startRangeTimeToWork ||
    timeStringToMs(endTimeWork) > endRangTimeToWork;

  useEffect(() => {
    if (workStartVal) {
      setStartTimeWork(workStartVal);
    }
    if (workEndDate) {
      setEndTimeWork(workEndDate);
    }
  }, [workEndDate, workStartVal]);

  useEffect(() => {
    if (TotalRangeTimeWork === true) {
      MySwal.fire({
        title: "Hors heures d'ouverture !",
        html: `
          <div>
          <p>Les heures que vous avez saisies ne correspondent pas avec nos horaires d'ouverture...</p>
          <p>Veuillez entrer une heure entre 9h00 et 18h00 svp.</p>
        </div>`,

        showConfirmButton: true,
        confirmButtonColor: "#7367f0",

        confirmButtonText: "Ok, J'ai compris !",
      }).then((result) => {
        if (result.isConfirmed) {
          setAcceptAll(false);
          if (
            timeStringToMs(startTimeWork) < startRangeTimeToWork ||
            timeStringToMs(startTimeWork) > endRangTimeToWork
          ) {
            document.getElementById("heureRetrait").value = "";
          }
          if (
            timeStringToMs(endTimeWork) < startRangeTimeToWork ||
            timeStringToMs(endTimeWork) > endRangTimeToWork
          ) {
            document.getElementById("heureDepot").value = "";
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRangeTimeToWork, endRangTimeToWork, startTimeWork, endTimeWork]);

  const [clickedDayAlert, setClickedDayAlert] = useState(false);

  useEffect(() => {
    if (!clickedDayAlert) {
      if (whatStartDayIsIt !== undefined || whatEndDayIsIt !== undefined) {
        if (
          whatStartDayIsIt === "Saturday" ||
          whatStartDayIsIt === "Sunday" ||
          whatEndDayIsIt === "Saturday" ||
          whatEndDayIsIt === "Sunday"
        ) {
          MySwal.fire({
            title: "Date hors jours ouvrables",
            icon: "info",
            html: `
                 <div>
                 <p>Nous sommes ouvert du lundi au vendredi seulement.</p>
               </div>`,
            footer: "",
            showConfirmButton: true,
            confirmButtonColor: "#7367f0",
            confirmButtonText: "Ok, J'ai compris !",
          })
            .then((result) => {
              if (result.isConfirmed) {
                setAcceptAll(false);
                setClickedDayAlert(true);

                MySwal.fire({
                  html: `
                     <div style="display:flex; align-items:center; justify-content:center">
                       <p style="color:darkgrey; font-size:18px; max-width:260px">veuillez saisir une date dans nos jours d'ouvertures. Merci !</p>
                     </div>`,
                  imageUrl: Logo,
                });

                document.getElementById("myStartDate").value = "";
                setWhatStartDayISIt("");

                document.getElementById("myEndDate").value = "";
                setWhatEndDayISIt("");
              }
            })
            .then(() => setClickedDayAlert(false));
        }
      }
    }
  }, [whatEndDayIsIt, whatStartDayIsIt, MySwal, clickedDayAlert]);

  const [isEmptyPayment, setIsEmptyPayment] =
    useRecoilState(isEmptyPayementAtom);

  useEffect(() => {
    if (acceptAll && isEmptyPayment) {
      MySwal.fire({
        title: "Aucun moyen de paiement renseigné",
        icon: "info",
        html: `
          <div>
          <p>Veuillez selectionner un moyen de paiement pour continuer !</p>
        </div>`,
        footer: "",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ee0979",
        confirmButtonText: "Ok, J'ai compris !",
      }).then((result) => {
        if (result.isConfirmed) {
          setAcceptAll(false);
        }
      });
    }
  }, [MySwal, isEmptyPayment, acceptAll]);

  return {
    choosedCar,
    setChoosedCar,
    selectedId,
    setSelectedId,
    clickedCars,
    setClickedCars,
    TotalRangeTimeWork,
    workStartVal,
    workEndDate,
    handleSubmit,
    onSubmit,
    register,
    startDate,
    endDate,
    currentDate,
    errors,
    handleAcceptAll,
    acceptAll,
    isEmptyPayment,
    setIsEmptyPayment,
  };
};

export default UseReservation;
