import { atom } from "recoil";

export const carCompare1Atom = atom({
  key: "carCompare1Atom",
  default: { carId: null },
});

export const carCompare2Atom = atom({
  key: "carCompare2Atom",
  default: { carId: null },
});

export const carCompare3Atom = atom({
  key: "carCompare3Atom",
  default: { carId: null },
});

export const optionsSupp1Atom = atom({
  key: "optionsSupp1Atom",
  default: {},
});
export const optionsSupp2Atom = atom({
  key: "optionsSupp2Atom",
  default: {},
});
export const optionsSupp3Atom = atom({
  key: "optionsSupp3Atom",
  default: {},
});
