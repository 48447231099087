import useSearchVehicles from "../hooks/useSearchVehicles";
import SuggestionsListComponent from "./searchByNameComponents/SuggestionsListComponent";

const SearchByName = () => {
  const {
    handleChange,
    onKeyDown,
    state,
    showSuggestions,
    setSelectedVehicle,
    filteredSuggestions,
    activeSuggestionIndex,
    onClick,
    searchResult,
    setCliked,
    navigate,
  } = useSearchVehicles();

  return (
    <form>
      <div
        className="form_item"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <input
          style={{ width: "80%" }}
          autoComplete="off"
          type="text"
          autoFocus
          onChange={handleChange}
          onKeyDown={onKeyDown}
          name="search"
          value={state.value}
          placeholder='Tapez "audi" ou "gps" ici par exemple...'
        />
        {showSuggestions && state.value !== "" && (
          <SuggestionsListComponent
            setSelectedVehicle={setSelectedVehicle}
            filteredSuggestions={filteredSuggestions}
            activeSuggestionIndex={activeSuggestionIndex}
            onClick={onClick}
          />
        )}
        {state.value !== "" ? (
          <p
            style={{
              width: 150,
              fontSize: 14,
              marginTop: 10,
              color: "red",
              marginLeft: 15,
            }}
          >
            résultat: {searchResult.length}
          </p>
        ) : null}
        {searchResult.length > 0 ? (
          <button
            onClick={() => {
              setCliked(true);
              navigate("/galerie");
            }}
          >
            <i className="fal fa-search"></i>
          </button>
        ) : null}
      </div>
    </form>
  );
};

export default SearchByName;
