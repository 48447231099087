import { Link } from "react-router-dom";

const Button = () => {
  return (
    <div className="buttonContain">
      <div
        className="abtn_wrap abtn_wrap-slider "
        data-animation="fadeInUp"
        data-delay=".7s"
        style={{
          animationDelay: "0.7s",
          position: "absolute",
          top: "5%",
          zIndex: 99999,
        }}
      >
        <Link
          className="custom_btn bg_default_red btn_width text-uppercase"
          to={"/reservations"}
        >
          Réservez maintenant{" "}
          <img
            src={require("../../assets/images/icons/icon_01.png")}
            alt="icon_not_found"
          />
        </Link>
      </div>
    </div>
  );
};

export default Button;
