import ScrollAnimation from "react-animate-on-scroll";

const FunFactSection = () => {
  return (
    <section
      className="funfact_section sec_ptb_150 clearfix"
      data-bg-gradient="linear-gradient(0deg, #0C0C0F, #292D45)"
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgb(12, 12, 15), rgb(41, 45, 69))",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div
              className="funfact_item text-center text-white aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <ScrollAnimation animateIn="zoomIn">
                <h3 className="counter_text text-white mb-0">
                  <span className="counter">500</span>+
                </h3>
                <small className="line bg_default_red"></small>
                <p className="item_title mb-0">Clients réguliers</p>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div
              className="funfact_item text-center text-white aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <ScrollAnimation animateIn="zoomIn">
                <h3 className="counter_text text-white mb-0">
                  <span className="counter">3500</span>+
                </h3>
                <small className="line bg_default_red"></small>
                <p className="item_title mb-0">Locations déjà effectuées</p>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div
              className="funfact_item text-center text-white aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <ScrollAnimation animateIn="zoomIn">
                <h3 className="counter_text text-white mb-0">
                  <span className="counter">180</span>+
                </h3>
                <small className="line bg_default_red"></small>
                <p className="item_title mb-0">Partenaires actifs</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFactSection;
