import { Link } from "react-router-dom";

const Sidebar = ({ clickedMenu, setClickedMenu }) => {
  return (
    <div className="sidebar-menu-wrapper">
      <div className={`mobile_sidebar_menu ${clickedMenu ? "active" : ""}`}>
        <button
          onClick={() => setClickedMenu(false)}
          type="button"
          className="close_btn"
        >
          <i className="fal fa-times"></i>
        </button>
        <div className="about_content mb_60">
          <div className="brand_logo mb_15">
            <Link to={"/"}>
              <img
                src={require("../assets/images/logo/logo_01_1x.png")}
                srcSet={require("../assets/images/logo/logo_01_2x.png")}
                alt="logo_not_found"
              />
            </Link>
          </div>
          <p className="mb-0">
            Nullam id dolor auctor, dignissim magna eu, mattis ante.
            Pellentesque tincidunt, elit a facilisis efficitur, nunc nisi
            scelerisque enim, rhoncus malesuada est velit a nulla. Cras porta mi
            vitae dolor tristique euismod. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit
          </p>
        </div>
        <div className="menu_list mb_60 clearfix">
          <h3 className="title_text text-white">Menu</h3>
          <ul className="ul_li_block clearfix">
            <li>
              <Link to={"/"}>Accueil</Link>
            </li>
            <li>
              <Link to={"/galerie"}>Nos véhicules</Link>
            </li>
            <li className="dropdown">
              <a
                href="#!"
                data-toggle="dropdown"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                Nos services
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/reservations"}>Réservation</Link>
                </li>
                <li>
                  <Link to={"/compare"}>Comparer le véhicules</Link>
                </li>
                <li>
                  <Link to={"/synchronisation"}>
                    synchronisation des données
                  </Link>
                </li>
                <li>
                  <Link to={"/a-propos"}>À propos de nous</Link>
                </li>
                <li>
                  <Link to={"/panier"}>Panier</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={"/contact"}>Contactez-nous</Link>
            </li>
          </ul>
        </div>
        <div className="booking_car_form">
          <h3 className="title_text text-white mb-2">Réserver un véhicule</h3>
          <p className="mb_15">
            Nullam id dolor auctor, dignissim magna eu, mattis ante.
            Pellentesque tincidunt, elit a facilisis efficitur.
          </p>
          <form>
            <div className="form_item">
              <h4 className="input_title text-white">Lieu de retrait</h4>
              <div className="position-relative">
                <input
                  id="location_one"
                  type="text"
                  name="location"
                  placeholder="Chez NégoLoc"
                  readOnly
                />{" "}
                <label htmlFor="location_one" className="input_icon">
                  <i className="fas fa-map-marker-alt"></i>
                </label>
              </div>
            </div>
            <div className="form_item">
              <h4 className="input_title text-white">Choisir une date</h4>
              <input type="date" name="date" />
            </div>
            <button className="custom_btn bg_default_red btn_width text-uppercase">
              <Link to={"/reservations"}>
                <p style={{ fontSize: 14 }}>Chercher</p>{" "}
              </Link>
            </button>
          </form>
        </div>
      </div>
      <div className={`overlay ${clickedMenu ? "active" : ""}`}></div>
    </div>
  );
};

export default Sidebar;
