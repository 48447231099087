import React from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import SliderRange from "../sharedComponents/SliderRange";
import ControlRentButton from "./ControlRentButton";
import PictureModal from "../pictureModal/PictureModal";
import useDetailVehicles from "../../hooks/useDetailVehicles";

const DetailsSection = React.memo(() => {
  const {
    isThisVehicle,
    minified,
    handleMinified,
    navigate,
    PicturesAddress,
    setSelectedId,
  } = useDetailVehicles();

  return (
    <div
      className="details_section sec_ptb_100 pb-0 clearfix"
      style={{ marginBottom: 25 }}
    >
      <>
        {isThisVehicle.map((res) => (
          <div key={res.id}>
            <div className="container">
              <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
                <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
                  <ScrollAnimation animateIn="zoomIn">
                    <aside
                      className={`filter_sidebar sidebar_section ${
                        minified ? "mini" : "maxi"
                      }`}
                      data-bg-color="#F2F2F2"
                      style={{ backgroundColor: "rgb(242, 242, 242)" }}
                    >
                      <div
                        onClick={handleMinified}
                        className="sidebar_header"
                        data-bg-gradient="linear-gradient(90deg, #0C0C0F, #292D45)"
                        style={{
                          backgroundImage:
                            "linear-gradient(90deg, rgb(12, 12, 15), rgb(41, 45, 69))",
                          display: "flex",
                          flexDirection: "row",

                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontSize: 28,
                            transform: minified
                              ? "rotate(90deg)"
                              : "rotate(-90deg)",
                            translate: "all 1.2s ease-in-out",
                            opacity: isMobileOnly ? 1 : 0,
                          }}
                        >
                          {">"}
                        </span>
                        <h3 className="text-white mb-0">Synthèse</h3>
                        <span
                          style={{
                            color: "white",
                            fontSize: 28,
                            transform: minified
                              ? "rotate(90deg)"
                              : "rotate(-90deg)",
                            translate: "all 1.2s ease-in-out",
                            opacity: isMobileOnly ? 1 : 0,
                          }}
                        >
                          {">"}
                        </span>
                      </div>
                      <div className="sb_widget">
                        <form>
                          <div
                            className="sb_widget price-range-area clearfix aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            <h4 className="input_title">Prix</h4>
                            <SliderRange val={res.price} />
                            <input
                              className="price-text"
                              type="text"
                              id="amount"
                              readOnly
                            />
                          </div>
                          <div
                            className="sb_widget car_picking aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            <div className="form_item">
                              <h4 className="input_title">Lieu de retrait</h4>
                              <div className="position-relative">
                                <input
                                  id="location_two"
                                  type="text"
                                  name="location"
                                  placeholder="Chez NégoLoc"
                                  readOnly
                                />{" "}
                                <label
                                  htmlFor="location_two"
                                  className="input_icon"
                                >
                                  <i className="fas fa-map-marker-alt"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="sb_widget aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          ></div>
                          <div
                            className="sb_widget aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            <h4 className="input_title">
                              Nombre de passagers:
                            </h4>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="checkbox_input">
                                  <label htmlFor="passengers_radio1">
                                    <input
                                      type="radio"
                                      id="passengers_radio1"
                                      name="passengers"
                                      checked
                                      readOnly
                                    />{" "}
                                    {res.passensers}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="sb_widget aos-init aos-animate"
                            style={{ paddingBottom: 0 }}
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            <div className="form_item">
                              <select style={{ display: "none" }}></select>
                              <div className="nice-select">
                                <span className="current">
                                  {` Boîte de vitesses:
                                  ${res.speeds}`}
                                </span>
                              </div>
                            </div>
                            <div className="form_item">
                              <div className="nice-select">
                                <span className="current">
                                  {` Type de carburant:
                                  ${res.fuel}`}
                                </span>
                              </div>
                            </div>
                            <div className="form_item">
                              <div className="nice-select">
                                <span className="current">
                                  {` Bagages:
                                  ${res.Suitcase}`}
                                </span>
                                <span className="current"></span>
                              </div>
                            </div>
                            <div className="form_item">
                              <div className="nice-select">
                                <span className="current">
                                  {` Type de véhicule:
                                  ${res.vehicleType}`}
                                </span>
                                <span className="current"></span>
                              </div>
                            </div>
                            <div>
                              <strong>Moteur:</strong> {res.Engine}
                            </div>
                            <div>
                              <strong>Paiement:</strong> Espèces, Carte
                              bancaire, Chèque.
                            </div>
                            <div>
                              <strong>Options:</strong> {res.Options}
                            </div>
                          </div>

                          <hr
                            data-aos="fade-up"
                            data-aos-delay="100"
                            className="aos-init aos-animate"
                          />
                          <ScrollAnimation animateIn="zoomIn">
                            <div
                              data-aos="fade-up"
                              data-aos-delay="100"
                              className="aos-init aos-animate"
                            >
                              <button
                                onClick={() => navigate("/galerie")}
                                className="custom_btn bg_default_red text-uppercase detailsButton"
                              >
                                <p style={{ fontSize: 12 }}>
                                  Retour aux Véhicules
                                </p>
                              </button>
                            </div>
                          </ScrollAnimation>
                        </form>
                      </div>
                    </aside>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12">
                  <div className="car_choose_carousel mb_30 clearfix">
                    <div
                      className="thumbnail_carousel slick-initialized slick-slider aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <ScrollAnimation animateIn="zoomIn">
                        <div className="slick-list draggable">
                          <div
                            className="slick-track"
                            style={{
                              opacity: 1,
                              width: " 100%",
                            }}
                          >
                            <div
                              className="item slick-slide "
                              data-slick-index="-1"
                              id=""
                              aria-hidden="true"
                              tabIndex="-1"
                              style={{ width: isMobile ? "200%" : "auto" }}
                            >
                              <div className="item_head">
                                <h4 className="item_title mb-0">{res.title}</h4>
                                <ul className="review_text ul_li_right clearfix">
                                  <li
                                    className="text-right"
                                    style={{ marginBottom: 10 }}
                                  >
                                    <strong>Super</strong>{" "}
                                  </li>
                                  <li>
                                    <span className="bg_default_blue">
                                      {res.note}
                                    </span>
                                  </li>
                                </ul>
                              </div>

                              {isMobile ? (
                                <>
                                  <img
                                    id="myImg"
                                    src={`${PicturesAddress}${res.img}`}
                                    alt={res.title}
                                  />
                                  <span
                                    id="mySpan"
                                    className="icon fl-bigmug-line-zoom60"
                                    data-lightgallery="item"
                                  />
                                </>
                              ) : (
                                <div className="pictContainer">
                                  <img
                                    id="myImg"
                                    src={`${PicturesAddress}${res.img}`}
                                    alt={res.title}
                                  />
                                  <span
                                    id="mySpan"
                                    className="icon fl-bigmug-line-zoom60"
                                    data-lightgallery="item"
                                  >
                                    <svg
                                      width="50%"
                                      height="50%"
                                      viewBox="0 0 210 297"
                                      version="1.1"
                                      id="svg5"
                                    >
                                      <defs id="defs2" />
                                      <g id="layer1">
                                        <path
                                          fill="none"
                                          d="M 25.081074,43.379654 H 101.35391 V 119.65249 H 25.081074 Z"
                                          id="path824"
                                          style={{ strokeWidth: 3.17803 }}
                                        />
                                        <path
                                          fillRule="nonzero"
                                          d="m 91.819805,52.913758 a 3.1780348,3.1780348 0 0 1 3.178035,3.178035 V 78.338037 H 88.64177 V 59.269828 H 37.793213 v 44.492492 h 19.068209 v 6.35606 H 34.615178 a 3.1780348,3.1780348 0 0 1 -3.178034,-3.17803 V 56.091793 a 3.1780348,3.1780348 0 0 1 3.178034,-3.178035 z m 0,31.780348 a 3.1780348,3.1780348 0 0 1 3.178035,3.178035 v 19.068209 a 3.1780348,3.1780348 0 0 1 -3.178035,3.17803 H 66.395526 a 3.1780348,3.1780348 0 0 1 -3.178034,-3.17803 V 87.872141 a 3.1780348,3.1780348 0 0 1 3.178034,-3.178035 z m -30.191331,-19.068208 -6.492725,6.492725 7.150578,7.150578 -4.493741,4.493741 -7.150578,-7.150578 -6.492725,6.492725 V 65.625898 Z"
                                          id="path826"
                                          style={{
                                            strokeWidth: 3.17803,
                                          }}
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                              )}
                              <ul className="btns_group ul_li_center clearfix">
                                <li>
                                  <span className="custom_btn btn_width bg_default_blue">
                                    {res.price}€/jour
                                  </span>
                                </li>
                                <li>
                                  <ControlRentButton
                                    res={res}
                                    setSelectedId={setSelectedId}
                                    navigate={navigate}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="car_choose_content">
                      <ul
                        className="info_list ul_li_block mb_15 clearfix aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <ScrollAnimation animateIn="zoomIn">
                          <li style={{ display: "flex", flexDirection: "row" }}>
                            <strong>Passagers:</strong>

                            <p>{res.passensers}</p>
                          </li>
                          <li>
                            <strong>Valises:</strong> {res.Suitcase}
                          </li>
                          <li>
                            <strong>Portes:</strong> {res.Doors}
                          </li>
                          <li>
                            <strong>Moteur:</strong> {res.Engine}
                          </li>
                          <li>
                            <strong>Type de carburant:</strong> {res.FuelType}
                          </li>
                          <li>
                            <strong>Options:</strong> {res.Options}
                          </li>
                        </ScrollAnimation>
                      </ul>
                      <div
                        data-aos="fade-up"
                        data-aos-delay="200"
                        className="aos-init aos-animate"
                      >
                        <ScrollAnimation animateIn="zoomIn">
                          <Link className="terms_condition" to={"/conditions"}>
                            <i className="fas fa-info-circle mr-1"></i> Termes
                            et conditions
                          </Link>
                        </ScrollAnimation>
                      </div>
                      <hr
                        data-aos="fade-up"
                        data-aos-delay="300"
                        className="aos-init aos-animate"
                      />
                      <ScrollAnimation animateIn="zoomIn">
                        <div className="rent_details_info">
                          <h4
                            className="list_title aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            Détails de la location:
                          </h4>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <ul
                                className="info_list ul_li_block mb_15 clearfix aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <li>
                                  <i className="fas fa-id-card"></i> Paiement
                                  Sécurisé
                                </li>
                                <li>
                                  <i className="fas fa-business-time"></i> Votre
                                  location est Protègée
                                </li>
                                <li>
                                  <i className="fas fa-business-time"></i> Reçu
                                  par courrier électronique
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <ul
                                className="info_list ul_li_block mb_15 clearfix aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-delay="300"
                              >
                                <li>
                                  <i className="fas fa-user-friends"></i>{" "}
                                  Co-voiturage
                                </li>
                                <li>
                                  <i className="fas fa-language"></i> Support
                                  multilingue
                                </li>
                                <li>
                                  <i className="fas fa-money-bill"></i>{" "}
                                  Différents Modes de paiement
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                      <hr
                        data-aos="fade-up"
                        data-aos-delay="100"
                        className="aos-init aos-animate"
                      />
                    </div>
                  </div>
                </div>
                <PictureModal PicturesAddress={PicturesAddress} res={res} />
              </div>
            </div>
          </div>
        ))}
      </>
    </div>
  );
});

export default DetailsSection;
