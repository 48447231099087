import { useState } from "react";
import { isMobile } from "react-device-detect";

const Profile = ({ orderInfos, user_id, isLoaded }) => {
  const [paymentMethod] = useState(
    JSON.parse(localStorage.getItem("payment")) ?? {}
  );
  return (
    <div
      className="account_info_list aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <h3 className="list_title mb_30">Profils de réservation:</h3>
      <ul className="ul_li_block clearfix">
        {isMobile ? (
          <li
            style={{
              display: "flex",
              width: "97%",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <span>ID de Profil :</span>{" "}
            <p style={{ fontSize: 13, transform: "translate(0,7px)" }}>
              {isLoaded ? user_id : "Chargement..."}
            </p>
          </li>
        ) : (
          <li>
            <span>ID de Profil :</span> {isLoaded ? user_id : "Chargement..."}
          </li>
        )}

        <li>
          <span>Méthode de paiement choisie:</span>{" "}
          {isLoaded
            ? paymentMethod && paymentMethod.cash
              ? "Éspèces"
              : paymentMethod.bankTransfert
              ? "Transfert bancaire"
              : paymentMethod.check
              ? "Chèque"
              : paymentMethod.paypal
              ? "Paypal"
              : "Chargement..."
            : null}
        </li>
        <li>
          <span>Numéro de téléphone:</span>{" "}
          {isLoaded && orderInfos ? `${orderInfos.phone}` : "Chargement..."}
        </li>
      </ul>
    </div>
  );
};

export default Profile;
