import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { currentDate } from "../../helpers/currentTimeVariables";
import { toTimestamp } from "../../hooks/useToTimestamp";
import HistoryCategoryCount from "./historiquesComponents/HistoryCategoryCount";
import HistoryDetailsComponent from "./historiquesComponents/HistoryDetailsComponent";
import { QrCodeModal } from "./historiquesComponents/QrCodeModal";

const Historiques = React.memo(
  ({ arrayData, rentHistory, navigate, ordered, isLoaded }) => {
    const [fullList, setFullList] = useState(false);
    const user_id = localStorage.getItem("userId");

    const toggleFullList = () => {
      setFullList((fullList) => !fullList);
    };

    const [startRentDate, setStartRentDate] = useState("");
    const [stopRentDate, setStopRentDate] = useState("");
    const [clicked, setClicked] = useState(false);
    const retrait = rentHistory.map((res) => res.dateRetrait).map((r) => r);
    const depot = rentHistory.map((res) => res.dateDepot).map((r) => r);
    const startD = retrait;
    const stopD = depot;

    useEffect(() => {
      if (startRentDate.length !== rentHistory.length) {
        setStartRentDate([...startRentDate, startD]);
        setStopRentDate([...stopRentDate, stopD]);
      }
    }, [rentHistory, startRentDate, stopRentDate, startD, stopD]);

    return (
      <div
        className="account_info_list aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        {user_id === arrayData[0].user_order ? (
          <>
            <h3 className="list_title mb_30">Historique des réservations :</h3>
            <HistoryCategoryCount isLoaded={isLoaded} arrayData={arrayData} />
            <button
              className="text_btn text-uppercase"
              onClick={() => navigate("/galerie")}
              style={{ marginBottom: 40 }}
            >
              <span>Réservez un véhicule</span>{" "}
              <img
                src={require("../../assets/images/icons/icon_02.png")}
                alt="icon_not_found"
              />
            </button>
            <div className="detailsHistory">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p style={{ fontWeight: 600 }}>Détails des réservations</p>
                <p> date actuelle : {new Date().toLocaleDateString()}</p>
              </div>
              <p
                onClick={toggleFullList}
                className={!fullList ? "bg_default_blue" : "bg_default_red"}
                style={{
                  textAlign: "center",
                  color: "white",
                  borderRadius: 4,
                  cursor: "pointer",
                  paddingTop: 4,
                  paddingBottom: 4,
                  transition: "all 0.6s ease-in-out",
                }}
              >
                {fullList ? "Réduire" : "Voir les détails"}
              </p>
              <HistoryDetailsComponent
                rentHistory={rentHistory}
                fullList={fullList}
                isMobile={isMobile}
                ordered={ordered}
                toTimestamp={toTimestamp}
                startD={startD}
                currentDate={currentDate}
                user_id={user_id}
                stopD={stopD}
                clicked={clicked}
                setClicked={setClicked}
                isLoaded={isLoaded}
                arrayData={arrayData}
              />
            </div>
            <QrCodeModal
              isLoaded={isLoaded}
              clicked={clicked}
              setClicked={setClicked}
              rentHistory={rentHistory}
            />
          </>
        ) : null}
      </div>
    );
  }
);

export default Historiques;
