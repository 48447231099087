import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import AboutPage from "./components/AboutPage";
import AccountPage from "./components/AccountPage";
import ComparComponent from "./components/ComparComponent";
import ConditionsGenerales from "./components/ConditionsGenerales";
import ContactPage from "./components/ContactPage";
import Error404 from "./components/Error404";
import GalleryPage from "./components/GalleryPage";
import HomePage from "./components/HomePage";
import Mentions from "./components/Mentions";
import ReservationPage from "./components/ReservationPage";
import ShoppingCart from "./components/ShoppingCart";
import VehiclesDetails from "./components/VehiclesDetails";
import useGetData from "./helpers/GetData";
import { vehiclesListAtom } from "./stateManager/vehiclesListAtom";
import { v4 as uuidv4 } from "uuid";
import SynchroAccount from "./components/accountComponents/SynchroAccount";
import { restoreAccountAtom } from "./stateManager/restoreAccountAtom";
import axios from "axios";
import { BASE_URL } from "./helpers/fetchUrls";
import { idForDeleteAtom } from "./stateManager/idForDeleteAtom";
import { homePageAtom } from "./stateManager/homePageAtom";
import { whyPageAtom } from "./stateManager/whyPageAtom";

function App() {
  const [vehicles] = useRecoilState(vehiclesListAtom);
  const { getData, getHomePageData, getWhyPageData } = useGetData();
  const [isMyHistory, setIsMyHistory] = useRecoilState(restoreAccountAtom);
  const [idForDelete] = useRecoilState(idForDeleteAtom);
  const [homeContent] = useRecoilState(homePageAtom);
  const [whyContent] = useRecoilState(whyPageAtom);

  useEffect(() => {
    if (vehicles.length === 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (homeContent.length === 0) {
      getHomePageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (whyContent.length === 0) {
      getWhyPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      localStorage.setItem("userId", uuidv4());
    }
  }, []);

  useEffect(() => {
    if (isMyHistory && isMyHistory.length > 0) {
      localStorage.setItem("reservation", JSON.stringify(isMyHistory));
      setTimeout(() => {
        setIsMyHistory([]);
      }, 600);
    }
  }, [isMyHistory, setIsMyHistory]);

  useEffect(() => {
    const deleteSauv = () =>
      idForDelete.forEach((x) => axios.delete(`${BASE_URL}/sauvegardes/${x}`));
    if (idForDelete.length > 0) deleteSauv();
  }, [idForDelete]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/details" element={<VehiclesDetails />} />
        <Route path="/reservations" element={<ReservationPage />} />
        <Route path="/panier" element={<ShoppingCart />} />
        <Route path="/galerie" element={<GalleryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/a-propos" element={<AboutPage />} />
        <Route path="/synchronisation" element={<SynchroAccount />} />
        <Route path="/conditions" element={<ConditionsGenerales />} />
        <Route path="/mentions-legales" element={<Mentions />} />
        <Route path="/error-page" element={<Error404 />} />
        <Route path="/compare" element={<ComparComponent />} />
        <Route path="/mon-compte" element={<AccountPage />} />
        <Route path="/synchronisation" element={<SynchroAccount />} />
        <Route path="/*" element={<Navigate replace to="/error-page" />} />
      </Routes>
    </div>
  );
}

export default App;
