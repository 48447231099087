import React, { useEffect, useRef, useState } from "react";
import CheckboxList from "./CheckboxList";
import { Link } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";
import ScrollAnimation from "react-animate-on-scroll";
import OrderPaymentMethode from "../shoppingCartComponents/OrderPaymentMethode";
import UseReservation from "../../hooks/UseReservation";
import { orderPaymentMethodsAtom } from "../../stateManager/shoppingCartAtom";
import { useRecoilState } from "recoil";

const ReservationSection = React.memo(() => {
  let targetRef = useRef();
  const {
    choosedCar,
    setChoosedCar,
    selectedId,
    setSelectedId,
    clickedCars,
    setClickedCars,
    TotalRangeTimeWork,
    workStartVal,
    workEndDate,
    handleSubmit,
    onSubmit,
    register,
    startDate,
    endDate,
    currentDate,
    errors,
    handleAcceptAll,
    acceptAll,
    isEmptyPayment,
    setIsEmptyPayment,
  } = UseReservation();

  const [paymentMethod] = useRecoilState(orderPaymentMethodsAtom);
  const [clickedStart, setClickedStart] = useState(false);
  const [clickedEnd, setClickedEnd] = useState(false);

  useEffect(() => {
    if (
      paymentMethod.cash === false &&
      paymentMethod.bankTransfert === false &&
      paymentMethod.check === false &&
      paymentMethod.paypal === false
    ) {
      setIsEmptyPayment(true);
    }
    if (
      paymentMethod.cash !== false ||
      paymentMethod.bankTransfert !== false ||
      paymentMethod.check !== false ||
      paymentMethod.paypal !== false
    ) {
      setIsEmptyPayment(false);
    }
  }, [paymentMethod, setIsEmptyPayment]);

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
  };

  useEffect(() => {
    if (isMobileOnly && choosedCar !== "" && clickedCars) {
      scrollToTarget();
    }
  }, [choosedCar, clickedCars]);

  return (
    <section className="reservation_section sec_ptb_100 clearfix">
      {choosedCar !== "" ? null : (
        <ScrollAnimation animateIn="zoomIn">
          <div
            className="container"
            style={{
              backgroundColor: "rgba(234,0,30,1)",
              borderRadius: 4,
              marginBottom: 10,
              padding: 4,
              alignItems: "center",
              justifyContent: "center",
              maxHeight: 50,
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: 21,
                color: "white",
                marginTop: 2,
              }}
            >
              {isMobile
                ? " Confirmez votre choix de vehicule "
                : "Confirmez votre choix de vehicule dans la liste de gauche."}
            </p>
          </div>
        </ScrollAnimation>
      )}
      {!isMobile ? (
        <>
          {choosedCar !== "" &&
          (TotalRangeTimeWork === true ||
            workStartVal === "" ||
            workEndDate === "") ? (
            <ScrollAnimation animateIn="zoomIn">
              <div
                className="container"
                style={{
                  backgroundColor: "rgba(234,0,30,1)",
                  marginBottom: 10,
                  padding: 4,
                  alignItems: "center",
                  justifyContent: "center",
                  maxHeight: 50,
                  borderRadius: 4,
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 21,
                    color: "white",
                    marginTop: 2,
                  }}
                >
                  Nos horaires d'ouvertures sont de 9:00 à 18h00
                </p>
              </div>
            </ScrollAnimation>
          ) : null}
        </>
      ) : null}
      <div className="container">
        <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
          <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
            <ScrollAnimation animateIn="zoomIn">
              <CheckboxList
                choosedCar={choosedCar}
                setChoosedCar={setChoosedCar}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                clickedCars={clickedCars}
                setClickedCars={setClickedCars}
              />
            </ScrollAnimation>
          </div>
          <p id="demo"></p>
          <div
            ref={(ref) => {
              targetRef = ref;
            }}
            className="col-lg-8 col-md-8 col-sm-10 col-xs-12 "
          >
            <ScrollAnimation
              animateIn="zoomIn"
              className="formCard"
              style={{ marginTop: isMobile ? 20 : 0 }}
            >
              <div className="reservation_form">
                <form
                  style={
                    choosedCar !== "" && clickedCars
                      ? { opacity: 1 }
                      : { opacity: 0.3 }
                  }
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {choosedCar !== "" ? (
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        type="text"
                        value={choosedCar}
                        onChange={(e) => setChoosedCar(e)}
                        name="vehicleName"
                        {...register("vehicleName")}
                      />

                      <h2 style={{ textAlign: "center", marginBottom: 25 }}>
                        {choosedCar}
                      </h2>
                      <button
                        className="choosedButton"
                        onClick={() => {
                          setSelectedId(undefined);
                          setClickedCars(false);
                          setChoosedCar("");
                        }}
                        style={{
                          width: isMobile ? 57 : 50,
                          height: 50,
                          backgroundColor: "whitesmoke",
                          padding: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          color: "white",
                          borderRadius: 8,
                          marginTop: -22,
                          marginRight: isMobile ? 0 : -25,
                          cursor: "pointer",
                          boxShadow:
                            "1px 2px 3px grey, inset 0px 1px 2px lighgrey",
                        }}
                      >
                        <img
                          className="closeIcon"
                          src={require("../../assets/images/icons/icons8-close-96.png")}
                          style={{ width: 35, height: 35 }}
                          alt="close"
                        />
                      </button>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                      {isMobile ? (
                        <>
                          {choosedCar !== "" &&
                          (TotalRangeTimeWork === true ||
                            workStartVal === "" ||
                            workEndDate === "") ? (
                            <ScrollAnimation animateIn="zoomIn">
                              <div
                                className="container"
                                style={{
                                  backgroundColor: "rgba(234,0,30,1)",
                                  border: "1px dotted grey",
                                  marginBottom: 10,
                                  padding: 4,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  maxHeight: 50,
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: 21,
                                    color: "white",
                                    marginTop: 2,
                                  }}
                                >
                                  {`Nos horaires: 9:00 -> 18h00`}
                                </p>
                              </div>
                            </ScrollAnimation>
                          ) : null}
                        </>
                      ) : null}
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <h4 className="input_title">Lieu de retrait</h4>
                        <div className="position-relative">
                          <input
                            id="location_two"
                            type="text"
                            name="location"
                            placeholder="Chez NégoLoc"
                            readOnly={true}
                          />
                          <label htmlFor="location_two" className="input_icon">
                            <i className="fas fa-map-marker-alt"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        style={{ position: "relative" }}
                      >
                        <h4 className="input_title">Choisissez une date</h4>
                        {clickedStart && isNaN(startDate) ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                              textIndent: 50,
                              position: "absolute",
                              top: isMobile ? 0 : 16,
                              left: isMobile ? 116 : 0,
                            }}
                          >
                            Du Lundi au Vendredi
                          </p>
                        ) : null}
                        <input
                          onClick={() => setClickedStart(true)}
                          type="date"
                          id="myStartDate"
                          className={
                            startDate <= currentDate ? "startDate" : ""
                          }
                          name="dateRetrait"
                          {...register("dateRetrait")}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 12,
                            fontWeight: "bold",
                            width: 250,
                          }}
                        >
                          {errors.dateRetrait?.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <label htmlFor="heureRetrait" className="input_title">
                          Heure
                        </label>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="time"
                            min="09:00"
                            max="18:00"
                            name="heureRetrait"
                            id="heureRetrait"
                            {...register("heureRetrait")}
                          />
                          <span className="validity"></span>
                        </span>
                        <p
                          style={{
                            color: "red",
                            fontSize: isMobile ? 10 : 11,
                            fontWeight: "bold",
                            width: 250,
                          }}
                        >
                          {errors.heureRetrait?.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <h4 className="input_title">Lieu de Dépôt</h4>
                        <div className="position-relative">
                          <input
                            id="location_three"
                            type="text"
                            name="location"
                            placeholder="Chez NégoLoc"
                            readOnly={true}
                          />
                          <label
                            htmlFor="location_three"
                            className="input_icon"
                          >
                            <i className="fas fa-map-marker-alt"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="500"
                      >
                        {" "}
                        {clickedEnd && isNaN(endDate) ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                              textIndent: 50,
                              position: "absolute",
                              top: isMobile ? 0 : 16,
                              left: isMobile ? 116 : 0,
                            }}
                          >
                            Du Lundi au Vendredi
                          </p>
                        ) : null}
                        <h4 className="input_title">Choisissez une date</h4>
                        <input
                          onClick={() => setClickedEnd(true)}
                          type="date"
                          name="dateDepot"
                          id="myEndDate"
                          className={endDate <= startDate ? "endDate" : ""}
                          {...register("dateDepot")}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 12,
                            fontWeight: "bold",
                            width: 250,
                          }}
                        >
                          {errors.dateDepot?.message}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div
                        className="form_item aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <label htmlFor="heureDepot" className="input_title">
                          Heure
                        </label>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="time"
                            name="heureDepot"
                            id="heureDepot"
                            min="09:00"
                            max="18:00"
                            {...register("heureDepot")}
                          />
                          <span className="validity"></span>
                        </span>
                        <p
                          style={{
                            color: "red",
                            fontSize: isMobile ? 10 : 11,
                            fontWeight: "bold",
                            width: 250,
                          }}
                        >
                          {errors.heureDepot?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr
                    className="mt-0 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  />
                  <div className="reservation_offer_checkbox">
                    <h4
                      className="input_title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      Votre offre comprend :
                    </h4>
                    <div className="row">
                      <div
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <div className="checkbox_input">
                          <label htmlFor="offer1">
                            <input
                              name="offer1"
                              {...register("offer1")}
                              type="radio"
                              id="offer1"
                              defaultChecked={true}
                            />
                            Enregistrement/ Taxe routière gratuit
                          </label>
                        </div>
                        <div className="checkbox_input">
                          <label htmlFor="offer2">
                            <input
                              name="offer2"
                              {...register("offer2")}
                              type="radio"
                              id="offer2"
                              defaultChecked
                            />
                            Assurance tous risques
                          </label>
                        </div>
                        <div className="checkbox_input">
                          <label htmlFor="offer3">
                            <input
                              name="offer3"
                              {...register("offer3")}
                              type="radio"
                              id="offer3"
                              defaultChecked
                            />
                            Kilométrage illimité
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <div className="checkbox_input">
                          <label htmlFor="offer4">
                            <input
                              name="offer4"
                              {...register("offer4")}
                              type="radio"
                              id="offer4"
                              defaultChecked
                            />
                            Dépôt de garantie
                          </label>
                        </div>
                        <div className="checkbox_input">
                          <label htmlFor="offer5">
                            <input
                              name="offer5"
                              {...register("offer5")}
                              type="radio"
                              id="offer5"
                            />
                            Siège pour bébé : 23 €/jour
                          </label>
                        </div>
                        <div className="checkbox_input">
                          <label htmlFor="offer6">
                            <input
                              name="offer6"
                              {...register("offer6")}
                              type="radio"
                              id="offer6"
                            />
                            Assistance en cas de panne
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr
                    className="mt-0 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  />
                  <div className="reservation_customer_details">
                    <h4
                      className="input_title aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Détails personnels :
                    </h4>
                    <ul
                      className="customer_gender ul_li clearfix aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <li>
                        <div className="checkbox_input">
                          <label htmlFor="mr">
                            <input
                              type="radio"
                              id="mr"
                              name="mr"
                              {...register("mr")}
                            />
                            Mr.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox_input">
                          <label htmlFor="ms">
                            <input
                              type="radio"
                              id="ms"
                              name="ms"
                              {...register("ms")}
                            />{" "}
                            Mme.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-xs-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="400"
                        >
                          <input
                            type="text"
                            name="firstname"
                            placeholder="Prénom"
                            {...register("firstname")}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                            }}
                          >
                            {errors.firstname?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-xs-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="500"
                        >
                          <input
                            type="text"
                            name="lastname"
                            placeholder="Nom"
                            {...register("lastname")}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                            }}
                          >
                            {errors.lastname?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-xs-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="600"
                        >
                          <input
                            type="text"
                            name="email"
                            placeholder="Adresse email"
                            {...register("email")}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                            }}
                          >
                            {errors.email?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-xs-12 col-xs-12">
                        <div
                          className="form_item aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-delay="700"
                        >
                          <input
                            type="text"
                            name="tel"
                            placeholder="Numéro de téléphone"
                            {...register("tel")}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 12,
                              fontWeight: "bold",
                              width: 250,
                            }}
                          >
                            {errors.tel?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="form_item aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      <textarea
                        name="information"
                        placeholder="Informations complémentaires (facultatif)"
                        {...register("information")}
                      ></textarea>
                    </div>
                    {isEmptyPayment ? (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p className="red-alert">
                          Un moyen de paiement doit être séléctionné !
                        </p>
                      </span>
                    ) : null}
                    <OrderPaymentMethode isEmptyPayment={isEmptyPayment} />
                    <div
                      data-aos="fade-up"
                      data-aos-delay="100"
                      className="aos-init aos-animate"
                    >
                      <Link className="terms_condition" to={"/conditions"}>
                        <i className="fas fa-info-circle mr-1"></i> Vous devez
                        avoir 21 ans au moins pour louer cette voiture.
                      </Link>
                    </div>
                    <hr
                      data-aos="fade-up"
                      data-aos-delay="200"
                      className="aos-init aos-animate"
                    />
                    <div className="row align-items-center justify-content-lg-between">
                      <div
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <div className="checkbox_input mb-0">
                          <label htmlFor="accept">
                            <input
                              onClick={handleAcceptAll}
                              type="radio"
                              value={acceptAll}
                              id="accept"
                              checked={acceptAll ? true : false}
                              onChange={(acceptAll) => acceptAll}
                            />{" "}
                            Accepter toutes les informations et conditions de
                            paiements.
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <button
                          disabled={acceptAll ? false : true}
                          type="submit"
                          className="custom_btn bg_default_red text-uppercase reservationButton"
                          style={acceptAll ? { opacity: 1 } : { opacity: 0.2 }}
                        >
                          <p style={{ fontSize: 10 }}> Réservez maintenant</p>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {choosedCar === "" ? (
                <div className="overlay-reservationForm" />
              ) : null}
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
});

export default ReservationSection;
