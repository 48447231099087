import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useRecoilState } from "recoil";
import { qrCodeValuesAtom } from "../../../stateManager/qrCodeValuesAtom";
import { veNameAtom } from "../../../stateManager/thisIsVehicleAtom";

export const QrCodeModal = ({ clicked, setClicked, rentHistory }) => {
  const [qrCodeValue, setQrCodeValue] = useRecoilState(qrCodeValuesAtom);
  const [veName] = useRecoilState(veNameAtom);

  const handleClick = () => {
    setClicked((clicked) => !clicked);
  };

  const [isLoaded, setIsloaded] = useState(false);

  useEffect(() => {
    setIsloaded(true);

    return () => setIsloaded(false);
  }, []);

  const getData = () =>
    new Promise((resolve, reject) => {
      const datas = qrCodeValue;
      if (!datas) {
        return setTimeout(() => reject(new Error("Data not found")), 50);
      }
      setTimeout(() => resolve(datas), 50);
    });

  const [dataToDisplay, setDataToDisplay] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    async function handleStatus() {
      const controller = new AbortController();
      const signal = controller.signal;
      await getData({ signal }).then((d) => {
        setDataToDisplay(d);
      });
    }
    if (isLoaded && qrCodeValue !== "") {
      handleStatus();
    }
    return () => {
      controller.abort(signal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, qrCodeValue, setQrCodeValue]);

  return (
    <div>
      <div
        id="demo-modal"
        className={`modalQrcode ${clicked ? "modalQrcode_target" : ""}`}
      >
        <div className="modalQrcode__content">
          <div className="modalQrcodeHead">
            <button onClick={handleClick} className="modalQrcode__close">
              &times;
            </button>
            <p>QrCode de vérification!</p>
          </div>
          <div className="modalQrcodeContain">
            <p>{veName ? veName : "Chargement..."}</p>
            {dataToDisplay ? (
              <QRCode value={`${dataToDisplay}`} />
            ) : (
              "Chargement..."
            )}
            <div className="modalQrcode__footer">
              <span> Présentez ce QrCode à chaque étape de location.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
