import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

const GallerySection = () => {
  return (
    <section className="gallery_section clearfix">
      <div className="updown_style_wrap minus_bottom">
        <div className="updown_style">
          <div
            className="gallery_fullimage aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <ScrollAnimation animateIn="zoomIn">
              <img
                style={{ position: "relative", zIndex: 10 }}
                src={require("../../assets/images/blog/img_07.jpg")}
                alt="image_not_found"
              />
              <div className="overlay" />
              <div className="item_content text-white">
                <h3 className="item_title text-white">
                  Phasellus porta pulvinar metus
                </h3>
                <p>
                  Phasellus porta pulvinar metus, sit amet bibendum lectus
                  hendrerit vel. Duis ullamcorper, justo quis hendrerit
                  venenatis, purus mi volutpat dui, vel commodo urna eros eget
                  sapien
                </p>
                <button className="text_btn text-uppercase">
                  <Link to={"/galerie"}>
                    <span>Trouver un véhicule</span>{" "}
                    <img
                      src={require("../../assets/images/icons/icon_02.png")}
                      alt="icon_not_found"
                    />
                  </Link>
                </button>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="gallery_fullimage aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
            style={{ position: "relative", zIndex: 10 }}
          >
            <ScrollAnimation animateIn="zoomIn">
              <img
                style={{ position: "relative", zIndex: 10 }}
                src={require("../../assets/images/blog/img_08.jpg")}
                alt="image_not_found"
              />
              <div className="overlay" />
              <div className="item_content text-white">
                <h3 className="item_title text-white">
                  Phasellus porta pulvinar metus
                </h3>
                <p>
                  Phasellus porta pulvinar metus, sit amet bibendum lectus
                  hendrerit vel. Duis ullamcorper, justo quis hendrerit
                  venenatis, purus mi volutpat dui, vel commodo urna eros eget
                  sapien
                </p>
                <button className="text_btn text-uppercase">
                  <Link to={"/galerie"}>
                    <span>Trouver un véhicule</span>{" "}
                    <img
                      src={require("../../assets/images/icons/icon_02.png")}
                      alt="icon_not_found"
                    />
                  </Link>
                </button>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="updown_style">
          <div
            className="gallery_fullimage aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ position: "relative", zIndex: 10 }}
          >
            <ScrollAnimation animateIn="zoomIn">
              <img
                style={{ position: "relative", zIndex: 10 }}
                src={require("../../assets/images/blog/img_01.jpg")}
                alt="image_not_found"
              />
              <div className="overlay" />
              <div className="item_content text-white">
                <h3 className="item_title text-white">
                  Phasellus porta pulvinar metus
                </h3>
                <p>
                  Phasellus porta pulvinar metus, sit amet bibendum lectus
                  hendrerit vel. Duis ullamcorper, justo quis hendrerit
                  venenatis, purus mi volutpat dui, vel commodo urna eros eget
                  sapien
                </p>
                <button className="text_btn text-uppercase">
                  <Link to={"/galerie"}>
                    <span>Trouver un véhicule</span>{" "}
                    <img
                      src={require("../../assets/images/icons/icon_02.png")}
                      alt="icon_not_found"
                    />
                  </Link>
                </button>
              </div>
            </ScrollAnimation>
          </div>
          <div
            className="gallery_fullimage aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="300"
            style={{ position: "relative", zIndex: 10 }}
          >
            <ScrollAnimation animateIn="zoomIn">
              <img
                style={{ position: "relative", zIndex: 10 }}
                src={require("../../assets/images/blog/img_09.jpg")}
                alt="image_not_found"
              />
              <div className="overlay" />
              <div className="item_content text-white">
                <h3 className="item_title text-white">
                  Phasellus porta pulvinar metus
                </h3>
                <p>
                  Phasellus porta pulvinar metus, sit amet bibendum lectus
                  hendrerit vel. Duis ullamcorper, justo quis hendrerit
                  venenatis, purus mi volutpat dui, vel commodo urna eros eget
                  sapien
                </p>
                <button className="text_btn text-uppercase">
                  <Link to={"/galerie"}>
                    <span>Trouver un véhicule</span>{" "}
                    <img
                      src={require("../../assets/images/icons/icon_02.png")}
                      alt="icon_not_found"
                    />
                  </Link>
                </button>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
