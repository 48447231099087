import React from "react";
import { isMobile } from "react-device-detect";
import { ASSETS_URL } from "../../helpers/fetchUrls";

const SuggestionsListComponent = React.memo(
  ({
    filteredSuggestions,
    activeSuggestionIndex,
    onClick,
    setSelectedVehicle,
  }) => {
    const PicturesAddress = `${ASSETS_URL}`;

    return filteredSuggestions.length ? (
      <div className="suggestionContainer slide-in-top ">
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestionIndex) {
              className = "suggestion-active";
            }

            return (
              <li
                className={className}
                key={suggestion.id}
                onClick={() => {
                  setSelectedVehicle(suggestion.id);
                  onClick();
                }}
              >
                <p style={{ fontSize: isMobile ? 11 : 13 }}>
                  {isMobile
                    ? suggestion.title.substring(4, 10) + "..."
                    : suggestion.title.substring(4, 24) + "..."}
                </p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${PicturesAddress}${suggestion.img}`}
                    style={{
                      width: 80,
                      height: "auto",
                      borderRadius: 8,
                      marginTop: 5,
                    }}
                    alt="car"
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      <div className="suggestionContainer slide-in-top ">
        <div className="no-suggestions">
          <span role="img" aria-label="tear emoji">
            😪
          </span>{" "}
          <em>Aucunes Suggestions, désolé!</em>
        </div>
      </div>
    );
  }
);

export default SuggestionsListComponent;
