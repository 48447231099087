const HistoryCategoryCount = ({ arrayData }) => {
  return (
    <ul className="ul_li_block clearfix">
      <li>
        <span>Reservations en cours:</span>{" "}
        {arrayData && arrayData.filter((res) => res.status === "draft").length}
      </li>
      <li>
        <span>Locations en cours:</span>{" "}
        {arrayData &&
          arrayData.filter((res) => res.status === "published").length}
      </li>
      <li style={{ borderBottom: "1px solid grey" }}>
        <span>Locations terminée:</span>{" "}
        {arrayData &&
          arrayData.filter(
            (res) => res.status !== "draft" && res.status !== "published"
          ).length}
      </li>
      <li>
        <span>Total des réservations:</span>{" "}
        {arrayData && arrayData.filter((res) => res.status).length}
      </li>
    </ul>
  );
};

export default HistoryCategoryCount;
